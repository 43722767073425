<div class="card custom-card-no-top-border">
    <div class="card-body">
        <fieldset>
            <div class="form-row">
                <div class="col-md-5">
                    <label for="codiceIntervento">Codice Intervento</label>
                    <select [ngClass]="{
                        'form-control custom-select': true,
                        'is-invalid':false}" 
                        id="codiceIntervento" 
                        [(ngModel)]="selectedCodiceIntervento"
                        (change)="changeSelectCodiceIntevento()">
                        <option></option>
                        <ng-container *ngFor="let codiceIntervento of codiciIntervento">
                            <option [ngValue]="codiceIntervento">{{ codiceIntervento.descrizione }}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
        </fieldset>
        <app-monitoraggio-mensile-dati-anagrafici 
            [datiAnagrafici]="datiAnagrafici"
            [contestoOperativo]="contestoOperativo">
        </app-monitoraggio-mensile-dati-anagrafici>
        <div class="card mt-3">
            <div class="card-body">
                <fieldset>
                    <legend>Acquisto Ambulanze</legend>
                    <div class="form-row">
                        <div class="form-group row col-md-6">
                            <label for="ambulanzeDaAcquistare" class="col-sm-6 col-form-label">Ambulanze da acquistare:</label>
                            <div class="col-sm-6">
                                <p class="form-control-plaintext" id="ambulanzeDaAcquistare">{{acquistoAmbulanze?.ambulanzeDaAcquistare | currency:'EUR' }}</p>
                            </div>
                        </div>
                        <div class="form-group row col-md-6">
                            <label for="tipologiaAllestimento" class="col-sm-6 col-form-label">Tipologia allestimento:</label>
                            <div class="col-sm-6">
                                <p class="form-control-plaintext" id="tipologiaAllestimento">{{acquistoAmbulanze?.tipologiaAllestimento}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group row col-md-6">
                            <label for="costoCadauno" class="col-sm-6 col-form-label">Costo cad. :</label>
                            <div class="col-sm-6">
                                <p class="form-control-plaintext" id="costoCadauno">{{acquistoAmbulanze?.costoCadauno | currency:'EUR'}}</p>
                            </div>
                        </div>
                        <div class="form-group row col-md-6">
                            <label for="costoComplessivo" class="col-sm-6 col-form-label">Costo complessivo:</label>
                            <div class="col-sm-6">
                                <p class="form-control-plaintext" id="costoComplessivo">{{acquistoAmbulanze?.costoComplessivo | currency:'EUR'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group row col-md-6">
                            <label for="cup" class="col-sm-6 col-form-label">CUP:</label>
                            <div class="col-sm-6">
                                <p class="form-control-plaintext" id="cup">{{acquistoAmbulanze?.cup}}</p>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-body">
              <form [formGroup]="form" (ngSubmit)="submit()">
                <fieldset>
                  <legend>Monitoraggi</legend>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="statiAttuazione">Stato di attuazione
                                <button 
                                    id="statiAttuazionePopOver" 
                                    class="btn" 
                                    type="button" 
                                    placement="top"
                                    ngbPopover="{{informativaCampi.AMBULANZE_STATO_ATTUAZIONE}}">
                                    <i class="fas fa-info-circle"></i>
                                </button>
                            </label>
                            <select
                                [ngClass]="{
                                'form-control custom-select': true,
                                'is-invalid':
                                    shouldValidate && formCommons.isFieldInvalid(form, 'statiAttuazione')
                                }"
                                id="statiAttuazione"
                                aria-describedby="statiAttuazioneSmall"
                                formControlName="statiAttuazione"
                                [compareWith]="formCommons.compareSelectOption"
                            >
                                <option></option>
                                <ng-container *ngFor="let statoAttuazioneAmbulanze of statiAttuazioneAmbulanze">
                                <option [ngValue]="statoAttuazioneAmbulanze">{{ statoAttuazioneAmbulanze.descrizione }}</option>
                                </ng-container>
                            </select>
                            <small id="statiAttuazioneSmall" class="invalid-feedback"></small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="tempistichePreviste"
                                >Tempistiche per acquisto e operativit&agrave;
                                <button 
                                    id="tempistichePrevistePopOver" 
                                    class="btn" 
                                    type="button" 
                                    placement="top"
                                    ngbPopover="{{informativaCampi.AMBULANZE_TEMPISTICHE_PREVISTE}}">
                                    <i class="fas fa-info-circle"></i>
                                </button>
                            </label
                            >
                            <input
                                type="number"
                                [ngClass]="{
                                'form-control': true,
                                'is-invalid':
                                    shouldValidate &&
                                    formCommons.isFieldInvalid(form, 'tempistichePreviste')
                                }"
                                min="0"
                                class="form-control"
                                id="tempistichePreviste"
                                formControlName="tempistichePreviste"
                                aria-describedby="tempistichePrevisteSmall"
                                onkeydown="return event.keyCode !== 69"
                            />
                            <small id="tempistichePrevisteSmall" class="invalid-feedback">
                                <p *ngIf="form.controls.tempistichePreviste.errors && form.controls.tempistichePreviste.errors.required">Il campo &egrave; obbligatorio</p>
                                <p *ngIf="form.controls.tempistichePreviste.errors && form.controls.tempistichePreviste.errors.min">Il valore minimo consentito &egrave; 0</p>
                                <p *ngIf="form.controls.tempistichePreviste.errors && form.controls.tempistichePreviste.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                            </small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="percentualeAttuazione"
                                >Percentuale di attuazione</label
                            >
                            <input
                                type="number"
                                [ngClass]="{
                                'form-control': true,
                                'is-invalid':
                                    shouldValidate &&
                                    formCommons.isFieldInvalid(form, 'percentualeAttuazione')
                                }"
                                min="0"
                                class="form-control"
                                id="percentualeAttuazione"
                                formControlName="percentualeAttuazione"
                                aria-describedby="percentualeAttuazioneSmall"
                                onkeydown="return event.keyCode !== 69"
                            />
                            <small id="percentualeAttuazioneSmall" class="invalid-feedback">
                                <p *ngIf="form.controls.percentualeAttuazione.errors && form.controls.percentualeAttuazione.errors.required">Il campo &egrave; obbligatorio</p>
                                <p *ngIf="form.controls.percentualeAttuazione.errors && form.controls.percentualeAttuazione.errors.min">Il valore minimo consentito &egrave; 0</p>
                                <p *ngIf="form.controls.percentualeAttuazione.errors && form.controls.percentualeAttuazione.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                            </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="mesiComplessiviPrevisti"
                                >Mesi complessivi previsti per lo stato di attuazione</label
                            >
                            <input
                                type="number"
                                [ngClass]="{
                                'form-control': true,
                                'is-invalid':
                                    shouldValidate &&
                                    formCommons.isFieldInvalid(form, 'mesiComplessiviPrevisti')
                                }"
                                min="0"
                                class="form-control"
                                id="mesiComplessiviPrevisti"
                                formControlName="mesiComplessiviPrevisti"
                                aria-describedby="mesiComplessiviPrevistiSmall"
                                onkeydown="return event.keyCode !== 69"
                            />
                            <small id="mesiComplessiviPrevistiSmall" class="invalid-feedback">
                                <p *ngIf="form.controls.mesiComplessiviPrevisti.errors && form.controls.mesiComplessiviPrevisti.errors.required">Il campo &egrave; obbligatorio</p>
                                <p *ngIf="form.controls.mesiComplessiviPrevisti.errors && form.controls.mesiComplessiviPrevisti.errors.min">Il valore minimo consentito &egrave; 0</p>
                                <p *ngIf="form.controls.mesiComplessiviPrevisti.errors && form.controls.mesiComplessiviPrevisti.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                            </small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="note"
                                >Note</label
                            >
                            <textarea
                                type="text"
                                [ngClass]="{
                                'form-control': true,
                                'is-invalid':
                                    shouldValidate &&
                                    formCommons.isFieldInvalid(form, 'note')
                                }"
                                class="form-control"
                                id="note"
                                maxlength="500"
                                formControlName="note"
                                aria-describedby="noteSmall"
                            ></textarea>
                            <small id="noteSmall" class="invalid-feedback"></small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="dataPrevistaConclusione"
                                >Data prevista conclusione intervento</label
                            >
                            <div class="input-group mb-2">
                            <input
                                type="text"
                                [ngClass]="{
                                    'form-control': true,
                                    'is-invalid':
                                    shouldValidate && formCommons.isFieldInvalid(form, 'dataPrevistaConclusione')
                                }"
                                class="form-control"
                                id="dataPrevistaConclusione"
                                aria-describedby="dataPrevistaConclusioneSmall"
                                formControlName="dataPrevistaConclusione"
                                placeholder="gg/mm/aaaa"
                                ngbDatepicker 
                                #dataPrevistaConclusioneDatePicker="ngbDatepicker"
                            />
                            <div class="input-group-append">
                                <button
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    (click)="dataPrevistaConclusioneDatePicker.toggle()"
                                >
                                    <i class="fas fa-calendar"></i>
                                </button>
                            </div>
                            <small id="dataPrevistaConclusioneSmall" class="invalid-feedback">
                                <p *ngIf="form.controls.dataPrevistaConclusione.errors && form.controls.dataPrevistaConclusione.errors.required">Il campo &egrave; obbligatorio</p>
                                <p *ngIf="form.controls.dataPrevistaConclusione.errors && form.controls.dataPrevistaConclusione.errors.ngbDate">La data non &egrave; in un formato valido</p>
                            </small>
                        </div>
                    </div>
                  </div>
                  <div *ngIf="this.monitoraggio && this.monitoraggio.isDefault !== false">
                    <button type="reset" class="btn btn-light mr-2">Svuota campi</button>
                    <button type="submit" class="btn btn-primary">Salva</button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
    </div>
</div>
