import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/service/login-service';
import { UserService } from 'src/app/service/user-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  user: any = undefined;
  userSubscription: Subscription;

  constructor(private router: Router, private userService: UserService, private loginService: LoginService) {
  }

  ngOnInit(): void {
    this.userSubscription = this.userService.user.subscribe(usr => {
      this.user = usr;
    })
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  goHome() {
    this.router.navigate(['/home']);
  }

  logout() {
    this.loginService.logout().then(()=>{
      this.router.navigateByUrl('/login');
    })
  }

}
