import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { DateUtils } from 'src/app/utility/date-utils';
import { CO_MON_MENSILE_PS } from '../../tipologiche/contesto-operativo';

@Component({
  selector: 'app-monitoraggio-mensile-pronto-soccorso',
  templateUrl: './monitoraggio-mensile-pronto-soccorso.component.html',
  styleUrls: ['./monitoraggio-mensile-pronto-soccorso.component.css']
})
export class MonitoraggioMensileProntoSoccorsoComponent implements OnInit {

  formCommons = AppInjector.get(FormCommons);
  form: FormGroup;
  contestoOperativo = CO_MON_MENSILE_PS;

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = '';

  @Input()
  datiAnagrafici: any;

  @Input()
  intervento: any;

  @Input()
  interventiRistrutturazione: any;

  @Input()
  quadroEconomico: any;

  @Input()
  statiAttuazioneServizi: any;

  @Input()
  statiAttuazioneLavori: any;

  @Input()
  statiAttuazioneAttrezzature: any;

  _monitoraggio: any;
  
  get monitoraggio(): any {
    return this._monitoraggio;
  };

  @Input()
  set monitoraggio(value: any) {
    this._monitoraggio = value;
  }

  _codiciIntervento: any;

  get codiciIntervento() {
    return this._codiciIntervento;
  }

  @Input()
  set codiciIntervento(value) {
    this._codiciIntervento = value;
  }

  _selectedCodiceIntervento: any;

  get selectedCodiceIntervento() {
    return this._selectedCodiceIntervento;
  }

  @Input()
  set selectedCodiceIntervento(value) {
    this._selectedCodiceIntervento = value;
  }

  @Output()
  selectedCodiceInterventoChange = new EventEmitter<any>();

  @Output()
  changeSelect = new EventEmitter<any>();

  @Output()
  preSubmit = new EventEmitter<any>();

  @Output()
  onSubmit = new EventEmitter<any>();

  @Output()
  onUpdate = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void { }

  changeSelectCodiceIntevento(){
    this.selectedCodiceInterventoChange.emit(this.selectedCodiceIntervento);
    this.changeSelect.emit();
  }

  preSubmitMonitoraggio(value:any){
    this.preSubmit.emit(value);
  }

  onSubmitMonitoraggio(value:any){
    this.onSubmit.emit(value);
  }

  onUpdateMonitoraggio(value:any){
    this.onUpdate.emit(value);
  }

}
