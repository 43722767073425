<div class="justify-content-center page-content">
    <div class="home-content">
        <h3>Reset Password</h3>
        <div class="card">
            <div class="card-body">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <fieldset>
                        <legend>{{ formTitle }}</legend>
                        <div class="form-row">
                            <div class="form-group col-md-5">
                                <label for="newPassword">Nuova password</label>
                                <div class="input-group mb-3">
                                    <input [type]="showNewPwd ? 'text' : 'password'" [ngClass]="{
                                        'form-control': true,
                                        'is-invalid':
                                        firstAttempt && formCommons.isFieldInvalid(form, 'newPassword')
                                    }" aria-describedby="newPasswordSmall" formControlName="newPassword" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button"
                                            (click)="toggleShowNewPwd()">
                                            <i [class]="
                                            showNewPwd
                                            ? 'fas fa-solid fa-eye-slash'
                                            : 'fas fa-solid fa-eye'
                                        "></i>
                                        </button>
                                    </div>
                                    <small id="newPasswordSmall" class="w-100 invalid-feedback">
                                        <p
                                            *ngIf="form.controls.newPassword.errors && form.controls.newPassword.errors.required">
                                            Il campo &egrave; obbligatorio</p>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-5">
                                <label for="confirmNewPassword">Conferma nuova password</label>
                                <div class="input-group mb-3">
                                    <input [type]="showConfirmNewPwd ? 'text' : 'password'" [ngClass]="{
                                        'form-control': true,
                                        'is-invalid':
                                        firstAttempt && formCommons.isFieldInvalid(form, 'confirmNewPassword')
                                    }" aria-describedby="confirmNewPasswordSmall"
                                        formControlName="confirmNewPassword" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button"
                                            (click)="toggleShowConfirmNewPwd()">
                                            <i [class]="
                                            showConfirmNewPwd
                                            ? 'fas fa-solid fa-eye-slash'
                                            : 'fas fa-solid fa-eye'
                                        "></i>
                                        </button>
                                    </div>
                                    <small id="confirmNewPasswordSmall" class="w-100 invalid-feedback">
                                        <p
                                            *ngIf="form.controls.confirmNewPassword.errors && form.controls.confirmNewPassword.errors.notEquivalent">
                                            Le password non coincidono</p>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type="reset" class="btn btn-light mr-2">Svuota campi</button>
                            <button type="submit" class="btn btn-primary">Salva</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>