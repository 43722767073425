<div class="row">
  <div class="col-md-3 col-sm-12">
    <app-left-menu></app-left-menu>
  </div>
  <div class="col-md-9 col-sm-12">
    <div class="justify-content-center page-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
