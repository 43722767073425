import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {AppInjector} from '../helpers/app-injector';
import { AlertService } from '../utility/alert-service';


export class BaseService {
    protected serviceUrl: string;

    alertService = AppInjector.get(AlertService);

    constructor(protected http: HttpClient, serviceUrl: string) {
        this.serviceUrl = environment.context + serviceUrl;
    }
    
    get(path?: string, options?: any): Promise<any> {
        try {
            let url = this.serviceUrl;
            if (url) {
                url = `${url}`;
                // this.alertService.addSuccessMessage('serviceURL:', url);
            } else {
                this.alertService.addErrorMessage('Errore','URL mancante');
            }  
            if (path) {
                url = `${url}/${path}`;
                // this.alertService.addInfoMessage('serviceURL (url/path):', url);
            // } else {
                // this.alertService.addInfoMessage('Info GET','PATH mancante. Uso alternativo di URL:'+url);
            }  
            // if (options) {
            //     this.alertService.addSuccessMessage('options:', options);
            // }
        return this.http.get(url, options)
            .toPromise()
            .catch(this.handleError);
        } catch (error) {
            if (error.status && error.status === 500) {
                this.alertService.addErrorMessage('Errore 500', 'Errore interno del server');
            } else {
                this.alertService.addErrorMessage('Errore', error.message);
            }
            return Promise.reject(error);
        }
    }

    post(path?: string, body?: any, options?: any): Promise<any> {
        let url = this.serviceUrl;
        if (path) {
            url = `${url}/${path}`;
        }
        return this.http.post(url,body,options)
            .toPromise()
            .catch(this.handleError);

    }

    put(path?: string, body?: any, options?: any): Promise<any> {
        let url = this.serviceUrl;
        if (path) {
            url = `${url}/${path}`;
        }
        return this.http.put(url,body,options)
            .toPromise()
            .catch(this.handleError);
    }

    downloadFile(path?: string, options?: any): Promise<any> {
        return this.get(path, options).then(result => {
            const fileName = result?.fileName + result?.extension;
            const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            this.downloadFileBase64(result?.fileContent, contentType, fileName); 
        })
    }

    downloadFileMassivo(path?: string, options?: any): Promise<any> {
        return this.get(path, options).then(result => {
            const fileName = result?.fileNameMassivo + result?.extensionMassivo;
            const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            this.downloadFileBase64(result?.fileContentMassivo, contentType, fileName); 
        })
    }

    b64toBlob(b64Data, contentType='', sliceSize=512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }
    
      downloadFileBase64(contentBase64, contentType, fileName) {
        var blob = this.b64toBlob(contentBase64,contentType);
        this.doDownloadFile(blob, fileName);
      }
    
      doDownloadFile(blob, fileName) {
    //TODO internet explorer
    //     if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
    //       window.navigator.msSaveOrOpenBlob(blob, fileName);
    //   } else { // for Non-IE (chrome, firefox etc.)
          var a = document.createElement("a");
          document.body.appendChild(a);                    
          var url = URL.createObjectURL(blob);
          a.href =  url;
          a.download = fileName;
          a.target="_blank"
          a.click();
          URL.revokeObjectURL(a.href)
          a.remove();
      //}
    }


    protected handleError(error: any): Promise<any> {
        //TODO: gestire eventuale errore generico?
        if(error.status === 500) {
            //this.alertService.addErrorMessage('Errore',error.message);
        }
        return Promise.reject(error);
    }

}
