import { HttpClient } from '@angular/common/http';
import { MonitoraggioService } from './monitoraggio-service';
import { Injectable } from '@angular/core';
import { AlertService } from '../utility/alert-service';
import { UtilityService } from '../utility/utility.service';
import { AppInjector } from '../helpers/app-injector';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class MonitoraggiMensiliMsService extends MonitoraggioService {
  alertService = AppInjector.get(AlertService);
  utilityService = AppInjector.get(UtilityService);

  user: BehaviorSubject<any>;

  constructor(protected http: HttpClient, protected spinner: NgxSpinnerService, alertService:AlertService) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/monitoraggi-mensili-ms`, spinner, alertService);
  }

}
