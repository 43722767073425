import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/service/user-service';
import { DatiAnagraficiService } from 'src/app/service/dati-anagrafici-service';
import {MonitoraggiBisettimanaliTiService} from '../../service/monitoraggi-bisettimanali-ti-service';
import {MonitoraggiBisettimanaliMsService} from '../../service/monitoraggi-bisettimanali-ms-service';
import {MonitoraggiBisettimanaliPsService} from '../../service/monitoraggi-bisettimanali-ps-service';
import {TIPO_MON_QUINDICINALE, TIPO_MON_MENSILE} from '../../tipologiche/tipo-monitoraggio';
import {CO_MON_QUINDICINALE_TI, CO_MON_QUINDICINALE_PS, CO_MON_QUINDICINALE_MS} from '../../tipologiche/contesto-operativo';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-monitoraggio-periodico',
  templateUrl: './modal-monitoraggio-periodico.component.html',
  styleUrls: ['./modal-monitoraggio-periodico.component.css']
})
export class ModalMonitoraggioPeriodicoComponent implements OnInit {
  _pageStatus: string;
  _modalTitle: string;
  _monitoraggio: any;

  get monitoraggio() {
    return this._monitoraggio;
  }

  @Input()
  set monitoraggio(value) {
    this._monitoraggio = value;
  }

  get pageStatus() {
    return this._pageStatus;
  }

  @Input()
  set pageStatus(value) {
    this._pageStatus = value;
  }

  get modalTitle() {
    return this._modalTitle;
  }

  @Input()
  set modalTitle(value) {
    this._modalTitle = value;
  }

  active: number = 1;
  firstAttempt: boolean;
  periodoRiferimento: any;
  stabilimenti: any[]=[];
  localMonitoraggioPL: any;
  localMonitoraggioMS: any;
  localMonitoraggioPS: any;

  _selectedStabilimento: any;

  get selectedStabilimento() {
    return this._selectedStabilimento;
  }

  set selectedStabilimento(value) {
    this._selectedStabilimento = value;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService, 
    private datiAnagraficiService: DatiAnagraficiService,
    private monitoraggioBisettimanaleTiService: MonitoraggiBisettimanaliTiService,
    private monitoraggiBisettimanaliMsService: MonitoraggiBisettimanaliMsService,
    private monitoraggiBisettimanaliPsService: MonitoraggiBisettimanaliPsService
    ) {}

  ngOnInit(): void {
    this.loadStabilimenti();
  }

  loadMonitoraggio(){
    this.loadPostiLetto();
    this.loadMezziSoccorso();
    this.loadProntoSoccorso();
  }

  loadPostiLetto() {
    //TODO id anagrafica del monitoraggio
    let idAnagrafica = this.userService.getIdAnagrafica();
    //localMonitoraggioPL contiene quella attualmente in modifica.
    this.monitoraggioBisettimanaleTiService.getDefault(TIPO_MON_QUINDICINALE, idAnagrafica, CO_MON_QUINDICINALE_TI).then (result => {
      this.periodoRiferimento = result?.monitoraggioBisettimanale?.monitoraggio?.calendarioMonitoraggio;

      this.localMonitoraggioPL = {
        plTiHsp2019Cod49: result?.plTiHsp2019Cod49,
        plTiProgConvModLg77: result?.plTiProgConvModLg77,
        plTiProgConvModLg77Agg: result?.plTiProgConvModLg77Agg,
        plTiProgConvModLg77Agg2: result?.plTiProgConvModLg77Agg2,
        plTsiProgConvModLg77: result?.plTsiProgConvModLg77,
        plTsiProgConvModLg77Agg: result?.plTsiProgConvModLg77Agg,
        plTsiProgConvModLg77Agg2: result?.plTsiProgConvModLg77Agg2,
        plTsiAttivati: result?.plTsiAttivati,
        plTsiConvertibili: result?.plTsiConvertibili,
        dataRilevazione: result?.dataRilevazione,
        isDefault: result?.monitoraggioBisettimanale?.monitoraggio?.isDefault
      };
    }).catch(error => {
      if(error.status === 404 || error.status === 500) {
        this.localMonitoraggioPL = {
          isDefault: false
        };
      }
    })
  }

  loadMezziSoccorso() {
    //TODO id anagrafica del monitoraggio
    let idAnagrafica = this.userService.getIdAnagrafica();
    //localMonitoraggioMS contiene quella attualmente in modifica.
    this.monitoraggiBisettimanaliMsService.getDefault(TIPO_MON_QUINDICINALE, idAnagrafica, CO_MON_QUINDICINALE_MS ).then (result => {
      this.periodoRiferimento = result?.monitoraggioBisettimanale?.monitoraggio?.calendarioMonitoraggio;

      this.localMonitoraggioMS = {
        daAcquistare: result?.daAcquistare,
        acquistate: result?.acquistate,
        acquistateAgg: result?.acquistateAgg,
        consegnateCollaudate: result?.consegnateCollaudate,
        consegnateCollaudateAgg: result?.consegnateCollaudateAgg,
        dataRilevazione: result?.dataRilevazione,
        isDefault: result?.monitoraggioBisettimanale?.monitoraggio?.isDefault
      };
    }).catch(error => {
      if(error.status === 404 || error.status === 500) {
        this.localMonitoraggioMS = {
          isDefault: false
        };
      }
    })
  }

  loadProntoSoccorso() {
    this.firstAttempt = false;
    if(!this.selectedStabilimento) {
      this.localMonitoraggioPS = {
        isDefault: false,
      }
      return;
    }
    //TODO id anagrafica del monitoraggio
    let idAnagrafica = this.selectedStabilimento.id;
    //localMonitoraggioPS contiene quella attualmente in modifica.
    this.monitoraggiBisettimanaliPsService.getDefault(TIPO_MON_QUINDICINALE, idAnagrafica, CO_MON_QUINDICINALE_PS ).then (result => {
      this.periodoRiferimento = result?.monitoraggioBisettimanale?.monitoraggio?.calendarioMonitoraggio;

      this.localMonitoraggioPS = {
        interventiPrevisti: result?.interventiPrevisti,
        intervRistrInCorso: result?.intervRistrInCorso,
        intervRistrConclusi: result?.intervRistrConclusi,
        dataPrevistaConclusione: result?.dataPrevistaConclusione,
        dataRilevazione: result?.dataRilevazione,
        isDefault: result?.monitoraggioBisettimanale?.monitoraggio?.isDefault
      };
    }).catch(error => {
      if(error.status === 404 || error.status === 500) {
        this.localMonitoraggioPS = {
          isDefault: false
        };
      }
    })
  }

  loadStabilimenti() {
    //TODO id anagrafica del monitoraggio
    let idAnagrafica = this.userService.getIdAnagrafica();
    this.datiAnagraficiService.getStabilimentiByIdAnagrafica(idAnagrafica).then (result => {
      this.stabilimenti = result;
    }).catch(error => {
      if(error.status === 404) {
        this.stabilimenti = [];
      }
    })
  }

  getSubmitBody(contestoOperativo: string, idAnagrafica: string) {
    let body = {
      idUtenteInserimento: this.userService.getIdUtente(),
      monitoraggioBisettimanale: {
        idAnagrafica,
        monitoraggio: {
          idAnagrafica: this.userService.getIdAnagrafica(),
          idUtente: this.userService.getIdUtente(),
          calendarioMonitoraggio: {
            id: this.periodoRiferimento.id
          }
        },
        contestoOperativo: {
          id: contestoOperativo
        },
      }
    }
    return body;
  }

  /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
  preSubmitPostiLetto(values: any) {
    this.localMonitoraggioPL = values;
    this.firstAttempt = true;
  }

  onUpdatePostiLetto(values) {
    //post di value
    let body = this.getSubmitBody(CO_MON_QUINDICINALE_TI, this.userService.getIdAnagrafica());
    body =  {
      ...body,
      ...values
    }
    this.monitoraggioBisettimanaleTiService.update(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadPostiLetto();
    })
  }

  /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
  preSubmitMezziSoccorso(values: any) {
    this.localMonitoraggioMS = values;
    this.firstAttempt = true;
  }

  onSubmitMezziSoccorso(values: any) {
    //post di value
    let body = this.getSubmitBody(CO_MON_QUINDICINALE_MS, this.userService.getIdAnagrafica());
    body =  {
      ...body,
      ...values
    }
    this.monitoraggiBisettimanaliMsService.update(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadMezziSoccorso();
    })
  }

  /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
   preSubmitProntoSoccorso(values: any) {
    this.localMonitoraggioPS = values;
    this.firstAttempt = true;
  }

  onSubmitProntoSoccorso(values: any) {
    //post di value
    let body = this.getSubmitBody(CO_MON_QUINDICINALE_PS, this.selectedStabilimento.id);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggiBisettimanaliPsService.update(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadProntoSoccorso();
    })
  }

}
