<div class="card custom-card-no-top-border">
    <div class="card-body">
      <fieldset>
        <div class="form-row">
          <div class="col-md-5">
            <label for="stabilimento">Stabilimento</label>
            <select
              [ngClass]="{
                'form-control custom-select': true,
                'is-invalid':false}"
              id="stabilimento"
              [(ngModel)]="selectedStabilimento"
              (change)="changeSelectStabilimento()"
            >
            <option></option>
              <ng-container *ngFor="let stabilimento of stabilimenti">
                <option [ngValue]="stabilimento">{{ stabilimento.denominazioneStabilimento }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </fieldset>
      <div class="card mt-3">
        <div class="card-body">
          <form [formGroup]="form" (ngSubmit)="submit()">
            <fieldset>
              <legend>{{ formTitle }}</legend>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="dataRilevazione"
                    >Data rilevazione</label
                  >
                  <div class="input-group mb-2">
                    <input
                      type="text"
                      [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                          shouldValidate && formCommons.isFieldInvalid(form, 'dataRilevazione')
                      }"
                      class="form-control"
                      id="dataRilevazione"
                      aria-describedby="dataRilevazioneSmall"
                      formControlName="dataRilevazione"
                      placeholder="gg/mm/aaaa"
                      ngbDatepicker 
                      #dataRilevazioneDatePicker="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        (click)="dataRilevazioneDatePicker.toggle()"
                      >
                        <i class="fas fa-calendar"></i>
                      </button>
                    </div>
                    <small id="dataRilevazioneSmall" class="invalid-feedback">
                      <p *ngIf="form.controls.dataRilevazione.errors && form.controls.dataRilevazione.errors.required">Il campo &egrave; obbligatorio</p>
                      <p *ngIf="form.controls.dataRilevazione.errors && form.controls.dataRilevazione.errors.ngbDate">La data non &egrave; in un formato valido</p>
                    </small>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="interventiPrevisti"
                    >N. interventi previsti</label
                  >
                  <input
                    type="number"
                    [ngClass]="{
                      'form-control': true,
                      'is-invalid':
                        shouldValidate &&
                        formCommons.isFieldInvalid(form, 'interventiPrevisti')
                    }"
                    min="0"
                    class="form-control"
                    id="interventiPrevisti"
                    formControlName="interventiPrevisti"
                    aria-describedby="interventiPrevistiSmall"
                    onkeydown="return event.keyCode !== 69"
                  />
                  <small id="interventiPrevistiSmall" class="invalid-feedback">
                    <p *ngIf="form.controls.interventiPrevisti.errors && form.controls.interventiPrevisti.errors.required">Il campo &egrave; obbligatorio</p>
                    <p *ngIf="form.controls.interventiPrevisti.errors && form.controls.interventiPrevisti.errors.min">Il valore minimo consentito &egrave; 0</p>
                    <p *ngIf="form.controls.interventiPrevisti.errors && form.controls.interventiPrevisti.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                  </small>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="intervRistrConclusi"
                      >Realizzazione interventi di ristrutturazione dei pronto soccorso (periodo precedente)</label
                  >
                  <input
                      type="number"
                      [ngClass]="{
                      'form-control': true,
                      'is-invalid':
                          shouldValidate &&
                          formCommons.isFieldInvalid(form, 'intervRistrConclusi')
                      }"
                      min="0"
                      class="form-control"
                      id="intervRistrConclusi"
                      formControlName="intervRistrConclusi"
                      aria-describedby="intervRistrConclusiSmall"
                      onkeydown="return event.keyCode !== 69"
                  />
                  <small id="intervRistrConclusiSmall" class="invalid-feedback">
                      <p *ngIf="form.controls.intervRistrConclusi.errors && form.controls.intervRistrConclusi.errors.required">Il campo &egrave; obbligatorio</p>
                      <p *ngIf="form.controls.intervRistrConclusi.errors && form.controls.intervRistrConclusi.errors.min">Il valore minimo consentito &egrave; 0</p>
                      <p *ngIf="form.controls.intervRistrConclusi.errors && form.controls.intervRistrConclusi.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                  </small>
                </div>
                <div class="form-group col-md-6">
                  <label for="intervRistrInCorso"
                      >Realizzazione interventi di ristrutturazione dei pronto soccorso (periodo attuale)</label
                  >
                  <input
                      type="number"
                      [ngClass]="{
                      'form-control': true,
                      'is-invalid':
                          shouldValidate &&
                          formCommons.isFieldInvalid(form, 'intervRistrInCorso')
                      }"
                      min="0"
                      class="form-control"
                      id="intervRistrInCorso"
                      formControlName="intervRistrInCorso"
                      aria-describedby="intervRistrInCorsoSmall"
                      onkeydown="return event.keyCode !== 69"
                  />
                  <small id="intervRistrInCorsoSmall" class="invalid-feedback">
                      <p *ngIf="form.controls.intervRistrInCorso.errors && form.controls.intervRistrInCorso.errors.required">Il campo &egrave; obbligatorio</p>
                      <p *ngIf="form.controls.intervRistrInCorso.errors && form.controls.intervRistrInCorso.errors.min">Il valore minimo consentito &egrave; 0</p>
                      <p *ngIf="form.controls.intervRistrInCorso.errors && form.controls.intervRistrInCorso.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                  </small>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="dataPrevistaConclusione"
                    >Data prevista conclusione</label
                  >
                  <div class="input-group mb-2">
                    <input
                      type="text"
                      [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                          shouldValidate && formCommons.isFieldInvalid(form, 'dataPrevistaConclusione')
                      }"
                      class="form-control"
                      id="dataPrevistaConclusione"
                      aria-describedby="dataPrevistaConclusioneSmall"
                      formControlName="dataPrevistaConclusione"
                      placeholder="gg/mm/aaaa"
                      ngbDatepicker 
                      #dataPrevistaConclusioneDatePicker="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        (click)="dataPrevistaConclusioneDatePicker.toggle()"
                      >
                        <i class="fas fa-calendar"></i>
                      </button>
                    </div>
                    <small id="dataPrevistaConclusioneSmall" class="invalid-feedback">
                      <p *ngIf="form.controls.dataPrevistaConclusione.errors && form.controls.dataPrevistaConclusione.errors.required">Il campo &egrave; obbligatorio</p>
                      <p *ngIf="form.controls.dataPrevistaConclusione.errors && form.controls.dataPrevistaConclusione.errors.ngbDate">La data non &egrave; in un formato valido</p>
                    </small>
                  </div>
                </div>
              </div>
              <div *ngIf="this.monitoraggioProntoSoccorso && this.monitoraggioProntoSoccorso.isDefault !== false">
                <button type="reset" class="btn btn-light mr-2">Svuota campi</button>
                <button type="submit" class="btn btn-primary">Salva</button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
  