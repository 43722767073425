<div class="container-header sezione-border">
  <div class="header">
    <div class="logoRegioneToscana" (click)="goHome()">
      <img
        alt="Regione-Toscana"
        src="assets/img/logoToscana.png"
        style="height: 70px"
      />
      <img
        alt="SST"
        style="height: 70px; margin-left: 3px"
        src="assets/img/Logo_SST.png"
      />
      <h1 class="h1Title">Monitoraggio mensile interventi<br/>
        <h5 style="text-align: center; font-weight: bold;">Art. 2 Decreto Legge 19 maggio 2020 n. 34</h5>
      </h1>
    </div>
    <div *ngIf="user && user.username" class="card mx-auto" style="width: 20rem">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p><strong>Nominativo</strong> {{ user.username }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p><strong>Profilo</strong> {{ user.nome_ruolo?.toUpperCase() === 'ADMIN' ? 'AMMINISTRATORE' :  user.nome_ruolo?.toUpperCase() === 'USER' ? 'UTENTE' : user.nome_ruolo}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p><strong>Ente riferimento</strong> {{ user.ente_riferimento }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              (click)="logout()"
            >
              Logout <i class="fas fa-sign-out-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
