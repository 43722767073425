<app-container>
    <h3>Ricerca Dati Monitoraggio Mensile</h3>
    <app-ricerca-monitoraggio
        [tipoMonitoraggio] = "tipoMonitoraggio"
        [ricercaMonitoraggioList] = "ricercaMonitoraggioMensileList"
        (onSearch)="searchMonitoraggioMensile($event)"
        (onDownload)="downloadMonitoraggioMensile($event)"
        (onDownloadMassivo)="downloadMonitoraggioMensileMassivo()"
        (onInviaMinistero)="confirmInviaAlMinisteroMonitoraggioMensile($event)"
    >
    </app-ricerca-monitoraggio>
</app-container>