<div class="modale-background">
    <div class="modale-content">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalMonitoraggioPeriodicoTitle">{{modalTitle}}</h5>
                    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <div class="justify-content-center page-content">
                                <h3>Dati Monitoraggio</h3>
                                <p *ngIf="periodoRiferimento">Periodo di riferimento:
                                    <strong>{{periodoRiferimento.dataInizioInserimento}}</strong> -
                                    <strong>{{periodoRiferimento.dataFineInserimento}}</strong></p>

                                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs mt-5">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>Posti Letto Terapia Intensiva e Sub Intensiva</a>
                                        <ng-template ngbNavContent>
                                            <app-monitoraggio-posti-letto [monitoraggio]="localMonitoraggioPL"
                                                [periodoRiferimento]="periodoRiferimento"
                                                [shouldValidate]="firstAttempt" [pageStatus]="pageStatus"
                                                (preSubmit)="preSubmitPostiLetto($event)"
                                                (onSubmit)="onUpdatePostiLetto($event)"></app-monitoraggio-posti-letto>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink>Interventi Pronto Soccorso</a>
                                        <ng-template ngbNavContent>
                                            <app-monitoraggio-pronto-soccorso [stabilimenti]="stabilimenti"
                                                [(selectedStabilimento)]="selectedStabilimento"
                                                [monitoraggioProntoSoccorso]="localMonitoraggioPS"
                                                [periodoRiferimento]="periodoRiferimento"
                                                [shouldValidate]="firstAttempt" [pageStatus]="pageStatus"
                                                (changeSelect)="loadProntoSoccorso()"
                                                (preSubmit)="preSubmitProntoSoccorso($event)"
                                                (onSubmit)="onUpdateProntoSoccorso($event)">
                                            </app-monitoraggio-pronto-soccorso>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink>Interventi mezzi di soccorso</a>
                                        <ng-template ngbNavContent>
                                            <app-monitoraggio-mezzi-soccorso
                                                [monitoraggioMezziSoccorso]="localMonitoraggioMS"
                                                [periodoRiferimento]="periodoRiferimento"
                                                [shouldValidate]="firstAttempt" [pageStatus]="pageStatus"
                                                (preSubmit)="preSubmitMezziSoccorso($event)"
                                                (onSubmit)="onUpdateMezziSoccorso($event)">
                                            </app-monitoraggio-mezzi-soccorso>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav" class="mt-0"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button *ngIf="modal_global.testoBottoneAnnulla" type="button" class="btn btn-primary"
                        (click)="modal_global.funzioneBottoneAnnulla()"
                        data-dismiss="modal">{{modal_global.testoBottoneAnnulla}}</button>
                    <button type="button" class="btn btn-primary" (click)="modal_global.funzioneBottoneOK()"
                        data-dismiss="modal">{{modal_global.testoBottoneOK}}</button>
                </div> -->
            </div>
        </div>
    </div>
</div>