export const informativa_campi = {
    SERVIZI_ORDINE_ATTIVAZIONE: "Indicare dettagli relativi agli ordini di attivazione",
    SERVIZI_STATO_ATTUAZIONE: "Indicare l'effettivo stato di attuazione dell'intervento descrivendo l'attuale fase di avanzamento relativamente ai servizi di progettazione",
    SERVIZI_MESI_COMPLESSIVI: 'Specificare  la durata complessiva in mesi della fase di attuazione indicata nella colonna "Stato di attuazione". (es. se lo stato di attuazione è "predisposizione del progetto esecutivo" da svolgere in 8 mesi, indicare 8)',
    SERVIZI_PERCENTUALE_ATTUAZIONE: 'Indicare la percentuale di avanzamento dello stato di attuazione riferito al mese oggetto di monitoraggio (es. se lo stato di attuazione è "predisposizione del progetto esecutivo" da svolgere in 8 mesi, indicare la percentuale di avanzamento della progettazione al 28 febbraio 2021)',
    SERVIZI_NOTE: "In questa sezione è possibile riportare eventuali note relative a criticità, problematiche e eventuali cause ostative che hanno impedito l'avvio dei servizi di progettazione. Inoltre, con specifico rifertimento agli interventi che prevedono l'attivazione di entrambe le tipologie di p.l., è possibile utilizzare questo campo per indicare l'impossibilità di distinguere gli stati di attivazione.",
    LAVORI_ORDINE_ATTIVAZIONE: "Indicare dettagli relativi agli ordini di attivazione",
    LAVORI_STATO_ATTUAZIONE: "Indicare l'effettivo stato di attuazione dell'intervento descrivendo l'attuale fase di avanzamento relativamente all'esecuzione lavori",
    LAVORI_MESI_COMPLESSIVI: 'Specificare la durata complessiva in mesi della fase di attuazione indicata nella colonna "Stato di attuazione". (es. se lo stato di attuazione è "lavori in corso" da svolgere in 12 mesi, indicare 12 - numero intero)',
    LAVORI_PERCENTUALE_ATTUAZIONE: 'Indicare la percentuale di avanzamento dello stato di attuazione riferito al mese oggetto di monitoraggio (es. se lo stato di attuazione è "lavori in corso" da svolgere in 8 mesi, indicare la percentuale di avanzamento della progettazione al 28 febbraio 2021)',
    LAVORI_NOTE: "In questa sezione è possibile riportare eventuali note relative a criticità, problematiche e eventuali cause ostative che hanno impedito l'avvio dei servizi di progettazione. Inoltre, con specifico rifertimento agli interventi che prevedono l'attivazione di entrambe le tipologie di p.l., è possibile utilizzare questo campo per indicare l'impossibilità di distinguere gli stati di attivazione.",
    ATTR_STATO_ATTUAZIONE: "Indicare l'effettivo stato di attuazione dell'intervento descrivendo l'attuale fase di avanzamento relativamente all'acquisizione delle tecnologie",
    ATTR_IMPORTO: "Specificare l'importo totale ordini attivati",
    ATTR_MESI_COMPLESSIVI: 'Indicare la percentuale di avanzamento dello stato di attuazione riferito al mese oggetto di monitoraggio (es. se lo stato di attuazione è "in fase di collaudo" da svolgere in 2 mesi, indicare 2 - numero intero).',
    ATTR_NOTE: "In questa sezione è possibile riportare eventuali note relative a criticità, problematiche e eventuali cause ostative che hanno impedito l'avvio dei servizi di progettazione. Inoltre, con specifico rifertimento agli interventi che prevedono l'attivazione di entrambe le tipologie di p.l., è possibile utilizzare questo campo per indicare l'impossibilità di distinguere gli stati di attivazione.",
    DURATA_INTERVENTO: "Indicare la durata complessiva dell'intervento espressa in mesi (comprensiva di tutte le fasi) come numero intero",
    DATA_CONCLUSIONE_INTERVENTO: "Indicare la data prevista di conclusione dell'intervento (come data in cifre)",
    AMBULANZE_STATO_ATTUAZIONE: "Indicare l'effettivo stato di attuazione dell'intervento descrivendo l'attuale fase di avanzamento relativamente all'acquisizione dei mezzi di soccorso",
    AMBULANZE_TEMPISTICHE_PREVISTE: "Indicare la previsione della durata complessiva, espressa in mesi, per l'acquisto e l'operatività delle ambulanze",
    NOTE: "In questa sezione è possibile riportare eventuali note relative a criticità, problematiche e eventuali cause ostative che hanno impedito l'avvio dei servizi di progettazione. Inoltre, con specifico rifertimento agli interventi che prevedono l'attivazione di entrambe le tipologie di p.l., è possibile utilizzare questo campo per indicare l'impossibilità di distinguere gli stati di attivazione."

}

