import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { Injectable } from '@angular/core';
import { AlertService } from '../utility/alert-service'
import {AppInjector} from '../helpers/app-injector';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CodiciInterventoService extends BaseService {

  alertService = AppInjector.get(AlertService);

  constructor(http: HttpClient) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/codici-intervento`);
  }

  getCodiciInterventoByIdAnagraficaIdContesto(idAnagrafica: string, idContesto: string ): Promise<any> {
    return super.get(`${idAnagrafica}/${idContesto}`);
  }

  getCodiceInterventoByIdCodice(idCodice: any ): Promise<any> {
    return super.get(`${idCodice}`);
  }
}
