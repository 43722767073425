import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { Injectable } from '@angular/core';
import { AlertService } from '../utility/alert-service'
import {AppInjector} from '../helpers/app-injector';
import {UserService} from './user-service';
import { pipe } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DatiAnagraficiService extends BaseService {

  alertService = AppInjector.get(AlertService);

  constructor(http: HttpClient) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/dati-anagrafici`);
  }

  getStabilimentiByIdAnagrafica(idAnagrafica: string): Promise<any> {
    return super.get(`strutture/${idAnagrafica}`);
  }
  getRicercaAziende(): Promise<any> {
    return super.get(``);
  }
  getAziende(): Promise<any> {
    return super.get(`aziende/`);
  }

}
