import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormCommons } from 'src/app/utility/commons';
import { AppInjector } from '../../helpers/app-injector';
import { UserService } from 'src/app/service/user-service';

@Component({
  selector: 'app-modifica-dati-utente',
  templateUrl: './modifica-dati-utente.component.html',
  styleUrls: ['./modifica-dati-utente.component.css'],
})
export class ModificaDatiUtenteComponent implements OnInit, OnDestroy {
  formCommons = AppInjector.get(FormCommons);
  private _formValue: FormGroup;
  // public utenti: any;
  @Input()
  utenti: any[] = [];
  @Input()
  username: any;

  @Input()
  get form(): FormGroup {
    return this._formValue;
  }

  set form(form: FormGroup) {
    this._formValue = form;
  }

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = '';

  @Input()
  ruoli: any[] = [];

  @Input()
  aziende: any[] = [];

  @Output()
  generateRandomPassword = new EventEmitter<void>();

  @Output()
  formChange = new EventEmitter<FormGroup>();

  @Output()
  onSubmit = new EventEmitter<void>();

  @Output()
  copyPasswordEvent = new EventEmitter<void>();

  @Output()
  changeSelezionaUtentePerModificaOutput = new EventEmitter<void>();

  _selectedUtente: any;
  aziendaSelezionata: String = "";
  ruoloSelezionato: String = "";

  get selectedUtente() {
    return this._selectedUtente;
  }

  @Input()
  set selectedUtente(value) {
    this._selectedUtente = value;
  }

  usernameSub: Subscription;

  constructor(
    private userservice:UserService 
  ) {}

  ngOnInit(): void {
    if (this.pageStatus === 'NEW') {
      this.generatePassword();
    }
    let usernameControl = this.form.get('username');
    this.usernameSub = usernameControl.valueChanges.subscribe(() => {
      if (usernameControl.value) {
        usernameControl.patchValue(usernameControl.value.toUpperCase(), {
          emitEvent: false,
        });
      }
    });
    this.loadUsersForEditing()

  }
  loadUsersForEditing() {
    this.userservice.getAllUsersForEditing().then((data) => {
      this.utenti = data;
      // console.log(this.utenti);
    }).catch((error) => {
      console.error('Si è verificato un errore durante il recupero dei dati degli utenti per la modifica: ', error);
    });
  }
  ngOnDestroy(): void {
    this.usernameSub.unsubscribe();
  }

  copyPassword() {
    this.copyPasswordEvent.emit();
  }

  generatePassword() {
    this.generateRandomPassword.emit();
  }

  save() {
    this.onSubmit.emit();
  }

  
  // onClickSelezionaUtente(utente: number){
  //   console.log("utente: "+utente);
  //   const selectedUser = this.utenti.find(user => user.id === utente);
  
  //   if (selectedUser) {
  //     this.form.patchValue({
  //       username: selectedUser.username,
  //       id_utente: selectedUser.id,
  //       email: selectedUser.email,
  //       ente_riferimento: selectedUser.ente_riferimento,
  //       ruolo_presso_ente: selectedUser.ruolo_presso_ente
  //     });
  //     // console.log("azienda selectedUser.azienda: "+this.aziende.find(azienda => azienda.id === selectedUser.id_dati_anagrafici));
  //     const aziendaSelezionata = this.aziende.find(azienda => azienda.id === selectedUser.id_dati_anagrafici);
  //     this.aziendaSelezionata = aziendaSelezionata.id;
  //     // console.log("azienda selezionata: "+this.aziendaSelezionata);
  //     const ruoloSelezionato= this.ruoli.find(ruolo => ruolo.id === selectedUser.id_ruolo);
  //     this.ruoloSelezionato = ruoloSelezionato.id;
  //     // this.form.patchValue({ azienda: aziendaSelezionata });
  //     // this.form.patchValue({ ruolo: ruoloSelezionato });
  //   } else {
  //     console.error('Utente non trovato');
  //   }
  // }

  onClickSelezionaUtente(utente: number){
    console.log("utente: "+utente);
    const selectedUser = this.utenti.find(user => user.id === utente);
 
    if (selectedUser) {
      const aziendaSelezionata = this.aziende.find(azienda => azienda.id === selectedUser.id_dati_anagrafici);
      const ruoloSelezionato= this.ruoli.find(ruolo => ruolo.id === selectedUser.id_ruolo);
      this.form.patchValue({
        username: selectedUser.username,
        id_utente: selectedUser.id,
        email: selectedUser.email,
        ente_riferimento: selectedUser.ente_riferimento,
        ruolo_presso_ente: selectedUser.ruolo_presso_ente,
        azienda: aziendaSelezionata.id,
        ruolo: ruoloSelezionato.id
      });
      // console.log("azienda selectedUser.azienda: "+this.aziende.find(azienda => azienda.id === selectedUser.id_dati_anagrafici));
      // const aziendaSelezionata = this.aziende.find(azienda => azienda.id === selectedUser.id_dati_anagrafici);
      this.aziendaSelezionata = aziendaSelezionata.id;
      this.form.get('azienda').setValue(this.aziendaSelezionata);
 
      // console.log("azienda selezionata: "+this.aziendaSelezionata);
      // const ruoloSelezionato= this.ruoli.find(ruolo => ruolo.id === selectedUser.id_ruolo);
      this.ruoloSelezionato = ruoloSelezionato.id;
      this.form.get('ruolo').setValue( this.ruoloSelezionato);
      // this.form.patchValue({ azienda: aziendaSelezionata });
      // this.form.patchValue({ ruolo: ruoloSelezionato });
    } else {
      console.error('Utente non trovato');
    }
  }

  onChangeSelectedUser(userId: number) {
    const selectedUser = this.utenti.find(user => user.id == userId);
    if (selectedUser) {
      const aziendaSelezionata = this.aziende.find(azienda => azienda.id === selectedUser.id_dati_anagrafici);
      const ruoloSelezionato= this.ruoli.find(ruolo => ruolo.id === selectedUser.id_ruolo);
      this.form.patchValue({
        username: selectedUser.username,
        id_utente: selectedUser.id,
        email: selectedUser.email,
        ente_riferimento: selectedUser.ente_riferimento,
        ruolo_presso_ente: selectedUser.ruolo_presso_ente,
        azienda: aziendaSelezionata.id,
        ruolo: ruoloSelezionato.id
      });
      this.aziendaSelezionata = aziendaSelezionata.id;
      this.form.get('azienda').setValue(this.aziendaSelezionata);
      this.ruoloSelezionato = ruoloSelezionato.id;
      this.form.get('ruolo').setValue( this.ruoloSelezionato);
    } else {console.error('Utente non trovato');}
  }
  isFieldInvalid(form: FormGroup, field: string) {
    let formField = form.get(field);
    if (formField?.untouched) {
        return false;
    }
    return formField?.invalid;
  }
  isErrore(form: any) {
    console.log("form: "+form);
  }
}
