import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MonitoraggiMensiliService extends BaseService {
 
  constructor(protected http: HttpClient, protected spinner: NgxSpinnerService) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/monitoraggi-mensili`);
  }

  search(jsonInput: any): Promise<any> {
    let jsonParams = {};
    jsonParams = {
      ...jsonParams,
      page: jsonInput.pageInfo.page,
      size: jsonInput.pageInfo.maxResults,
    }
    if(jsonInput.sortInfo){
      jsonParams = {
        ...jsonParams,
        sort: jsonInput.sortInfo.sortProperty,
        sortDirection: jsonInput.sortInfo.sortDirection,
      } 
    }
    if(jsonInput.idAzienda){
      jsonParams = {
        ...jsonParams,
        anagrafica: jsonInput.idAzienda,
      }
    }
    if(jsonInput.dataInizioPeriodo){
      jsonParams = {
        ...jsonParams,
        "data-inizio": jsonInput.dataInizioPeriodo,
      }
    }
    if(jsonInput.dataFinePeriodo){
      jsonParams = {
        ...jsonParams,
        "data-fine": jsonInput.dataFinePeriodo,
      }
    }
    this.spinner.show();
    return super.get('', {params: jsonParams}).then(result => {
      this.spinner.hide();
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  downladMonitoraggioConAzienda(idPeriodo, azienda){
    this.spinner.show();
    return super.downloadFile(`download/${idPeriodo}/${azienda}`).finally(() => {
      this.spinner.hide();
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  downladMonitoraggio(idPeriodo){
    this.spinner.show();
    return super.downloadFile(`download/${idPeriodo}`).finally(() => {
      this.spinner.hide();
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  // downloadMonitoraggioMassivo(){
  //   this.spinner.show();
  //   return super.downloadFile(`download-massivo/`).finally(() => {
  //     this.spinner.hide();
  //   }).catch(error => {
  //     this.spinner.hide();
  //     throw error;
  //   });
  // }
  downloadMassivo(){
    this.spinner.show();
    return super.downloadFileMassivo(`download-massivo/`).finally(() => {
      this.spinner.hide();
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  inviaMinistero(idPeriodo){
    this.spinner.show();
    return super.put(`${idPeriodo}`).then(() => {
      this.spinner.hide();
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }
}
