import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { Injectable } from '@angular/core';
import { AlertService } from '../utility/alert-service';
import { UtilityService } from '../utility/utility.service';
import { AppInjector } from '../helpers/app-injector';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  alertService = AppInjector.get(AlertService);
  utilityService = AppInjector.get(UtilityService);

  user: BehaviorSubject<any>;

  constructor(http: HttpClient, private spinner: NgxSpinnerService) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/utenti`);
    this.user = new BehaviorSubject<any>(this.getUserStored());
  }
  //Roberto Serranò
  getAllUsersForEditing(): Promise<any> {
    return super.get('modifica-utenti');
  }
  storeUser(user: any) {
    if (user){
    user = {
      ...user,
      password: undefined,
    };
    
    }
    // this.alertService.addInfoMessage('user: ',user);
    sessionStorage.setItem('usr',JSON.stringify(user));
    this.user.next(user);
  }

  getUserStored() {
    let usr = sessionStorage.getItem('usr');
    if(usr) {
      return JSON.parse(usr);
    } else {
      // this.alertService.addErrorMessage('Errore getUserStored','usr non importato correttamente');
      return {};
    } 
  }

  getIdUtente() {
    let usr = this.getUserStored();
    if (usr) {
      return usr.id;
    } else{
      this.alertService.addErrorMessage("ERRORE","usr non inizializzato!");
    }
  }

  getIdAnagrafica() {
    let usr = this.getUserStored();
    return usr.id_dati_anagrafici;
  }
  
  deleteUser() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('usr');
    this.user.next(null);
  }

  isAdmin() {
    let user = this.getUserStored();
    if (user && user.nome_ruolo?.toUpperCase() === "ADMIN") {
      return true;
    }
    return false;
  }

  hasResetPassword() {
    let user = this.getUserStored();
    if (user && user.flag_reset === false) {
      return true;
    }
    return false;
  }

  isUser() {
    let user = this.getUserStored();
    if (user && user.nome_ruolo?.toUpperCase() === "USER") {
      return true;
    }
    return false;
  }


  getAll(): Promise<any> {
    return super.get();
  }

  

  get(username: string): Promise<any> {
    return super.get(`${username}`).then((result) => {
      this.storeUser(result);
      return result;
    });
  }

  save(body: any): Promise<any> {
    this.spinner.show();
    return super.post('',body).finally(() => {
      this.spinner.hide();
    })
  }
  
  update(body: any): Promise<any> {
    console.log("update body: "+body);
    this.spinner.show();
    return super.put('modifica-utenti', body).finally(() => {
        this.spinner.hide();
        this.alertService.addSuccessMessage('', 'Utente aggiornato con successo! \n Si prega di aggiornare la pagina prima di effettuare altre modifiche.');
    });
  }

  changePassword(body: any): Promise<any> {
    this.spinner.show();
    return super.put('',body).then((result) => {
      this.spinner.hide();
    })
  }
}
