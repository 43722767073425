import { OnInit } from '@angular/core';
import { SortDirection } from 'src/app/directive/sortable-header.directive';
import { environment } from 'src/environments/environment';


export class SearchBaseComponent {

  constructor() { }

  // proprietà per gestire la paginazione del componente ng-bootstrap
  page: number;
  pageSize: number;
  collectionSize: number;
  

  setPaginatorValues (pageInfo) {
    if(pageInfo == undefined || pageInfo == null) {
      return;
    }
    this.page = pageInfo.page;
    this.pageSize = environment.itemsPerPage;
    this.collectionSize = pageInfo.totalCount;
  }

  initializePageInfo() {
    let pageInfo = {
      maxResults: environment.itemsPerPage,
      totalCount: 0,
      page: 1,
    }
    return pageInfo;
  }

  setPageInfo(page: number, pageSize: number, collectionSize: number) {
    const pageInfo = this.initializePageInfo();
    pageInfo.totalCount = collectionSize;
    pageInfo.maxResults = pageSize;
    pageInfo.page = page;
    return pageInfo;
  }

  setSortInfo(sortProperty: string, sortDirection: SortDirection) {
    return {
      sortProperty,
      sortDirection
    };
  }

  get numeroVoci(): string {
    let voci="";
    if (this.page && this.collectionSize && this.pageSize) {
      if((this.page * this.pageSize) > this.collectionSize) {
        voci = "" + (this.collectionSize);
      } else {
        voci = "" + (this.page * this.pageSize);
      }
      voci = voci.concat(" ").concat("di").concat(" ").concat("" + this.collectionSize);
    }
    return voci;
  }


}
