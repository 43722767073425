import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { ModalService } from 'src/app/service/modale/modal.service';
import { UserService } from 'src/app/service/user-service';
import { AlertService } from 'src/app/utility/alert-service';
import { FormCommons } from 'src/app/utility/commons';
import { RuoliService } from '../../service/ruoli-service';
import { DatiAnagraficiService } from '../../service/dati-anagrafici-service';

@Component({
  selector: 'app-inserimento-nuovo-utente',
  templateUrl: './inserimento-nuovo-utente.component.html',
  styleUrls: ['./inserimento-nuovo-utente.component.css'],
})
export class InserimentoNuovoUtenteComponent implements OnInit {
  form: FormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  ruoli: any[] = [];
  aziende: any[] = [];

  constructor(
    private ruoliService: RuoliService,
    private datiAnagraficiService: DatiAnagraficiService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private fc: FormCommons,
    private clipboardService: ClipboardService,
    private alertService: AlertService,
    private userService: UserService,
    private modalService: ModalService
  ) {
    this.formCommons = fc;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.maxLength(16), Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]],
      password: ['', [Validators.required, Validators.maxLength(100)]],
      ruolo: ['', Validators.required],
      azienda: ['', Validators.required],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(50)],
      ],
      ente_riferimento: ['', [Validators.required, Validators.maxLength(50)]],
      ruolo_presso_ente: ['', [Validators.required, Validators.maxLength(50)]],
    });
    this.ruoliService.getRuoli().then((result) => {
      this.ruoli = result;
    });
    this.datiAnagraficiService.getAziende().then((result) => {
      this.aziende = result;
    });
  }

  copyPassword() {
    this.clipboardService.copy(this.form.controls.password.value);
    this.alertService.addSuccessMessage('', 'Password copiata negli appunti');
  }

  generateRandomPassword() {
    let password = Math.random().toString(36).slice(-8);
    this.form.patchValue({
      password,
    });
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmSave();
  }

  confirmSave() {
    this.modalService.openModalGlobal(
      'Inserimento nuovo Utente',
      "Vuoi confermare la creazione dell'utente?",
      'Conferma',
      () => {
        this.save();
      },
      'Annulla',
      () => {
        this.modalService.closeModal();
      }
    );
  }

  save() {
    this.modalService.closeModal();
    const body = {
      username: this.form.controls.username.value,
      password: this.form.controls.password.value,
      id_ruolo: this.form.controls.ruolo.value.id,
      id_dati_anagrafici: this.form.controls.azienda.value.id,
      nome_ruolo: this.form.controls.ruolo.value.nomeRuolo,
      email: this.form.controls.email.value,
      ente_riferimento: this.form.controls.ente_riferimento.value,
      ruolo_presso_ente: this.form.controls.ruolo_presso_ente.value,
    };
    this.userService.save(body).then((result) => {
      this.form.reset();
      this.generateRandomPassword();
      this.alertService.addDefaultSuccessMessage();
    });
  }
}
