import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { DateUtils } from 'src/app/utility/date-utils';
import { CO_MON_MENSILE_MS } from '../../tipologiche/contesto-operativo';
import {informativa_campi} from 'src/app/utility/informativa_campi';

@Component({
  selector: 'app-monitoraggio-mensile-mezzi-soccorso',
  templateUrl: './monitoraggio-mensile-mezzi-soccorso.component.html',
  styleUrls: ['./monitoraggio-mensile-mezzi-soccorso.component.css']
})
export class MonitoraggioMensileMezziSoccorsoComponent implements OnInit {

  formCommons = AppInjector.get(FormCommons);
  form: FormGroup;
  contestoOperativo = CO_MON_MENSILE_MS;
  informativaCampi = informativa_campi;

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = '';

  @Input()
  datiAnagrafici: any;

  @Input()
  acquistoAmbulanze: any;

  @Input()
  statiAttuazioneAmbulanze: any;

  _monitoraggio: any;
  
  get monitoraggio(): any {
    return this._monitoraggio;
  };

  @Input()
  set monitoraggio(value: any) {
    this._monitoraggio = value;
    if(this.form) {
      if (value?.isDefault === false) {
        this.form.disable();
      } else {
        this.form.enable();
      }
      this.form.patchValue({
        statiAttuazione: value?.statiAttuazione,
        mesiComplessiviPrevisti: value?.mesiComplessiviPrevisti,
        percentualeAttuazione: value?.percentualeAttuazione,
        tempistichePreviste: value?.tempistichePreviste,
        note: value?.note,
        dataPrevistaConclusione: DateUtils.convertToFeDateNullable(value?.dataPrevistaConclusione)
      });
    }
  }

  _codiciIntervento: any;

  get codiciIntervento() {
    return this._codiciIntervento;
  }

  @Input()
  set codiciIntervento(value) {
    this._codiciIntervento = value;
  }

  _selectedCodiceIntervento: any;

  get selectedCodiceIntervento() {
    return this._selectedCodiceIntervento;
  }

  @Input()
  set selectedCodiceIntervento(value) {
    this._selectedCodiceIntervento = value;
  }

  @Output()
  selectedCodiceInterventoChange = new EventEmitter<any>();

  @Output()
  changeSelect = new EventEmitter<any>();

  @Output()
  preSubmit = new EventEmitter<any>();

  @Output()
  onSubmit = new EventEmitter<any>();

  @Output()
  onUpdate = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      statiAttuazione: [
        this.monitoraggio?.statiAttuazione,
        [],
      ],
      mesiComplessiviPrevisti: [
        this.monitoraggio?.mesiComplessiviPrevisti,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      percentualeAttuazione: [
        this.monitoraggio?.percentualeAttuazione,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      tempistichePreviste: [
        this.monitoraggio?.tempistichePreviste,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      note: [
        this.monitoraggio?.note,
        [],
      ],
      dataPrevistaConclusione: [
        DateUtils.convertToFeDateNullable(this.monitoraggio?.dataPrevistaConclusione),
        [],
      ],
    });
    if(this.pageStatus === 'VIEW' || this.monitoraggio?.isDefault === false) {
      this.form.disable();
    }
  }

  submit() {
    this.form.markAllAsTouched();
    let json = {
      statiAttuazione: this.form.controls.statiAttuazione.value,
      mesiComplessiviPrevisti: this.form.controls.mesiComplessiviPrevisti.value,
      percentualeAttuazione: this.form.controls.percentualeAttuazione.value,
      tempistichePreviste: this.form.controls.tempistichePreviste.value,
      note: this.form.controls.note.value,
      dataPrevistaConclusione: DateUtils.convertToBEDateString(
        this.form.controls.dataPrevistaConclusione.value
      ), 
    };
    this.preSubmit.emit(json);
    if (this.form.invalid) {
      return;
    }
    
    if(this.pageStatus === 'NEW') {
      this.onSubmit.emit(json);
    } else if(this.pageStatus === 'UPDATE') {
      this.onUpdate.emit(json);
    }
  }

  changeSelectCodiceIntevento(){
    this.selectedCodiceInterventoChange.emit(this.selectedCodiceIntervento);
    this.changeSelect.emit();
  }

}
