import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { ModalService } from 'src/app/service/modale/modal.service';
import { UserService } from 'src/app/service/user-service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.css']
})
export class ResetPasswordPageComponent implements OnInit {
  formCommons = AppInjector.get(FormCommons);
  form: FormGroup;
  showNewPwd: boolean;
  showConfirmNewPwd: boolean;
  firstAttempt: boolean;
  formTitle: string = '';
  
  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.showNewPwd = false;
    this.showConfirmNewPwd = false;
    this.firstAttempt = false;
    this.form = this.fb.group({
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    }, { validator: this.checkMatchingPasswords('newPassword', 'confirmNewPassword') });
  }

  checkMatchingPasswords(newPasswordKey: string, confirmNewPasswordKey: string) {
    return (group: FormGroup) => {
      let newPasswordInput = group.controls[newPasswordKey],
          confirmNewPasswordInput = group.controls[confirmNewPasswordKey];
      if (newPasswordInput.value !== confirmNewPasswordInput.value) {
        return confirmNewPasswordInput.setErrors({notEquivalent: true})
      }
      else {
          return confirmNewPasswordInput.setErrors(null);
      }
    }
  }

  toggleShowNewPwd() {
    this.showNewPwd = !this.showNewPwd;
  }

  toggleShowConfirmNewPwd() {
    this.showConfirmNewPwd = !this.showConfirmNewPwd;
  }

  submit() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.confirmReset();
  }

  confirmReset() {
    this.modalService.openModalGlobal(
      'Reset Password',
      "Vuoi confermare la nuova password?",
      'Conferma',
      () => {
        this.changePassword();
      },
      'Annulla',
      () => {
        this.modalService.closeModal();
      }
    );
  }

  changePassword(){
    this.modalService.closeModal();
    let user = this.userService.getUserStored();
    const body = {
      ...user,
      password: this.form.controls.newPassword.value,
      flag_reset: false,
    }
    this.userService.changePassword(body).then(result=> {
      this.userService.get(body.username).then(result => {
      let nextUrl = '/home';
      if(this.route.snapshot.params.returnUrl) {
        nextUrl = this.route.snapshot.params.returnUrl;
      }
      this.router.navigateByUrl(nextUrl);
      })
    });
  }

}
