<div class="card mt-3">
    <div class="card-body">
        <fieldset>
            <legend>Dati Anagrafici</legend>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="codiceRegione" class="col-sm-6 col-form-label">Codice Regione:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="codiceRegione">{{datiAnagrafici?.codiceRegione}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="regione" class="col-sm-6 col-form-label">Regione:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="regione">{{datiAnagrafici?.regione}}</p>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="codiceAzienda" class="col-sm-6 col-form-label">Codice Azienda:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="codiceAzienda">{{datiAnagrafici?.codiceAzienda}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="azienda" class="col-sm-6 col-form-label">Azienda:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="azienda">{{datiAnagrafici?.azienda}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!isMonitoraggioMensileMezziSoccorso()" class="form-row">
                <div class="form-group row col-md-6">
                    <label for="codiceStruttura" class="col-sm-6 col-form-label">Codice Struttura:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="codiceStruttura">{{datiAnagrafici?.codiceStruttura}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="denominazioneStruttura" class="col-sm-6 col-form-label">Denominazione Struttura:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="denominazioneStruttura">{{datiAnagrafici?.denominazioneStruttura}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!isMonitoraggioMensileMezziSoccorso()" class="form-row">
                <div class="form-group row col-md-6">
                    <label for="codiceStabilimento" class="col-sm-6 col-form-label">Codice Stabilimento:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="codiceStabilimento">{{datiAnagrafici?.codiceStabilimento}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="denominazioneStabilimento" class="col-sm-6 col-form-label">Denominazione Stabilimento:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="denominazioneStabilimento">{{datiAnagrafici?.denominazioneStabilimento}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!isMonitoraggioMensileMezziSoccorso()" class="form-row">
                <div class="form-group row col-md-6">
                    <label for="comune" class="col-sm-6 col-form-label">Comune:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="comune">{{datiAnagrafici?.comune}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="provincia" class="col-sm-6 col-form-label">Provincia:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="provincia">{{datiAnagrafici?.provincia}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!isMonitoraggioMensileMezziSoccorso()" class="form-row">
                <div class="form-group row col-md-6">
                    <label for="classificazioneStruttura" class="col-sm-6 col-form-label">Classificazione Struttura:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="classificazioneStruttura">{{datiAnagrafici?.classificazioneStruttura?.descrizione}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="isMonitoraggioMensileMezziSoccorso()" class="form-row">
                <div class="form-group row col-md-6">
                    <label for="sedeOperativa" class="col-sm-6 col-form-label">Sede del mezzo di soccorso:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="sedeOperativa">{{datiAnagrafici?.sedeOperativa}}</p>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</div>