import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private router: Router,

  ) { }


  storeToken(jsonRes: any) {
    sessionStorage.setItem('access_token', jsonRes.access_token);
    sessionStorage.setItem("refresh_token", jsonRes.refresh_token);
    sessionStorage.setItem("token_type", jsonRes.token_type);
  }

  deleteToken() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("token_type");
  }

  addToken(accessToken:string) {
    sessionStorage.setItem('access_token', accessToken);
  }

  getToken() {
    return sessionStorage.getItem('access_token');
  }

}
