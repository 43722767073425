import { Injectable } from '@angular/core';
import { Modal } from './../../model/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }

  modal_global: Modal;


  setModalGlobal(modale) {
    this.modal_global = modale;
  }

  openModalGlobal(titolo, messaggio, testoBottoneOK, funzioneBottoneOK?, testoBottoneAnnulla?, funzioneBottoneAnnulla?) {
    this.modal_global.title = titolo;
    this.modal_global.message = messaggio;
    this.modal_global.testoBottoneOK = testoBottoneOK;
    this.modal_global.funzioneBottoneOK = funzioneBottoneOK ? funzioneBottoneOK : this.modal_global.closeModal;
    this.modal_global.testoBottoneAnnulla = testoBottoneAnnulla;
    this.modal_global.funzioneBottoneAnnulla = funzioneBottoneAnnulla;

    this.modal_global.open = true;
  }

  closeModal() {
    this.modal_global.closeModal();
  }


}
