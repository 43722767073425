<app-container>  
  <app-modifica-dati-utente
    [(form)]="form"
    [pageStatus]="'NEW'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Modifica Utente Esistente'"
    [utenti]="utenti"
    [ruoli]="ruoli"
    [aziende]="aziende"
    (generateRandomPassword)="generateRandomPassword()"
    (onSubmit)="onSave()"
    (copyPasswordEvent)="copyPassword()"
    
  >
  </app-modifica-dati-utente>
</app-container>
