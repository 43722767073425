<app-container>
    <h3>Ricerca Dati Monitoraggio Quindicinale</h3>
    <app-ricerca-monitoraggio
        [tipoMonitoraggio] = "tipoMonitoraggio"
        [ricercaMonitoraggioList] = "ricercaMonitoraggioPeriodicoList"
        (onSearch)="searchMonitoraggioBisettimanale($event)"
        (onDownload)="downloadMonitoraggioBisettimanale($event)"
        (onInviaMinistero)="confirmInviaAlMinisteroMonitoraggioBisettimanale($event)"
    >
    </app-ricerca-monitoraggio>
</app-container>