import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user-service';
import { LoginService } from '../../service/login-service';
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {

  user: any = undefined;
  userSubscription: Subscription;

  adminRole:boolean;
  userRole:boolean;

  constructor(private userService: UserService) {
    this.manageUser(userService.getUserStored());
   }

  ngOnInit(): void {
    this.userSubscription = this.userService.user.subscribe(usr => {
      this.manageUser(usr);
    })
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  manageUser(user: any) {
    this.user = user;
    this.adminRole = this.userService.isAdmin();
    this.userRole = this.userService.isUser();
  }
  
  
}
