<app-container>
  <app-dati-utente
    [(form)]="form"
    [pageStatus]="'NEW'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Inserimento nuovo utente'"
    [ruoli]="ruoli"
    [aziende]="aziende"
    (generateRandomPassword)="generateRandomPassword()"
    (onSubmit)="onSave()"
    (copyPasswordEvent)="copyPassword()"
  >
  </app-dati-utente>
</app-container>
