import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private toastr: ToastrService) {

  }

  addDefaultSuccessMessage() {
    this.addSuccessMessage('','Operazione eseguita correttamente');
  }

  addDefaultErrorMessage() {
    this.addErrorMessage('','Operazione non riuscita');
  }

  addSuccessMessage(title: string, message: string) {
    this.toastr.success(message,title)
  }
  
  addErrorMessage(title: string, message: string) {
    this.toastr.error(message,title)
  }
  addInfoMessage(title: string, message: string) {
    this.toastr.info(message,title)
  }
}
