import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-monitoraggio-mensile-quadro-economico',
  templateUrl: './monitoraggio-mensile-quadro-economico.component.html',
  styleUrls: ['./monitoraggio-mensile-quadro-economico.component.css']
})
export class MonitoraggioMensileQuadroEconomicoComponent implements OnInit {
  @Input()
  quadroEconomico: any;

  @Input()
  contestoOperativo: string;

  constructor() { }

  ngOnInit(): void {
  }

}
