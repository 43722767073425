<div class="card mt-3">
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <fieldset>
          <legend>Monitoraggio</legend>
          <div class="card">
            <div class="card-body">
              <fieldset>
                <legend>Servizi</legend>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="serviziOrdAttivazione"
                      >Ordine di Attivazione / Lettera d'ordine / altro
                        <button 
                            id="serviziOrdAttivazionePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.SERVIZI_ORDINE_ATTIVAZIONE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                      </label>
                    <input
                      type="text"
                      [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                          shouldValidate &&
                          formCommons.isFieldInvalid(form, 'serviziOrdAttivazione')
                      }"
                      maxlength="350"
                      class="form-control"
                      id="serviziOrdAttivazione"
                      formControlName="serviziOrdAttivazione"
                      aria-describedby="serviziOrdAttivazioneSmall"
                    />
                    <small id="serviziOrdAttivazioneSmall" class="invalid-feedback">
                      <p *ngIf="form.controls.serviziOrdAttivazione.errors && form.controls.serviziOrdAttivazione.errors.required">Il campo &egrave; obbligatorio</p>
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="serviziStatoAttuazione">Stato di attuazione
                        <button 
                            id="serviziStatoAttuazionePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.SERVIZI_STATO_ATTUAZIONE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label>
                    <select
                      [ngClass]="{
                        'form-control custom-select': true,
                        'is-invalid':
                          shouldValidate && formCommons.isFieldInvalid(form, 'serviziStatoAttuazione')
                      }"
                      id="serviziStatoAttuazione"
                      aria-describedby="serviziStatoAttuazioneSmall"
                      formControlName="serviziStatoAttuazione"
                      [compareWith]="formCommons.compareSelectOption"
                    >
                      <option></option>
                      <ng-container *ngFor="let statoAttuazioneServizi of statiAttuazioneServizi">
                        <option [ngValue]="statoAttuazioneServizi">{{ statoAttuazioneServizi.descrizione }}</option>
                      </ng-container>
                    </select>
                    <small id="serviziStatoAttuazioneSmall" class="invalid-feedback"></small>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="serviziMesiComplessivi"
                        >Mesi complessivi previsti per lo stato di attuazione
                        <button 
                            id="serviziMesiComplessiviPopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.SERVIZI_MESI_COMPLESSIVI}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                        </label
                    >
                    <input
                        type="number"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'serviziMesiComplessivi')
                        }"
                        min="0"
                        class="form-control"
                        id="serviziMesiComplessivi"
                        formControlName="serviziMesiComplessivi"
                        aria-describedby="serviziMesiComplessiviSmall"
                        onkeydown="return event.keyCode !== 69"
                    />
                    <small id="serviziMesiComplessiviSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.serviziMesiComplessivi.errors && form.controls.serviziMesiComplessivi.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.serviziMesiComplessivi.errors && form.controls.serviziMesiComplessivi.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.serviziMesiComplessivi.errors && form.controls.serviziMesiComplessivi.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="serviziPercentualeAttiv"
                        >Percentuale di attuazione
                        <button 
                            id="serviziPercentualeAttivPopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.SERVIZI_PERCENTUALE_ATTUAZIONE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                        </label
                    >
                    <input
                        type="number"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'serviziPercentualeAttiv')
                        }"
                        min="0"
                        class="form-control"
                        id="serviziPercentualeAttiv"
                        formControlName="serviziPercentualeAttiv"
                        aria-describedby="serviziPercentualeAttivSmall"
                        onkeydown="return event.keyCode !== 69"
                    />
                    <small id="serviziPercentualeAttivSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.serviziPercentualeAttiv.errors && form.controls.serviziPercentualeAttiv.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.serviziPercentualeAttiv.errors && form.controls.serviziPercentualeAttiv.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.serviziPercentualeAttiv.errors && form.controls.serviziPercentualeAttiv.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                    </small>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="serviziNote"
                        >Note
                      <button 
                        id="serviziNotePopOver" 
                        class="btn" 
                        type="button" 
                        placement="top"
                        ngbPopover="{{informativaCampi.SERVIZI_NOTE}}">
                        <i class="fas fa-info-circle"></i>
                      </button>
                    </label
                    >
                    <textarea
                        type="text"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'serviziNote')
                        }"
                        class="form-control"
                        id="serviziNote"
                        formControlName="serviziNote"
                        aria-describedby="serviziNoteSmall"
                        maxlength="500"
                    ></textarea>
                    <small id="serviziNoteSmall" class="invalid-feedback"></small>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-body">
              <fieldset>
                <legend>Lavori</legend>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="lavoriOrdAttivazione"
                      >Ordine di Attivazione / Lettera d'ordine / altro
                        <button 
                            id="lavoriOrdAttivazionePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.LAVORI_ORDINE_ATTIVAZIONE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                      </label
                    >
                    <input
                      type="text"
                      [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                          shouldValidate &&
                          formCommons.isFieldInvalid(form, 'lavoriOrdAttivazione')
                      }"
                      class="form-control"
                      id="lavoriOrdAttivazione"
                      maxlength="350"
                      formControlName="lavoriOrdAttivazione"
                      aria-describedby="lavoriOrdAttivazioneSmall"
                    />
                    <small id="lavoriOrdAttivazioneSmall" class="invalid-feedback">
                      <p *ngIf="form.controls.lavoriOrdAttivazione.errors && form.controls.lavoriOrdAttivazione.errors.required">Il campo &egrave; obbligatorio</p>
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="lavoriStatoAttuazione">Stato di attuazione
                        <button 
                            id="lavoriStatoAttuazionePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.LAVORI_STATO_ATTUAZIONE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label>
                    <select
                      [ngClass]="{
                        'form-control custom-select': true,
                        'is-invalid':
                          shouldValidate && formCommons.isFieldInvalid(form, 'lavoriStatoAttuazione')
                      }"
                      id="lavoriStatoAttuazione"
                      aria-describedby="lavoriStatoAttuazioneSmall"
                      formControlName="lavoriStatoAttuazione"
                      [compareWith]="formCommons.compareSelectOption"
                    >
                      <option></option>
                      <ng-container *ngFor="let statoAttuazioneLavori of statiAttuazioneLavori">
                        <option [ngValue]="statoAttuazioneLavori">{{ statoAttuazioneLavori.descrizione }}</option>
                      </ng-container>
                    </select>
                    <small id="lavoriStatoAttuazioneSmall" class="invalid-feedback"></small>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="lavoriMesiComplessivi"
                        >Mesi complessivi previsti per lo stato di attuazione
                        <button 
                            id="lavoriMesiComplessiviPopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.LAVORI_MESI_COMPLESSIVI}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <input
                        type="number"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'lavoriMesiComplessivi')
                        }"
                        min="0"
                        class="form-control"
                        id="lavoriMesiComplessivi"
                        formControlName="lavoriMesiComplessivi"
                        aria-describedby="lavoriMesiComplessiviSmall"
                        onkeydown="return event.keyCode !== 69"
                    />
                    <small id="lavoriMesiComplessiviSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.lavoriMesiComplessivi.errors && form.controls.lavoriMesiComplessivi.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.lavoriMesiComplessivi.errors && form.controls.lavoriMesiComplessivi.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.lavoriMesiComplessivi.errors && form.controls.lavoriMesiComplessivi.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="lavoriPercentualeAttiv"
                        >Percentuale di attuazione
                        <button 
                            id="lavoriPercentualeAttivPopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.LAVORI_PERCENTUALE_ATTUAZIONE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <input
                        type="number"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'lavoriPercentualeAttiv')
                        }"
                        min="0"
                        class="form-control"
                        id="lavoriPercentualeAttiv"
                        formControlName="lavoriPercentualeAttiv"
                        aria-describedby="lavoriPercentualeAttivSmall"
                        onkeydown="return event.keyCode !== 69"
                    />
                    <small id="lavoriPercentualeAttivSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.lavoriPercentualeAttiv.errors && form.controls.lavoriPercentualeAttiv.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.lavoriPercentualeAttiv.errors && form.controls.lavoriPercentualeAttiv.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.lavoriPercentualeAttiv.errors && form.controls.lavoriPercentualeAttiv.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                    </small>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="lavoriNote"
                        >Note
                        <button 
                            id="lavoriNotePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.LAVORI_NOTE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <textarea
                        type="text"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'lavoriNote')
                        }"
                        class="form-control"
                        id="lavoriNote"
                        maxlength="500"
                        formControlName="lavoriNote"
                        aria-describedby="lavoriNoteSmall"
                    ></textarea>
                    <small id="lavoriNoteSmall" class="invalid-feedback"></small>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-body">
              <fieldset>
                <legend>Attrezzature</legend>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="attrStatoAttuazione">Stato di attuazione
                        <button 
                            id="attrStatoAttuazionePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.ATTR_STATO_ATTUAZIONE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label>
                    <select
                      [ngClass]="{
                        'form-control custom-select': true,
                        'is-invalid':
                          shouldValidate && formCommons.isFieldInvalid(form, 'attrStatoAttuazione')
                      }"
                      id="attrStatoAttuazione"
                      aria-describedby="attrStatoAttuazioneSmall"
                      formControlName="attrStatoAttuazione"
                      [compareWith]="formCommons.compareSelectOption"
                    >
                      <option></option>
                      <ng-container *ngFor="let statoAttuazioneAttrezzature of statiAttuazioneAttrezzature">
                        <option [ngValue]="statoAttuazioneAttrezzature">{{ statoAttuazioneAttrezzature.descrizione }}</option>
                      </ng-container>
                    </select>
                    <small id="attrStatoAttuazioneSmall" class="invalid-feedback"></small>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="attrImporto"
                      >Importo totale ordini attivati
                        <button 
                            id="attrImportoPopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.ATTR_IMPORTO}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <input
                      type="number"
                      [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                          shouldValidate &&
                          formCommons.isFieldInvalid(form, 'attrImporto')
                      }"
                      min="0"
                      class="form-control"
                      id="attrImporto"
                      formControlName="attrImporto"
                      aria-describedby="attrImportoSmall"
                      onkeydown="return event.keyCode !== 69"
                    />
                    <small id="attrImportoSmall" class="invalid-feedback">
                      <p *ngIf="form.controls.attrImporto.errors && form.controls.attrImporto.errors.required">Il campo &egrave; obbligatorio</p>
                      <p *ngIf="form.controls.attrImporto.errors && form.controls.attrImporto.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.attrImporto.errors && form.controls.attrImporto.errors.pattern">L'importo non &egrave; in un formato valido</p>
                    </small>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="attrMesiComplessivi"
                        >Mesi complessivi previsti per il completamento delle forniture
                        <button 
                            id="attrMesiComplessiviPopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.ATTR_MESI_COMPLESSIVI}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <input
                        type="number"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'attrMesiComplessivi')
                        }"
                        min="0"
                        class="form-control"
                        id="attrMesiComplessivi"
                        formControlName="attrMesiComplessivi"
                        aria-describedby="attrMesiComplessiviSmall"
                        onkeydown="return event.keyCode !== 69"
                    />
                    <small id="attrMesiComplessiviSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.attrMesiComplessivi.errors && form.controls.attrMesiComplessivi.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.attrMesiComplessivi.errors && form.controls.attrMesiComplessivi.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.attrMesiComplessivi.errors && form.controls.attrMesiComplessivi.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="attrNote"
                        >Note
                        <button 
                            id="attrNotePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.ATTR_NOTE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <textarea
                        type="text"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'attrNote')
                        }"
                        class="form-control"
                        id="attrNote"
                        maxlength="500"
                        formControlName="attrNote"
                        aria-describedby="attrNoteSmall"
                    ></textarea>
                    <small id="attrNoteSmall" class="invalid-feedback"></small>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="card mt-3 mb-3">
            <div class="card-body">
              <fieldset>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="durataComplessivaIntervento"
                        >Durata complessiva dell'intervento
                        <button 
                            id="durataComplessivaInterventoPopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.DURATA_INTERVENTO}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <input
                        type="number"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'durataComplessivaIntervento')
                        }"
                        min="0"
                        class="form-control"
                        id="durataComplessivaIntervento"
                        formControlName="durataComplessivaIntervento"
                        aria-describedby="durataComplessivaInterventoSmall"
                        onkeydown="return event.keyCode !== 69"
                    />
                    <small id="durataComplessivaInterventoSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.durataComplessivaIntervento.errors && form.controls.durataComplessivaIntervento.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.durataComplessivaIntervento.errors && form.controls.durataComplessivaIntervento.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.durataComplessivaIntervento.errors && form.controls.durataComplessivaIntervento.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="dataPrevistaConclusione"
                      >Data prevista conclusione intervento
                        <button 
                            id="dataPrevistaConclusionePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.DATA_CONCLUSIONE_INTERVENTO}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        [ngClass]="{
                          'form-control': true
                        }"
                        class="form-control"
                        id="dataPrevistaConclusione"
                        aria-describedby="dataPrevistaConclusioneSmall"
                        formControlName="dataPrevistaConclusione"
                        placeholder="gg/mm/aaaa"
                        ngbDatepicker 
                        #dataPrevistaConclusioneDatePicker="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary"
                          type="button"
                          (click)="dataPrevistaConclusioneDatePicker.toggle()"
                        >
                          <i class="fas fa-calendar"></i>
                        </button>
                      </div>
                      <small id="dataPrevistaConclusioneSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.dataPrevistaConclusione.errors && form.controls.dataPrevistaConclusione.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.dataPrevistaConclusione.errors && form.controls.dataPrevistaConclusione.errors.ngbDate">La data non &egrave; in un formato valido</p>
                      </small>
                    </div>
                  </div>
                </div>
                <div *ngIf="isMonitoraggioPostiLetto()" class="form-row">
                  <div class="form-group col-md-6">
                    <label for="numeroPlTiAttivati"
                        >Numero posti letto TI attivati</label
                    >
                    <input
                        type="number"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'numeroPlTiAttivati')
                        }"
                        min="0"
                        class="form-control"
                        id="numeroPlTiAttivati"
                        formControlName="numeroPlTiAttivati"
                        aria-describedby="numeroPlTiAttivatiSmall"
                        onkeydown="return event.keyCode !== 69"
                    />
                    <small id="numeroPlTiAttivatiSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.numeroPlTiAttivati.errors && form.controls.numeroPlTiAttivati.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.numeroPlTiAttivati.errors && form.controls.numeroPlTiAttivati.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.numeroPlTiAttivati.errors && form.controls.numeroPlTiAttivati.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="numeroPlStiAttivati"
                        >Numero posti letto TSI attivati</label
                    >
                    <input
                        type="number"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'numeroPlStiAttivati')
                        }"
                        min="0"
                        class="form-control"
                        id="numeroPlStiAttivati"
                        formControlName="numeroPlStiAttivati"
                        aria-describedby="numeroPlStiAttivatiSmall"
                        onkeydown="return event.keyCode !== 69"
                    />
                    <small id="numeroPlStiAttivatiSmall" class="invalid-feedback">
                        <p *ngIf="form.controls.numeroPlStiAttivati.errors && form.controls.numeroPlStiAttivati.errors.required">Il campo &egrave; obbligatorio</p>
                        <p *ngIf="form.controls.numeroPlStiAttivati.errors && form.controls.numeroPlStiAttivati.errors.min">Il valore minimo consentito &egrave; 0</p>
                        <p *ngIf="form.controls.numeroPlStiAttivati.errors && form.controls.numeroPlStiAttivati.errors.pattern">&Egrave; consentito inserire solo numeri</p>
                    </small>
                  </div>
                </div>
                <div *ngIf="isMonitoraggioPostiLetto()" class="form-row">
                  <div class="form-group col-md-12">
                    <label for="note">Note
                        <button 
                            id="notePopOver" 
                            class="btn" 
                            type="button" 
                            placement="top"
                            ngbPopover="{{informativaCampi.NOTE}}">
                            <i class="fas fa-info-circle"></i>
                        </button>
                    </label
                    >
                    <textarea
                        type="text"
                        [ngClass]="{
                        'form-control': true,
                        'is-invalid':
                            shouldValidate &&
                            formCommons.isFieldInvalid(form, 'note')
                        }"
                        class="form-control"
                        id="note"
                        maxlength="500"
                        formControlName="note"
                        aria-describedby="noteSmall"
                    ></textarea>
                    <small id="noteSmall" class="invalid-feedback"></small>
                  </div>
                </div>
                </fieldset>
              </div>
            </div>
          <div *ngIf="this.monitoraggio && this.monitoraggio.isDefault !== false">
            <button type="reset" class="btn btn-light mr-2">Svuota campi</button>
            <button type="submit" class="btn btn-primary">Salva</button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
