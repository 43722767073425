import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { DateUtils } from 'src/app/utility/date-utils';

@Component({
  selector: 'app-monitoraggio-mezzi-soccorso',
  templateUrl: './monitoraggio-mezzi-soccorso.component.html',
  styleUrls: ['./monitoraggio-mezzi-soccorso.component.css']
})
export class MonitoraggioMezziSoccorsoComponent implements OnInit {
  formCommons = AppInjector.get(FormCommons);
  form: FormGroup;

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = '';

  _monitoraggioMezziSoccorso: any;
  
  get monitoraggioMezziSoccorso(): any {
    return this._monitoraggioMezziSoccorso;
  };

  @Input()
  set monitoraggioMezziSoccorso(value: any) {
    this._monitoraggioMezziSoccorso = value;
    if(this.form) {
      if (value?.isDefault === false) {
        this.form.disable();
      } else {
        this.form.enable();
      }
      this.form.patchValue({
        daAcquistare: value?.daAcquistare,
        acquistate: value?.acquistate,
        acquistateAgg: value?.acquistateAgg,
        consegnateCollaudate: value?.consegnateCollaudate,
        consegnateCollaudateAgg: value?.consegnateCollaudateAgg,
        dataRilevazione: DateUtils.convertToFeDate(value?.dataRilevazione)
      });
    }
  }

  @Output()
  preSubmit = new EventEmitter<any>();

  @Output()
  onSubmit = new EventEmitter<any>();

  @Output()
  onUpdate = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      daAcquistare: [
        this.monitoraggioMezziSoccorso?.daAcquistare,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      acquistate: [
        this.monitoraggioMezziSoccorso?.acquistate,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      acquistateAgg: [
        this.monitoraggioMezziSoccorso?.acquistateAgg,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      consegnateCollaudate: [
        this.monitoraggioMezziSoccorso?.consegnateCollaudate,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      consegnateCollaudateAgg: [
        this.monitoraggioMezziSoccorso?.consegnateCollaudateAgg,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      dataRilevazione: [
        DateUtils.convertToFeDate(this.monitoraggioMezziSoccorso?.dataRilevazione),
        [Validators.required],
      ],
    });

    if(this.pageStatus === 'VIEW' || this.monitoraggioMezziSoccorso?.isDefault === false) {
      this.form.disable();
    }
  }

  submit() {
    this.form.markAllAsTouched();
    let json = {
      daAcquistare: this.form.controls.daAcquistare.value,
      acquistate: this.form.controls.acquistate.value,
      acquistateAgg: this.form.controls.acquistateAgg.value,
      consegnateCollaudate: this.form.controls.consegnateCollaudate.value,
      consegnateCollaudateAgg: this.form.controls.consegnateCollaudateAgg.value,
      dataRilevazione: DateUtils.convertToBEOnlyDateString(
        this.form.controls.dataRilevazione.value
      ),
    };
    this.preSubmit.emit(json);
    if (this.form.invalid) {
      return;
    }
    if(this.pageStatus === 'NEW') {
      this.onSubmit.emit(json);
    } else if(this.pageStatus === 'UPDATE') {
      this.onUpdate.emit(json);
    }
  }

}
