<div class="card mt-3">
    <div class="card-body">
        <fieldset>
            <legend>Quadro Economico</legend>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="importoLavori" class="col-sm-6 col-form-label">Importo lavori:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="importoLavori">{{quadroEconomico?.importoLavori | currency:'EUR' }}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="importoAttrezzatureElettm" class="col-sm-6 col-form-label">Importo attrezzature elettromedicali:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="importoAttrezzatureElettm">{{quadroEconomico?.importoAttrezzatureElettm | currency:'EUR'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="altro" class="col-sm-6 col-form-label">Altro:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="altro">{{quadroEconomico?.altro | currency:'EUR'}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="iva" class="col-sm-6 col-form-label">IVA:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="iva">{{quadroEconomico?.iva | currency:'EUR'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="importoComplessivoQe" class="col-sm-6 col-form-label">Importo complessivo (IVA inclusa):</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="importoComplessivoQe">{{quadroEconomico?.importoComplessivoQe | currency:'EUR'}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="importoSostenuto" class="col-sm-6 col-form-label">Importo sostenuto:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="importoSostenuto">{{quadroEconomico?.importoSostenuto | currency:'EUR'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="cup" class="col-sm-6 col-form-label">CUP:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="cup">{{quadroEconomico?.cup}}</p>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</div>