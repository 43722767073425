import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormCommons } from 'src/app/utility/commons';
import { AppInjector } from '../../helpers/app-injector';

@Component({
  selector: 'app-dati-utente',
  templateUrl: './dati-utente.component.html',
  styleUrls: ['./dati-utente.component.css'],
})
export class DatiUtenteComponent implements OnInit, OnDestroy {
  formCommons = AppInjector.get(FormCommons);
  private _formValue: FormGroup;

  @Input()
  get form(): FormGroup {
    return this._formValue;
  }

  set form(form: FormGroup) {
    this._formValue = form;
  }

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = '';

  @Input()
  ruoli: any[] = [];

  @Input()
  aziende: any[] = [];

  @Output()
  generateRandomPassword = new EventEmitter<void>();

  @Output()
  formChange = new EventEmitter<FormGroup>();

  @Output()
  onSubmit = new EventEmitter<void>();

  @Output()
  copyPasswordEvent = new EventEmitter<void>();

  usernameSub: Subscription;

  constructor() {}

  ngOnInit(): void {
    if (this.pageStatus === 'NEW') {
      this.generatePassword();
    }
    let usernameControl = this.form.get('username');
    this.usernameSub = usernameControl.valueChanges.subscribe(() => {
      if (usernameControl.value) {
        usernameControl.patchValue(usernameControl.value.toUpperCase(), {
          emitEvent: false,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.usernameSub.unsubscribe();
  }

  copyPassword() {
    this.copyPasswordEvent.emit();
  }

  generatePassword() {
    this.generateRandomPassword.emit();
  }

  save() {
    this.onSubmit.emit();
  }
}
