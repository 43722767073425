import { Component } from '@angular/core';
import { Modal } from './model/modal';
import { TokenService } from './service/token/token.service'
import { ModalService } from './service/modale/modal.service'
import { UtilityService } from './utility/utility.service'
import { Router } from '@angular/router'

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Monitoraggio DL34/2020";
  sessionExpired: boolean;
  modal_global: Modal;
  refreshToken: boolean;

  constructor(
    private tokenService: TokenService,
    private modalService: ModalService,
    private router: Router,
    private utility: UtilityService
  ) {}

  ngOnInit() {
    this.modal_global = new Modal();
    this.modalService.setModalGlobal(this.modal_global);
    this.tokenService.initAuthProcess();
    this.tokenService.handleToken();
  }

  handleToken() {
    if (sessionStorage.getItem("access_token")) {
      let access_token = sessionStorage.getItem("access_token");
      let refresh_token = sessionStorage.getItem("refresh_token");
      if (
        this.tokenService.isTokenExpired(refresh_token) &&
        !this.sessionExpired
      ) {
        this.sessionExpired = true;
        this.router.navigate(["/sessionExpired"]);
      } else if (
        this.tokenService.isTokenExpired(access_token) &&
        !this.tokenService.isTokenExpired(refresh_token) &&
        !this.refreshToken
      ) {
        this.tokenService.getRefreshToken().subscribe((data) => {
          let jsonRes = JSON.parse(JSON.stringify(data));
          this.utility.storeToken(jsonRes);
          this.refreshToken = false;
        });
        this.refreshToken = true;
      } else {
        this.tokenService.handleToken();
      }
    }
  }
}

