import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { DateUtils } from 'src/app/utility/date-utils';
import { CO_MON_MENSILE_TI } from '../../tipologiche/contesto-operativo';
import {informativa_campi} from 'src/app/utility/informativa_campi';

@Component({
  selector: 'app-monitoraggio-mensile-pl-ps',
  templateUrl: './monitoraggio-mensile-pl-ps.component.html',
  styleUrls: ['./monitoraggio-mensile-pl-ps.component.css']
})
export class MonitoraggioMensilePlPsComponent implements OnInit {

  formCommons = AppInjector.get(FormCommons);
  form: FormGroup;
  informativaCampi = informativa_campi;

  @Input()
  contestoOperativo: string;

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = '';

  @Input()
  statiAttuazioneServizi: any;

  @Input()
  statiAttuazioneLavori: any;

  @Input()
  statiAttuazioneAttrezzature: any;

  _monitoraggio: any;
  
  get monitoraggio(): any {
    return this._monitoraggio;
  };

  @Input()
  set monitoraggio(value: any) {
    this._monitoraggio = value;
    if(this.form) {
      if (value?.isDefault === false) {
        this.form.disable();
      } else {
        this.form.enable();
      }
      
      this.form.patchValue({
        serviziOrdAttivazione: value?.serviziOrdAttivazione,
        serviziStatoAttuazione: value?.serviziStatoAttuazione,
        serviziMesiComplessivi: value?.serviziMesiComplessivi,
        serviziPercentualeAttiv: value?.serviziPercentualeAttiv,
        serviziNote: value?.serviziNote,
        lavoriOrdAttivazione: value?.lavoriOrdAttivazione,
        lavoriStatoAttuazione: value?.lavoriStatoAttuazione,
        lavoriMesiComplessivi: value?.lavoriMesiComplessivi,
        lavoriPercentualeAttiv: value?.lavoriPercentualeAttiv,
        lavoriNote: value?.lavoriNote,
        attrMesiComplessivi: value?.attrMesiComplessivi,
        attrStatoAttuazione: value?.attrStatoAttuazione,
        attrImporto: value?.attrImporto,
        attrNote: value?.attrNote,
        note: value?.note,
        durataComplessivaIntervento: value?.durataComplessivaIntervento,
        numeroPlTiAttivati: value?.numeroPlTiAttivati,
        numeroPlStiAttivati: value?.numeroPlStiAttivati,
        dataPrevistaConclusione: DateUtils.convertToFeDateNullable(value?.dataPrevistaConclusione)
      });
    }
  }

  @Output()
  preSubmit = new EventEmitter<any>();

  @Output()
  onSubmit = new EventEmitter<any>();

  @Output()
  onUpdate = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      serviziOrdAttivazione: [
        this.monitoraggio?.serviziOrdAttivazione,
        [],
      ],
      serviziStatoAttuazione: [
        this.monitoraggio?.serviziStatoAttuazione,
        [],
      ],
      serviziMesiComplessivi: [
        this.monitoraggio?.serviziMesiComplessivi,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      serviziPercentualeAttiv: [
        this.monitoraggio?.serviziPercentualeAttiv,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      serviziNote: [
        this.monitoraggio?.serviziNote,
        [],
      ],
      lavoriOrdAttivazione: [
        this.monitoraggio?.lavoriOrdAttivazione,
        [],
      ],
      lavoriStatoAttuazione: [
        this.monitoraggio?.lavoriStatoAttuazione,
        [],
      ],
      lavoriMesiComplessivi: [
        this.monitoraggio?.lavoriMesiComplessivi,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      lavoriPercentualeAttiv: [
        this.monitoraggio?.lavoriPercentualeAttiv,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      lavoriNote: [
        this.monitoraggio?.lavoriNote,
        [],
      ],
      attrMesiComplessivi: [
        this.monitoraggio?.attrMesiComplessivi,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      attrStatoAttuazione: [
        this.monitoraggio?.attrStatoAttuazione,
        [],
      ],
      attrImporto: [
        this.monitoraggio?.attrImporto,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$'),
        ],
      ],
      attrNote: [
        this.monitoraggio?.attrNote,
        [],
      ],
      note: [
        this.monitoraggio?.note,
        [],
      ],
      durataComplessivaIntervento: [
        this.monitoraggio?.durataComplessivaIntervento,
        [
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      numeroPlTiAttivati: [
        this.monitoraggio?.numeroPlTiAttivati,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      numeroPlStiAttivati: [
        this.monitoraggio?.numeroPlStiAttivati,
        [
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      dataPrevistaConclusione: [
        DateUtils.convertToFeDateNullable(this.monitoraggio?.dataPrevistaConclusione)
      ],
    });
    
    if(this.pageStatus === 'VIEW' || this.monitoraggio?.isDefault === false) {
      this.form.disable();
    }
  }

  submit() {
    
    this.form.markAllAsTouched();
    let json = {
      serviziOrdAttivazione: this.form.controls.serviziOrdAttivazione.value,
      serviziStatoAttuazione: this.form.controls.serviziStatoAttuazione.value,
      serviziMesiComplessivi: this.form.controls.serviziMesiComplessivi.value,
      serviziPercentualeAttiv: this.form.controls.serviziPercentualeAttiv.value,
      serviziNote: this.form.controls.serviziNote.value,
      lavoriOrdAttivazione: this.form.controls.lavoriOrdAttivazione.value,
      lavoriStatoAttuazione: this.form.controls.lavoriStatoAttuazione.value,
      lavoriMesiComplessivi: this.form.controls.lavoriMesiComplessivi.value,
      lavoriPercentualeAttiv: this.form.controls.lavoriPercentualeAttiv.value,
      lavoriNote: this.form.controls.lavoriNote.value,
      attrMesiComplessivi: this.form.controls.attrMesiComplessivi.value,
      attrStatoAttuazione: this.form.controls.attrStatoAttuazione.value,
      attrImporto: this.form.controls.attrImporto.value,
      attrNote: this.form.controls.attrNote.value,
      note: this.form.controls.note.value,
      durataComplessivaIntervento: this.form.controls.durataComplessivaIntervento.value,
      numeroPlTiAttivati: this.form.controls.numeroPlTiAttivati.value,
      numeroPlStiAttivati: this.form.controls.numeroPlStiAttivati.value,
      dataPrevistaConclusione: DateUtils.convertToBEDateString(
        this.form.controls.dataPrevistaConclusione.value
      ), 
    };
    this.preSubmit.emit(json);
    if (this.form.invalid) {
      return;
    }
    
    if(this.pageStatus === 'NEW') {
      this.onSubmit.emit(json);
    } else if(this.pageStatus === 'UPDATE') {
      this.onUpdate.emit(json);
    }
  }

  isMonitoraggioPostiLetto(){
    return CO_MON_MENSILE_TI === this.contestoOperativo;
  }

}
