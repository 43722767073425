import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user-service';
import { DatiAnagraficiService } from 'src/app/service/dati-anagrafici-service';
import {MonitoraggiBisettimanaliTiService} from '../../service/monitoraggi-bisettimanali-ti-service';
import {MonitoraggiBisettimanaliMsService} from '../../service/monitoraggi-bisettimanali-ms-service';
import {MonitoraggiBisettimanaliPsService} from '../../service/monitoraggi-bisettimanali-ps-service';
import {TIPO_MON_QUINDICINALE, TIPO_MON_MENSILE} from '../../tipologiche/tipo-monitoraggio';
import {CO_MON_QUINDICINALE_TI, CO_MON_QUINDICINALE_PS, CO_MON_QUINDICINALE_MS} from '../../tipologiche/contesto-operativo';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inserimento-monitoraggio-periodico',
  templateUrl: './inserimento-monitoraggio-periodico.component.html',
  styleUrls: ['./inserimento-monitoraggio-periodico.component.css'],
})
export class InserimentoMonitoraggioPeriodicoComponent implements OnInit {

  adminRole:boolean;
  userRole:boolean;
  userSubscription: Subscription;

  active: number = 1;
  firstAttempt: boolean;
  pageStatusPL: string = 'NEW';
  pageStatusPS: string = 'NEW';
  pageStatusMS: string = 'NEW';

  idAnagrafica: any;
  periodoRiferimento: any;
  stabilimenti: any[]=[];
  monitoraggioPL: any;
  monitoraggioPS: any;
  monitoraggioMS: any;
  localMonitoraggioPL: any;
  localMonitoraggioMS: any;
  localMonitoraggioPS: any;

  aziende: any[] = [];
  selectedAzienda: any;

  _selectedStabilimento: any;

  get selectedStabilimento() {
    return this._selectedStabilimento;
  }

  set selectedStabilimento(value) {
    this._selectedStabilimento = value;
  }

  constructor(private userService: UserService, 
    private datiAnagraficiService: DatiAnagraficiService,
    private monitoraggioBisettimanaleTiService: MonitoraggiBisettimanaliTiService,
    private monitoraggiBisettimanaliMsService: MonitoraggiBisettimanaliMsService,
    private monitoraggiBisettimanaliPsService: MonitoraggiBisettimanaliPsService
    ) {}

  ngOnInit(): void {
    this.idAnagrafica = this.userService.getIdAnagrafica();
    this.loadPostiLetto();
    this.loadMezziSoccorso();
    this.loadStabilimenti();
    this.loadProntoSoccorso();
    this.loadAziende();
    this.userSubscription = this.userService.user.subscribe(usr => {
      this.manageUser(usr);
    });
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  manageUser(user: any) {
    this.adminRole = this.userService.isAdmin();
    this.userRole = this.userService.isUser();
  }

  loadAziende() {
    this.datiAnagraficiService.getAziende().then((result) => {
      this.aziende = result;
    });
  }

  changeSelectAzienda() { 
    this.stabilimenti = [];
    this.selectedStabilimento = undefined;
    this.monitoraggioPL = {};
    this.monitoraggioPS = {};
    this.monitoraggioMS = {};
    this.localMonitoraggioPL = {
      isDefault: false,
    };
    this.localMonitoraggioMS = {
      isDefault: false,
    };
    this.localMonitoraggioPS = {
      isDefault: false,
    };
    
    this.idAnagrafica = this.selectedAzienda.id;
    this.loadPostiLetto();
    this.loadMezziSoccorso();
    this.loadStabilimenti();
    this.loadProntoSoccorso();
  }

  setIsDefault(isDefault, flagInviatoMinistero) {
    let isDefaultValue = false;
    if(this.userRole){
      isDefaultValue = isDefault || !flagInviatoMinistero;
    } else if(this.adminRole) {
      isDefaultValue = !isDefault && !flagInviatoMinistero;
    }
    return isDefaultValue;
  }

  loadPostiLetto() {
    if(!this.idAnagrafica) {
      this.localMonitoraggioPL = {
        isDefault: false
      };
      return;
    }
    //monitoraggio contiene quella scaricata da be
    //localMonitoraggioPL contiene quella attualmente in modifica.
    this.monitoraggioBisettimanaleTiService.getDefault(TIPO_MON_QUINDICINALE, this.idAnagrafica, CO_MON_QUINDICINALE_TI).then (result => {
      this.periodoRiferimento = result?.monitoraggioBisettimanale?.monitoraggio?.calendarioMonitoraggio;
      //posso modificare il monitoraggio fino a che non scade il periodo di inserimento
      //e fino a che non e' inviato al ministero
      let flagInviatoMinistero = result?.monitoraggioBisettimanale?.monitoraggio?.inviatoMinistero;
      this.pageStatusPL = result?.monitoraggioBisettimanale?.monitoraggio?.isDefault ? 'NEW' : 'UPDATE';

      this.monitoraggioPL = {
        ...result
      }
      this.localMonitoraggioPL = {
        plTiHsp2019Cod49: result?.plTiHsp2019Cod49,
        plTiProgConvModLg77: result?.plTiProgConvModLg77,
        plTiProgConvModLg77Agg: result?.plTiProgConvModLg77Agg,
        plTiProgConvModLg77Agg2: result?.plTiProgConvModLg77Agg2,
        plTsiProgConvModLg77: result?.plTsiProgConvModLg77,
        plTsiProgConvModLg77Agg: result?.plTsiProgConvModLg77Agg,
        plTsiProgConvModLg77Agg2: result?.plTsiProgConvModLg77Agg2,
        plTsiAttivati: result?.plTsiAttivati,
        plTsiConvertibili: result?.plTsiConvertibili,
        dataRilevazione: result?.dataRilevazione,
        isDefault: this.setIsDefault(result?.monitoraggioBisettimanale?.monitoraggio?.isDefault, flagInviatoMinistero)
      };
    }).catch(error => {
      if(error.status === 404) {
        this.localMonitoraggioPL = {
          isDefault: true
        };
      } else if(error.status === 500){
        this.localMonitoraggioPL = {
          isDefault: false
        };
      }
    })
  }

  loadMezziSoccorso() {
    if(!this.idAnagrafica) {
      this.localMonitoraggioMS = {
        isDefault: false
      };
      return;
    }
    //monitoraggio contiene quella scaricata da be
    //localMonitoraggioPL contiene quella attualmente in modifica.
    this.monitoraggiBisettimanaliMsService.getDefault(TIPO_MON_QUINDICINALE, this.idAnagrafica, CO_MON_QUINDICINALE_MS ).then (result => {
      this.periodoRiferimento = result?.monitoraggioBisettimanale?.monitoraggio?.calendarioMonitoraggio;
      //posso modificare il monitoraggio fino a che non scade il periodo di inserimento
      //e fino a che non e' inviato al ministero
      let flagInviatoMinistero = result?.monitoraggioBisettimanale?.monitoraggio?.inviatoMinistero;
      this.pageStatusMS = result?.monitoraggioBisettimanale?.monitoraggio?.isDefault ? 'NEW' : 'UPDATE';

      this.monitoraggioMS = {
        ...result
      }
      this.localMonitoraggioMS = {
        daAcquistare: result?.daAcquistare,
        acquistate: result?.acquistate,
        acquistateAgg: result?.acquistateAgg,
        consegnateCollaudate: result?.consegnateCollaudate,
        consegnateCollaudateAgg: result?.consegnateCollaudateAgg,
        dataRilevazione: result?.dataRilevazione,
        isDefault: this.setIsDefault(result?.monitoraggioBisettimanale?.monitoraggio?.isDefault, flagInviatoMinistero)
      };
    }).catch(error => {
      if(error.status === 404) {
        this.localMonitoraggioMS = {
          isDefault: true
        };
      } else if(error.status === 500){
        this.localMonitoraggioMS = {
          isDefault: false
        };
      }
    })
  }

  loadProntoSoccorso() {
    this.firstAttempt = false;
    if(!this.selectedStabilimento) {
      this.localMonitoraggioPS = {
        isDefault: false,
      }
      return;
    }
    let idAnagrafica = this.selectedStabilimento.id;
    //localMonitoraggioPL contiene quella attualmente in modifica.
    this.monitoraggiBisettimanaliPsService.getDefault(TIPO_MON_QUINDICINALE, idAnagrafica, CO_MON_QUINDICINALE_PS ).then (result => {
      this.periodoRiferimento = result?.monitoraggioBisettimanale?.monitoraggio?.calendarioMonitoraggio;
      //posso modificare il monitoraggio fino a che non scade il periodo di inserimento
      //e fino a che non e' inviato al ministero
      let flagInviatoMinistero = result?.monitoraggioBisettimanale?.monitoraggio?.inviatoMinistero;
      this.pageStatusPS = result?.monitoraggioBisettimanale?.monitoraggio?.isDefault ? 'NEW' : 'UPDATE';

      this.monitoraggioPS = {
        ...result
      }
      this.localMonitoraggioPS = {
        interventiPrevisti: result?.interventiPrevisti,
        intervRistrInCorso: result?.intervRistrInCorso,
        intervRistrConclusi: result?.intervRistrConclusi,
        dataPrevistaConclusione: result?.dataPrevistaConclusione,
        dataRilevazione: result?.dataRilevazione,
        isDefault: this.setIsDefault(result?.monitoraggioBisettimanale?.monitoraggio?.isDefault, flagInviatoMinistero)
      };
    }).catch(error => {
      if(error.status === 404) {
        this.localMonitoraggioPS = {
          isDefault: true
        };
      } else if(error.status === 500){
        this.localMonitoraggioPS = {
          isDefault: false
        };
      }
    })
  }

  loadStabilimenti() {
    if(!this.idAnagrafica) {
      this.stabilimenti = [];
      return;
    }
    this.datiAnagraficiService.getStabilimentiByIdAnagrafica(this.idAnagrafica).then (result => {
      this.stabilimenti = result;
    }).catch(error => {
      if(error.status === 404) {
        this.stabilimenti = [];
      }
    })
  }

  getSubmitBody(contestoOperativo: string, idAnagrafica: string) {
    let body = {
      idUtenteInserimento: this.userService.getIdUtente(),
      monitoraggioBisettimanale: {
        idAnagrafica,
        monitoraggio: {
          idAnagrafica: this.idAnagrafica,
          idUtente: this.userService.getIdUtente(),
          calendarioMonitoraggio: {
            id: this.periodoRiferimento.id
          }
        },
        contestoOperativo: {
          id: contestoOperativo
        },
      }
    }
    return body;
  }

  getUpdateBody(updateMonitoraggio:any) {
    let body = {
      ...updateMonitoraggio
    }
    body.idUtenteModifica = this.userService.getIdUtente();
    body.dataModifica = undefined;
    body.monitoraggioBisettimanale.contestoOperativo.descrizione = undefined;
    body.monitoraggioBisettimanale.monitoraggio.hash = undefined;
    body.monitoraggioBisettimanale.monitoraggio.calendarioMonitoraggio.dataInizioInserimento = undefined;
    body.monitoraggioBisettimanale.monitoraggio.calendarioMonitoraggio.dataFineInserimento = undefined;
    return body;
  }

  /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
  preSubmitPostiLetto(values: any) {
    this.localMonitoraggioPL = values;
    this.firstAttempt = true;
  }

  onSubmitPostiLetto(values) {
    //post di value
    let body = this.getSubmitBody(CO_MON_QUINDICINALE_TI, this.idAnagrafica);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggioBisettimanaleTiService.save(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadPostiLetto();
    })
  }

  onUpdatePostiLetto(values) {
    //put di value
    let body = this.getUpdateBody(this.monitoraggioPL);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggioBisettimanaleTiService.update(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadPostiLetto();
    })
  }

  /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
  preSubmitMezziSoccorso(values: any) {
    this.localMonitoraggioMS = values;
    this.firstAttempt = true;
  }

  onSubmitMezziSoccorso(values: any) {
    //post di value
    let body = this.getSubmitBody(CO_MON_QUINDICINALE_MS, this.idAnagrafica);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggiBisettimanaliMsService.save(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadMezziSoccorso();
    })
  }

  onUpdateMezziSoccorso(values: any) {
    //put di value
    let body = this.getUpdateBody(this.monitoraggioMS);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggiBisettimanaliMsService.update(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadMezziSoccorso();
    })
  }

  /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
   preSubmitProntoSoccorso(values: any) {
    this.localMonitoraggioPS = values;
    this.firstAttempt = true;
  }

  onSubmitProntoSoccorso(values: any) {
    //post di value
    let body = this.getSubmitBody(CO_MON_QUINDICINALE_PS, this.selectedStabilimento.id);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggiBisettimanaliPsService.save(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadProntoSoccorso();
    })
  }

  onUpdateProntoSoccorso(values: any) {
    //put di value
    let body = this.getUpdateBody(this.monitoraggioPS);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggiBisettimanaliPsService.update(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadProntoSoccorso();
    })
  }

}
