<div class="card mt-3">
    <div class="card-body">
        <fieldset>
            <legend>Tipologia Interventi di Ristrutturazione</legend>
            <div class="form-row">
                <div class="input-group col-md-6">
                    <label for="areePretriageDistinte" class="col-sm-6 col-form-label">Aree di pre-triage distinte:</label>
                    <input class="position-static" type="checkbox" id="areePretriageDistinte" [checked]="interventiRistrutturazione?.areePretriageDistinte" disabled>
                </div>
                <div class="input-group col-md-6">
                    <label for="areeAttesaDedicata" class="col-sm-6 col-form-label">Area di attesa dedicata covid-19:</label>
                    <input class="position-static" type="checkbox" id="areeAttesaDedicata" [checked]="interventiRistrutturazione?.areeAttesaDedicata" disabled>
                </div>
            </div>
            <div class="form-row">
                <div class="input-group col-md-6">
                    <label for="ambulatorioDedicato" class="col-sm-6 col-form-label">Ambulatorio dedicato covid-19:</label>
                    <input class="position-static" type="checkbox" id="ambulatorioDedicato" [checked]="interventiRistrutturazione?.ambulatorioDedicato" disabled>
                </div>
                <div class="input-group col-md-6">
                    <label for="areaDedicataAttesaTampone" class="col-sm-6 col-form-label">Area dedicata per soggetti in attesa di esito tampone:</label>
                    <input class="position-static" type="checkbox" id="areaDedicataAttesaTampone" [checked]="interventiRistrutturazione?.areaDedicataAttesaTampone" disabled>
                </div>
            </div>
            <div class="form-row">
                <div class="input-group col-md-6">
                    <label for="percorsoPazienteCovid" class="col-sm-6 col-form-label">Percorso specificatamente individuato per paziente COVID-19:</label>
                    <input class="position-static" type="checkbox" id="percorsoPazienteCovid" [checked]="interventiRistrutturazione?.percorsoPazienteCovid" disabled>
                </div>
                <div class="input-group col-md-6">
                    <label for="accessoDirettoMezziSoccorso" class="col-sm-6 col-form-label">Accesso diretto e percorsi dedicati di mezzi di soccorso a spazi di attesa sospetti barellati:</label>
                    <input class="position-static" type="checkbox" id="accessoDirettoMezziSoccorso" [checked]="interventiRistrutturazione?.accessoDirettoMezziSoccorso" disabled>
                </div>
            </div>
            <div class="form-row">
                <div class="input-group col-md-6">
                    <label for="radiologiaDedicata" class="col-sm-6 col-form-label">Diagnostica radiologica dedicata:</label>
                    <input class="position-static" type="checkbox" id="radiologiaDedicata" [checked]="interventiRistrutturazione?.radiologiaDedicata" disabled>               
                </div>
            </div>
        </fieldset>
    </div>
</div>
