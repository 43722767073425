import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utente-non-abilitato',
  templateUrl: './utente-non-abilitato.component.html',
  styleUrls: ['./utente-non-abilitato.component.css']
})
export class UtenteNonAbilitatoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
