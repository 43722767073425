<form [formGroup]="form" (ngSubmit)="save()">
  <fieldset>
    <legend>{{ formTitle }}</legend>
    <div class="form-row">
      <div class="form-group col-md-5">
        <label for="username">Username</label>
        <input
          type="username"
          [ngClass]="{
            'form-control': true,
            'is-invalid':
              shouldValidate && formCommons.isFieldInvalid(form, 'username')
          }"
          class="form-control"
          id="username"
          aria-describedby="usernameSmall"
          formControlName="username"
        />
        <small id="usernameSmall" class="invalid-feedback">
          <p *ngIf="form.controls.username.errors && form.controls.username.errors.required">Campo obbligatorio</p>
          <p *ngIf="form.controls.username.errors && form.controls.username.errors.maxlength">Il campo non pu&ograve; contenere pi&ugrave; di 16 caratteri</p>
          <p *ngIf="form.controls.username.errors && form.controls.username.errors.pattern">Codice fiscale non valido</p>
        </small>
      </div>
      <div *ngIf="false" class="form-group col-md-5">
        <label for="password">Password</label>
        <div class="input-group mb-2">
          <input
            type="text"
            [ngClass]="{
              'form-control': true,
              'is-invalid':
                shouldValidate && formCommons.isFieldInvalid(form, 'password')
            }"
            class="form-control"
            id="password"
            formControlName="password"
            aria-describedby="passwordSmall"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="copyPassword()"
            >
              <i class="fas fa-copy"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="generatePassword()"
            >
              <i class="fas fa-sync-alt"></i>
            </button>
          </div>
          <small id="passwordSmall" class="invalid-feedback">
            <p *ngIf="form.controls.password.errors && form.controls.password.errors.required">Campo obbligatorio</p>
            <p *ngIf="form.controls.password.errors && form.controls.password.errors.maxlength">Il campo non pu&ograve; contenere pi&ugrave; di 100 caratteri</p>
          </small>
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="email">Email</label>
        <input
          type="email"
          [ngClass]="{
            'form-control': true,
            'is-invalid':
              shouldValidate && formCommons.isFieldInvalid(form, 'email')
          }"
          class="form-control"
          id="email"
          formControlName="email"
          aria-describedby="emailSmall"
        />
        <small id="emailSmall" class="invalid-feedback">
          <p *ngIf="form.controls.email.errors && form.controls.email.errors.required">Campo obbligatorio</p>
          <p *ngIf="form.controls.email.errors && form.controls.email.errors.email">Email non valida</p>
          <p *ngIf="form.controls.email.errors && form.controls.email.errors.maxlength">Il campo non pu&ograve; contenere pi&ugrave; di 50 caratteri</p>
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-5">
        <label for="ruolo">Ruolo</label>
        <select
          [ngClass]="{
            'form-control custom-select': true,
            'is-invalid':
              shouldValidate && formCommons.isFieldInvalid(form, 'ruolo')
          }"
          id="ruolo"
          aria-describedby="ruoloSmall"
          formControlName="ruolo"
        >
          <option></option>
          <ng-container *ngFor="let ruolo of ruoli">
            <option [ngValue]="ruolo">{{ ruolo.nomeRuolo }}</option>
          </ng-container>
        </select>
        <small id="ruoloSmall" class="invalid-feedback">
          <p *ngIf="form.controls.ruolo.errors && form.controls.ruolo.errors.required">Campo obbligatorio</p>
        </small>
      </div>
      <div class="form-group col-md-5">
        <label for="azienda">Azienda</label>
        <select
          [ngClass]="{
            'form-control custom-select': true,
            'is-invalid':
              shouldValidate && formCommons.isFieldInvalid(form, 'azienda')
          }"
          id="azienda"
          aria-describedby="aziendaSmall"
          formControlName="azienda"
        >
          <option></option>
          <ng-container *ngFor="let azienda of aziende">
            <option [ngValue]="azienda">{{ azienda.azienda }}</option>
          </ng-container>
        </select>
        <small id="aziendaSmall" class="invalid-feedback">
          <p *ngIf="form.controls.azienda.errors && form.controls.azienda.errors.required">Campo obbligatorio</p>
        </small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-5">
        <label for="ente_riferimento">Ente riferimento</label>
        <input
          type="text"
          [ngClass]="{
            'form-control': true,
            'is-invalid':
              shouldValidate &&
              formCommons.isFieldInvalid(form, 'ente_riferimento')
          }"
          class="form-control"
          id="ente_riferimento"
          formControlName="ente_riferimento"
          aria-describedby="ente_riferimentoSmall"
        />
        <small id="ente_riferimentoSmall" class="invalid-feedback">
          <p *ngIf="form.controls.ente_riferimento.errors && form.controls.ente_riferimento.errors.required">Campo obbligatorio</p>
          <p *ngIf="form.controls.ente_riferimento.errors && form.controls.ente_riferimento.errors.maxlength">Il campo non pu&ograve; contenere pi&ugrave; di 50 caratteri</p>
        </small>
      </div>
      <div class="form-group col-md-5">
        <label for="ruolo_presso_ente">Ruolo presso ente</label>
        <input
          type="text"
          [ngClass]="{
            'form-control': true,
            'is-invalid':
              shouldValidate &&
              formCommons.isFieldInvalid(form, 'ruolo_presso_ente')
          }"
          class="form-control"
          id="ruolo_presso_ente"
          formControlName="ruolo_presso_ente"
          aria-describedby="ruolo_presso_enteSmall"
        />
        <small id="ruolo_presso_enteSmall" class="invalid-feedback">
          <p *ngIf="form.controls.ruolo_presso_ente.errors && form.controls.ruolo_presso_ente.errors.required">Campo obbligatorio</p>
          <p *ngIf="form.controls.ruolo_presso_ente.errors && form.controls.ruolo_presso_ente.errors.maxlength">Il campo non pu&ograve; contenere pi&ugrave; di 50 caratteri</p>
        </small>
      </div>
    </div>
    <button type="submit" class="btn btn-primary">Salva</button>
  </fieldset>
</form>
