<div class="card custom-card-no-top-border">
  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <fieldset>
        <legend>{{ formTitle }}</legend>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="dataRilevazione"
              >Data Rilevazione</label
            >
            <div class="input-group mb-2">
              <input
                type="text"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    shouldValidate && formCommons.isFieldInvalid(form, 'dataRilevazione')
                }"
                class="form-control"
                id="dataRilevazione"
                aria-describedby="dataRilevazioneSmall"
                formControlName="dataRilevazione"
                placeholder="gg/mm/aaaa"
                ngbDatepicker 
                #dataRilevazioneDatePicker="ngbDatepicker"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="dataRilevazioneDatePicker.toggle()"
                >
                  <i class="fas fa-calendar"></i>
                </button>
              </div>
              <small id="dataRilevazioneSmall" class="invalid-feedback">
                <p *ngIf="form.controls.dataRilevazione.errors && form.controls.dataRilevazione.errors.required">Il campo &egrave; obbligatorio</p>
                <p *ngIf="form.controls.dataRilevazione.errors && form.controls.dataRilevazione.errors.ngbDate">La data non &egrave; in un formato valido</p>
              </small>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="plTiHsp2019Cod49"
              >Posti Letto di T. Intensiva (HSP 31/12/2019)</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTiHsp2019Cod49')
              }"
              min="0"
              class="form-control"
              id="plTiHsp2019Cod49"
              formControlName="plTiHsp2019Cod49"
              aria-describedby="plTiHsp2019Cod49Small"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTiHsp2019Cod49Small" class="invalid-feedback">
              <p *ngIf="form.controls.plTiHsp2019Cod49.errors && form.controls.plTiHsp2019Cod49.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTiHsp2019Cod49.errors && form.controls.plTiHsp2019Cod49.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTiHsp2019Cod49.errors && form.controls.plTiHsp2019Cod49.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="plTiProgConvModLg77"
              >Posti Letto di T. Intensiva programmati</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTiProgConvModLg77')
              }"
              min="0"
              class="form-control"
              id="plTiProgConvModLg77"
              formControlName="plTiProgConvModLg77"
              aria-describedby="plTiProgConvModLg77Small"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTiProgConvModLg77Small" class="invalid-feedback">
              <p *ngIf="form.controls.plTiProgConvModLg77.errors && form.controls.plTiProgConvModLg77.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTiProgConvModLg77.errors && form.controls.plTiProgConvModLg77.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTiProgConvModLg77.errors && form.controls.plTiProgConvModLg77.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
          <div class="form-group col-md-6">
            <label for="plTiProgConvModLg77Agg"
              >Posti Letto di T. Intensiva attivati (periodo precedente)</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTiProgConvModLg77Agg')
              }"
              min="0"
              class="form-control"
              id="plTiProgConvModLg77Agg"
              formControlName="plTiProgConvModLg77Agg"
              aria-describedby="plTiProgConvModLg77AggSmall"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTiProgConvModLg77AggSmall" class="invalid-feedback">
              <p *ngIf="form.controls.plTiProgConvModLg77Agg.errors && form.controls.plTiProgConvModLg77Agg.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTiProgConvModLg77Agg.errors && form.controls.plTiProgConvModLg77Agg.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTiProgConvModLg77Agg.errors && form.controls.plTiProgConvModLg77Agg.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="plTiProgConvModLg77Agg2"
              >Posti Letto di T. Intensiva attivati ai sensi art.2 dl 34/2020 (periodo attuale)</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTiProgConvModLg77Agg2')
              }"
              min="0"
              class="form-control"
              id="plTiProgConvModLg77Agg2"
              formControlName="plTiProgConvModLg77Agg2"
              aria-describedby="plTiProgConvModLg77Agg2Small"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTiProgConvModLg77Agg2Small" class="invalid-feedback">
              <p *ngIf="form.controls.plTiProgConvModLg77Agg2.errors && form.controls.plTiProgConvModLg77Agg2.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTiProgConvModLg77Agg2.errors && form.controls.plTiProgConvModLg77Agg2.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTiProgConvModLg77Agg2.errors && form.controls.plTiProgConvModLg77Agg2.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="plTsiProgConvModLg77"
              >Posti Letto T. Semintensiva programmati</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTsiProgConvModLg77')
              }"
              min="0"
              class="form-control"
              id="plTsiProgConvModLg77"
              formControlName="plTsiProgConvModLg77"
              aria-describedby="plTsiProgConvModLg77Small"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTsiProgConvModLg77Small" class="invalid-feedback">
              <p *ngIf="form.controls.plTsiProgConvModLg77.errors && form.controls.plTsiProgConvModLg77.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTsiProgConvModLg77.errors && form.controls.plTsiProgConvModLg77.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTsiProgConvModLg77.errors && form.controls.plTsiProgConvModLg77.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
          <div class="form-group col-md-6">
            <label for="plTsiAttivati"
              >Posti Letto T. Semintensiva attivati (periodo precedente)</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTsiAttivati')
              }"
              min="0"
              class="form-control"
              id="plTsiAttivati"
              formControlName="plTsiAttivati"
              aria-describedby="plTsiAttivatiSmall"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTsiAttivatiSmall" class="invalid-feedback">
              <p *ngIf="form.controls.plTsiAttivati.errors && form.controls.plTsiAttivati.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTsiAttivati.errors && form.controls.plTsiAttivati.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTsiAttivati.errors && form.controls.plTsiAttivati.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="plTsiProgConvModLg77Agg2"
              >Posti Letto T. Semintensiva attivati ai sensi art.2 dl 34/2020 (periodo attuale)</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTsiProgConvModLg77Agg2')
              }"
              min="0"
              class="form-control"
              id="plTsiProgConvModLg77Agg2"
              formControlName="plTsiProgConvModLg77Agg2"
              aria-describedby="plTsiProgConvModLg77Agg2Small"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTsiProgConvModLg77Agg2Small" class="invalid-feedback">
              <p *ngIf="form.controls.plTsiProgConvModLg77Agg2.errors && form.controls.plTsiProgConvModLg77Agg2.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTsiProgConvModLg77Agg2.errors && form.controls.plTsiProgConvModLg77Agg2.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTsiProgConvModLg77Agg2.errors && form.controls.plTsiProgConvModLg77Agg2.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="plTsiConvertibili"
              >Posti Letto T. Semintensiva convertibili in posti letti di T. Intensiva (periodo precedente)</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTsiConvertibili')
              }"
              min="0"
              class="form-control"
              id="plTsiConvertibili"
              formControlName="plTsiConvertibili"
              aria-describedby="plTsiConvertibiliSmall"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTsiConvertibiliSmall" class="invalid-feedback">
              <p *ngIf="form.controls.plTsiConvertibili.errors && form.controls.plTsiConvertibili.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTsiConvertibili.errors && form.controls.plTsiConvertibili.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTsiConvertibili.errors && form.controls.plTsiConvertibili.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
          <div class="form-group col-md-6">
            <label for="plTsiProgConvModLg77Agg"
              >Posti Letto T. Semintensiva convertibili in posti letto di T. Intensiva (periodo attuale)</label
            >
            <input
              type="number"
              [ngClass]="{
                'form-control': true,
                'is-invalid':
                  shouldValidate &&
                  formCommons.isFieldInvalid(form, 'plTsiProgConvModLg77Agg')
              }"
              min="0"
              class="form-control"
              id="plTsiProgConvModLg77Agg"
              formControlName="plTsiProgConvModLg77Agg"
              aria-describedby="plTsiProgConvModLg77AggSmall"
              onkeydown="return event.keyCode !== 69"
            />
            <small id="plTsiProgConvModLg77AggSmall" class="invalid-feedback">
              <p *ngIf="form.controls.plTsiProgConvModLg77Agg.errors && form.controls.plTsiProgConvModLg77Agg.errors.required">Il campo &egrave; obbligatorio</p>
              <p *ngIf="form.controls.plTsiProgConvModLg77Agg.errors && form.controls.plTsiProgConvModLg77Agg.errors.min">Il valore minimo consentito &egrave; 0</p>
              <p *ngIf="form.controls.plTsiProgConvModLg77Agg.errors && form.controls.plTsiProgConvModLg77Agg.errors.pattern">&Egrave; consentito inserire solo numeri</p>
            </small>
          </div>
        </div>
        <div *ngIf="this.monitoraggio && this.monitoraggio.isDefault !== false">
          <button type="reset" class="btn btn-light mr-2">Svuota campi</button>
          <button type="submit" class="btn btn-primary">Salva</button>
        </div>
      </fieldset>
    </form>
  </div>
</div>
