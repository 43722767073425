import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../utility/alert-service';


export class MonitoraggioService extends BaseService {
 
  constructor(protected http: HttpClient, serviceUrl: string, protected spinner: NgxSpinnerService, alertService: AlertService) {
    super(http, serviceUrl);
  }

  save(body: any): Promise<any> {
    this.spinner.show();
    return super.post('',body).then(result => {
      this.spinner.hide();
      this.alertService.addSuccessMessage('','Aggiornamento Dati Avvenuto con Successo');
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  update(body: any): Promise<any> {
    this.spinner.show();
    return super.put('',body).then(result => {
      this.spinner.hide();
      this.alertService.addSuccessMessage('','Aggiornamento Dati Avvenuto con Successo');
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  getDefault(tipoMonitoraggio: string, idAnagrafica: string, idContesto: string): Promise<any> {
    this.spinner.show();
    return super.get(`${tipoMonitoraggio}/${idAnagrafica}/${idContesto}`).then(result => {
      this.spinner.hide();
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  getDefaultMensile(tipoMonitoraggio: string, idCodiceIntervento: string): Promise<any> {
    this.spinner.show();
    return super.get(`${tipoMonitoraggio}/${idCodiceIntervento}`).then(result => {
      this.spinner.hide();
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }
}
