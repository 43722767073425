<footer id="custom-footer">
    <div class="container-fluid container-footer">
        <div class="maxSizeContent">
            <div class="row container-logo">
                <div class="col-12 col-md-4">
                    <img alt="Logo regione Toscana" class="light-logo" src="assets/img/logoToscana.png"
                        style="height:61px;">
                </div>
                <div class="col-12 col-md-8 container-text">
                    <div>
                        <p>Direzione: Sanità, welfare e coesione sociale</p>
                        <p>Settore: Ricerca e investimenti in ambito sanitario</p>
                        <p>Mail: <a href="mailto:monitoraggio_dl34_2020@regione.toscana.it"
                            class="email-footer">monitoraggio_dl34_2020@regione.toscana.it</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row container-copyright ">
                <div class="span">
                    <div style="padding-left: 20px">Regione Toscana © 2021 | <a
                            href="http://fascicolosanitario.regione.toscana.it" class="link-footer">Fascicolo Sanitario
                            Elettronico</a>
                        |
                        <a href="http://www.regione.toscana.it/cittadini/diritti-e-cittadinanza/privacy"
                            class="link-footer">Privacy</a> | <a href="http://www.regione.toscana.it/accessibilita"
                            class="link-footer">Accessibilità</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>