import { Component, EventEmitter, Input, OnInit, Output, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { SortableHeaderDirective, SortEvent } from 'src/app/directive/sortable-header.directive';
import { DateUtils } from 'src/app/utility/date-utils';
import { SearchBaseComponent } from 'src/app/page/search-base-component';
import { DatiAnagraficiService } from '../../service/dati-anagrafici-service';
import { MonitoraggiMensiliService } from '../../service/monitoraggi-mensili-service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user-service';
import { ModalService } from 'src/app/service/modale/modal.service';
import {TIPO_MON_MENSILE} from 'src/app/tipologiche/tipo-monitoraggio';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-ricerca-monitoraggio',
  templateUrl: './ricerca-monitoraggio.component.html',
  styleUrls: ['./ricerca-monitoraggio.component.css']
})
export class RicercaMonitoraggioComponent extends SearchBaseComponent implements OnInit {

  formCommons = AppInjector.get(FormCommons);
  form: FormGroup;

  pageStatus: string = 'SEARCH';
  formTitle: string = '';
  shouldValidate: boolean;

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  @Input()
  tipoMonitoraggio: any;

  aziende: any[] = [];
  ricercaMonitoraggioCriteri: any;
  @Input()
  ricercaMonitoraggioList: any[] = undefined;

  adminRole:boolean;
  userRole:boolean;
  userSubscription: Subscription;

  @Output()
  onSearch = new EventEmitter<any>();

  @Output()
  onDownload = new EventEmitter<any>();
  @Output()
  onDownloadMassivo = new EventEmitter<any>();
  @Output()
  onInviaMinistero = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private datiAnagraficiService: DatiAnagraficiService,
    private monitoraggiMensiliService: MonitoraggiMensiliService,
    private userService: UserService,
    private modalService: ModalService,
    private http: HttpClient,
    ) {
      super();
      this.manageUser(userService.getUserStored());
    }

  ngOnInit(): void {
    this.form = this.fb.group({
      azienda: null,
      dataInizioPeriodo: null,
      dataFinePeriodo: null,
    });
    this.datiAnagraficiService.getAziende().then((result) => {
      this.aziende = result;
    });
    this.userSubscription = this.userService.user.subscribe(usr => {
      this.manageUser(usr);
    })
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  manageUser(user: any) {
    this.adminRole = this.userService.isAdmin();
    this.userRole = this.userService.isUser();
  }

  onSort({column, direction}: SortEvent) {
    // reset header
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.ricercaMonitoraggioCriteri = {
      ...this.ricercaMonitoraggioCriteri,
      sortInfo: this.setSortInfo(column, direction),
    }
    this.onSearch.emit(this.ricercaMonitoraggioCriteri);
  }

  getPage(event: EventEmitter<any>){
    this.ricercaMonitoraggioCriteri = {
      ...this.ricercaMonitoraggioCriteri,
      pageInfo: this.setPageInfo(this.page,this.pageSize, this.collectionSize),
    }
    this.onSearch.emit(this.ricercaMonitoraggioCriteri);
  }

  submit() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaMonitoraggioCriteri = {
      idAzienda: this.userRole? this.userService.getIdAnagrafica() : this.form.controls.azienda.value?.id,
      dataInizioPeriodo: DateUtils.convertToBEDateString(
        this.form.controls.dataInizioPeriodo.value
      ),
      dataFinePeriodo: DateUtils.convertToBEDateString(
        this.form.controls.dataFinePeriodo.value
      ),
      sortInfo: this.setSortInfo('descrizioneAnagrafica', 'ASC'),
      pageInfo: this.initializePageInfo(),
    };
    if (this.form.invalid) {
      return;
    }
    this.onSearch.emit(this.ricercaMonitoraggioCriteri);
  }

  downloadMonitoraggio(event, monitoraggio){
    console.log(event)
    this.onDownload.emit(monitoraggio);
  }

  confirmInviaAlMinisteroMonitoraggio(event, monitoraggio) {
    this.onInviaMinistero.emit(monitoraggio);
  }

  isMonitoraggioMensile(){
    return TIPO_MON_MENSILE === this.tipoMonitoraggio;
  }

  downloadMassivo(): void {
    console.log("downloadMassivo")
    this.onDownloadMassivo.emit();
    // Effettua una richiesta GET al backend per il download massivo
    // this.http.get('/download-massivo/').subscribe(
    //   (data) => {
    //     console.log('Download massivo avviato con successo!', data);
    //     // Puoi aggiungere ulteriori azioni come reindirizzamento o manipolazione dell'interfaccia utente qui
    //   },
    //   (error) => {
    //     console.error('Errore durante il download massivo:', error);
    //     // Puoi gestire gli errori qui, ad esempio mostrando un messaggio di errore all'utente
    //   }
    // );
  }

  downloadMassivoButton(): void {
    this.onDownloadMassivo.emit();
    // // Effettua una richiesta GET al backend per il download massivo
    // this.http.get('/download-massivo/').subscribe(
    //   (data) => {
    //     console.log('Download massivo avviato con successo!', data);
    //     // Puoi aggiungere ulteriori azioni come reindirizzamento o manipolazione dell'interfaccia utente qui
    //   },
    //   (error) => {
    //     console.error('Errore durante il download massivo:', error);
    //     // Puoi gestire gli errori qui, ad esempio mostrando un messaggio di errore all'utente
    //   }
    // );
  }
}
