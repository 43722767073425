import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatiAnagraficiService } from '../../service/dati-anagrafici-service';
import { MonitoraggiMensiliService } from '../../service/monitoraggi-mensili-service';
import { RicercaMonitoraggioComponent } from 'src/app/component/ricerca-monitoraggio/ricerca-monitoraggio.component';
import { UserService } from 'src/app/service/user-service';
import { ModalService } from 'src/app/service/modale/modal.service';
import {TIPO_MON_MENSILE} from 'src/app/tipologiche/tipo-monitoraggio';
import { AlertService } from '../../utility/alert-service';

@Component({
  selector: 'app-ricerca-monitoraggio-mensile',
  templateUrl: './ricerca-monitoraggio-mensile.component.html',
  styleUrls: ['./ricerca-monitoraggio-mensile.component.css']
})
export class RicercaMonitoraggioMensileComponent implements OnInit {

  tipoMonitoraggio = TIPO_MON_MENSILE;
  ricercaMonitoraggioMensileList: any[] = undefined;

  @ViewChild(RicercaMonitoraggioComponent) rmc!: RicercaMonitoraggioComponent;

  constructor(
    private fb: FormBuilder,
    private datiAnagraficiService: DatiAnagraficiService,
    private monitoraggiMensiliService: MonitoraggiMensiliService,
    private userService: UserService,
    private modalService: ModalService,
    ) {
    }

  ngOnInit(): void {  }

  searchMonitoraggioMensile(criteri:any){
    this.monitoraggiMensiliService.search(criteri).then(result => {
      this.ricercaMonitoraggioMensileList = result?.monitoraggioDto;
      this.rmc.setPaginatorValues({page: result?.paginaCorrente, totalCount: result?.totaleRecord});
    }).catch(error => {
      if(error.status === 404) {
        this.ricercaMonitoraggioMensileList = [];
      }
    })
  }

  downloadMonitoraggioMensile(monitoraggio){
    console.log(monitoraggio)
    //this.monitoraggiMensiliService.downladMonitoraggio(monitoraggio.idPeriodo);
    this.monitoraggiMensiliService.downladMonitoraggioConAzienda(monitoraggio.idPeriodo, monitoraggio.descrizioneAnagrafica);

  }

  downloadMonitoraggioMensileMassivo(){
    console.log("downloadMonitoraggioMensileMassivo")
    //this.monitoraggiMensiliService.downladMonitoraggio(monitoraggio.idPeriodo);
    this.monitoraggiMensiliService.downloadMassivo();

  }

  confirmInviaAlMinisteroMonitoraggioMensile(monitoraggio) {
    this.modalService.openModalGlobal(
      'Invia Monitoraggio al Ministero',
      "Vuoi confermare l'invio del monitoraggio al Ministero?",
      'Conferma',
      () => {
        this.inviaAlMinisteroMonitoraggioMensile(monitoraggio);
      },
      'Annulla',
      () => {
        this.modalService.closeModal();
      }
    );
  }

  inviaAlMinisteroMonitoraggioMensile(monitoraggio){
    this.modalService.closeModal();
    this.monitoraggiMensiliService.inviaMinistero(monitoraggio.idPeriodo).then(() => {
      this.searchMonitoraggioMensile(this.rmc.ricercaMonitoraggioCriteri);
    })
  }
}
