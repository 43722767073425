<div class="card mt-3">
    <div class="card-body">
        <fieldset>
            <legend>Descrizione Intervento</legend>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="tipologiaLavori" class="col-sm-6 col-form-label">Tipologia lavori:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="tipologiaLavori">{{intervento?.tipologieLavori?.descrizione}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="isMonitoraggioMensilePostiLetto()" class="form-row">
                <div class="form-group row col-md-6">
                    <label for="postiLettoDaRealizzareTi" class="col-sm-6 col-form-label">Posti letto da realizzare (Terapia intensiva):</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="postiLettoDaRealizzareTi">{{intervento?.postiLettoDaRealizzareTi}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="postiLettoDaRealizzareSi" class="col-sm-6 col-form-label">Posti letto da realizzare (Terapia semi-intensiva):</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="azienda">{{intervento?.postiLettoDaRealizzareSi}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="isMonitoraggioMensilePostiLetto()" class="form-row">
                <div class="form-group row col-md-6">
                    <label for="codiceStruttura" class="col-sm-6 col-form-label">Tipologia intervento:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="codiceStruttura">{{intervento?.tipologieInterventi?.descrizione}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="adeguamentoPl" class="col-sm-6 col-form-label">Adeguamento posti letto esistenti?:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="adeguamentoPl">{{intervento?.adeguamentoPl}}</p>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="superficieIntervento" class="col-sm-6 col-form-label">Superficie complessiva:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="superficieIntervento">{{intervento?.superficieIntervento}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="descrizioneIntervento" class="col-sm-6 col-form-label">Descrizione intervento:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="descrizioneIntervento">{{intervento?.descrizioneIntervento}}</p>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</div>