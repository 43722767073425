import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormCommons } from '../../utility/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../service/login-service'
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/service/token/token.service';
import { UserService } from 'src/app/service/user-service';
// Roberto Serranò
import { environment } from '../../../environments/environment';
import { AlertService } from 'src/app/utility/alert-service';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  form: FormGroup;
  formCommons: FormCommons;
  showPwd: boolean;
  firstAttempt: boolean;
  usernameSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private fc: FormCommons,
    private authService: TokenService,
    private loginService: LoginService
    // Roberto Serranò
    ,private alertService: AlertService
  ) {
    this.formCommons = fc;
  }

  ngOnInit(): void {
    this.showPwd = false;
    this.firstAttempt = false;
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    let usernameControl = this.form.get('username');
    this.usernameSub = usernameControl.valueChanges.subscribe(() => {
      if (usernameControl.value) {
        usernameControl.patchValue(usernameControl.value.toUpperCase(), {
          emitEvent: false,
        });
      }
    });
  }

  loginArpa(): void {
    if (sessionStorage.getItem("access_token")) { 
      this.router.navigate(["/home"]);
    } else {
      this.authService.getAuthCode();
    }
    //this.fakeLogin();
  }
  loginLocal(): void {
    this.fakeLogin();
  }
  fakeLogin() {
    this.firstAttempt = true;
    this.formCommons.setAllTouched(this.form);    
    const body = {
        // username: 'FRMTTR76M06B715E',
        // password: '$2a$10$'
        // username: 'CSRGGL44L13H501E',
        // password: '$2a$10$9BQKBpteRzJHfkXaSWRIp.2X/hagp9F0wkCGpLUpk3wQnW4SvYtYS'
        username: 'SRRRRT73R03G317R', password: '$2a$10$'        
      }
    this.loginService.login(body).then(result => {
      let nextUrl = '/home';
      if (this.route.snapshot.params.returnUrl) {
        nextUrl = this.route.snapshot.params.returnUrl;
      }
      this.router.navigateByUrl(nextUrl);
    });

  }

  ngOnDestroy(): void {
    this.usernameSub.unsubscribe();
  }

  toggleShowPwd() {
    this.showPwd = !this.showPwd;
  }

  login() {
    this.firstAttempt = true;
    this.formCommons.setAllTouched(this.form);
    if (!this.form.valid) {
      return;
    }
    const body = {
      username: this.form.value.username,
      password: this.form.value.password
    }
    
    this.loginService.login(body).then(result => {
      let nextUrl = '/home';
      if (this.route.snapshot.params.returnUrl) {
        nextUrl = this.route.snapshot.params.returnUrl;
      }
      this.router.navigateByUrl(nextUrl);
    });
  }

  logout() {
    this.loginService.logout().then(()=>{
      this.router.navigateByUrl('/login');
    })
  }
}
