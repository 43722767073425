<div class="card">
      <div class="card-body">
          <form [formGroup]="form" (ngSubmit)="submit()">
              <fieldset>
                  <legend>{{ formTitle }}</legend>
                  <div class="form-row">
                      <div *ngIf="adminRole" class="form-group col-md-5">
                          <label for="azienda">Azienda</label>
                          <select
                            [ngClass]="{
                              'form-control custom-select': true
                            }"
                            id="azienda"
                            formControlName="azienda"
                          >
                            <option></option>
                            <ng-container *ngFor="let azienda of aziende">
                              <option [ngValue]="azienda">{{ azienda.azienda }}</option>
                            </ng-container>
                          </select>
                      </div>
                      
                  </div>
                  <div class="form-row">
                      <div class="form-group col-md-5">
                        <label for="dataInizioPeriodo"
                          >Data inizio periodo</label
                        >
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            [ngClass]="{
                              'form-control': true,
                              'is-invalid':
                                shouldValidate && formCommons.isFieldInvalid(form, 'dataInizioPeriodo')
                            }"
                            class="form-control"
                            id="dataInizioPeriodo"
                            aria-describedby="dataInizioPeriodoSmall"
                            formControlName="dataInizioPeriodo"
                            placeholder="gg/mm/aaaa"
                            ngbDatepicker 
                            #dataInizioPeriodoDatePicker="ngbDatepicker"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary"
                              type="button"
                              (click)="dataInizioPeriodoDatePicker.toggle()"
                            >
                              <i class="fas fa-calendar"></i>
                            </button>
                          </div>
                          <small id="dataInizioPeriodoSmall" class="invalid-feedback">
                            <p *ngIf="form.controls.dataInizioPeriodo.errors && form.controls.dataInizioPeriodo.errors.ngbDate">La data non &egrave; in un formato valido</p>
                          </small>
                        </div>
                      </div>
                      <div class="form-group col-md-5">
                          <label for="dataFinePeriodo"
                            >Data fine periodo</label
                          >
                          <div class="input-group mb-2">
                            <input
                              type="text"
                              [ngClass]="{
                                'form-control': true,
                                'is-invalid':
                                  shouldValidate && formCommons.isFieldInvalid(form, 'dataFinePeriodo')
                              }"
                              class="form-control"
                              id="dataFinePeriodo"
                              aria-describedby="dataFinePeriodoSmall"
                              formControlName="dataFinePeriodo"
                              placeholder="gg/mm/aaaa"
                              ngbDatepicker 
                              #dataFinePeriodoDatePicker="ngbDatepicker"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary"
                                type="button"
                                (click)="dataFinePeriodoDatePicker.toggle()"
                              >
                                <i class="fas fa-calendar"></i>
                              </button>
                            </div>
                            <small id="dataFinePeriodoSmall" class="invalid-feedback">
                              <p *ngIf="form.controls.dataFinePeriodo.errors && form.controls.dataFinePeriodo.errors.ngbDate">La data non &egrave; in un formato valido</p>
                            </small>
                          </div>
                      </div>
                  </div>
                  <div>
                    <button type="reset" class="btn btn-light mr-2">Svuota campi</button>
                    <button type="submit" class="btn btn-primary">Ricerca</button>
                  </div>
                  <div *ngIf="adminRole" class="btn btn-light mr-2">
                    <button id="downloadMassivoButton" title="Download Monitoraggio" class="btn btn-secondary" type="button" (click)="downloadMassivo()">Export Massivo</button>
                  </div>
              </fieldset>
          </form>
      </div>
  </div>
  <div id="risultatiRicercaMonitoraggio" [ngbCollapse]="!ricercaMonitoraggioList">
      <table class="table table-striped table-bordered mt-3">
          <thead>
              <tr>
                  <th scope="col" sortable="descrizioneAnagrafica" (sort)="onSort($event)">Azienda</th>
                  <th scope="col" sortable="dataInizioPeriodo" (sort)="onSort($event)">Data inizio periodo</th>
                  <th scope="col" sortable="dataFinePeriodo" (sort)="onSort($event)">Data fine periodo</th>
                  <th scope="col" sortable="dataInvioMinistero" (sort)="onSort($event)">Data invio al ministero</th>
              </tr>
          </thead>
          <tbody *ngIf="ricercaMonitoraggioList && ricercaMonitoraggioList.length === 0">
              <tr><h6 class="mt-2">Risultati non trovati</h6></tr>
          </tbody>
          <tbody *ngIf="ricercaMonitoraggioList && ricercaMonitoraggioList.length !== 0">
              <tr *ngFor="let monitoraggio of ricercaMonitoraggioList">
                  <td><ngb-highlight [result]="monitoraggio.descrizioneAnagrafica"></ngb-highlight></td>
                  <td><ngb-highlight [result]="monitoraggio.dataInizioPeriodo"></ngb-highlight></td>
                  <td><ngb-highlight [result]="monitoraggio.dataFinePeriodo"></ngb-highlight></td>
                  <td><ngb-highlight [result]="monitoraggio.dataInvioMinistero"></ngb-highlight></td>
                  <td>
                    <button id="downloadMonitoraggioButton" title="Download Monitoraggio" class="btn" type="button" (click)="downloadMonitoraggio($event, monitoraggio)"><i class="fas fa-file-download"></i></button>
                    <button *ngIf="adminRole && !monitoraggio.dataInvioMinistero" id="inviaAlMinisteroMonitoraggioButton" title="Invia al Ministero" class="btn" type="button" (click)="confirmInviaAlMinisteroMonitoraggio($event, monitoraggio)"><i class="fas fa-paper-plane"></i></button>
                  </td>
              </tr>
          </tbody>
      </table>
      <div id="paginazione" *ngIf="ricercaMonitoraggioList && ricercaMonitoraggioList.length !== 0">
        <div class="row">
          <div class="input-group col-md-4">
            <label for="numero-voci"
            >Numero voci:</label>
            <div class="ml-3">
              {{numeroVoci}}
            </div> 
          </div> 
          <div class="col-md-8">
            <div class="d-flex justify-content-end">
                <ngb-pagination
                    [collectionSize]="collectionSize" 
                    [(page)]="page" 
                    [pageSize]="pageSize"
                    (pageChange)="getPage($event)" class="d-flex justify-content-end" size="sm"
                >
                </ngb-pagination>
            </div>
          </div>
      </div>
  </div>
</div>