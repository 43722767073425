<div class="card custom-card-no-top-border">
    <div class="card-body">
      <fieldset>
        <div class="form-row">
          <div class="col-md-5">
            <label for="codiceIntervento">Codice Intervento</label>
            <select
              [ngClass]="{
                'form-control custom-select': true,
                'is-invalid':false}"
              id="codiceIntervento"
              [(ngModel)]="selectedCodiceIntervento"
              (change)="changeSelectCodiceIntevento()"
            >
            <option></option>
              <ng-container *ngFor="let codiceIntervento of codiciIntervento">
                <option [ngValue]="codiceIntervento">{{ codiceIntervento.descrizione }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </fieldset>
      <app-monitoraggio-mensile-dati-anagrafici
        [datiAnagrafici]="datiAnagrafici"
        [contestoOperativo]="contestoOperativo"
      >
      </app-monitoraggio-mensile-dati-anagrafici>
      <app-monitoraggio-mensile-intervento
        [intervento]="intervento"
        [contestoOperativo]="contestoOperativo"
      >
      </app-monitoraggio-mensile-intervento>
      <app-monitoraggio-mensile-quadro-economico
        [quadroEconomico]="quadroEconomico"
        [contestoOperativo]="contestoOperativo"
      >
      </app-monitoraggio-mensile-quadro-economico>
      <app-monitoraggio-mensile-fonti-finanziamento
        [quadroEconomico]="quadroEconomico"
        [contestoOperativo]="contestoOperativo"
      >
      </app-monitoraggio-mensile-fonti-finanziamento>
      <app-monitoraggio-mensile-pl-ps
        [contestoOperativo]="contestoOperativo"
        [statiAttuazioneServizi]="statiAttuazioneServizi"
        [statiAttuazioneLavori]="statiAttuazioneLavori"
        [statiAttuazioneAttrezzature]="statiAttuazioneAttrezzature"
        [monitoraggio]="monitoraggio"
        [shouldValidate]="shouldValidate"
        [pageStatus]="pageStatus"
        (preSubmit)="preSubmitMonitoraggio($event)"
        (onSubmit)="onSubmitMonitoraggio($event)"
        (onUpdate)="onUpdateMonitoraggio($event)"
        >
      </app-monitoraggio-mensile-pl-ps>
    </div>
  </div>
  