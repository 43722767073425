import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user-service';
import { CodiciInterventoService } from 'src/app/service/codici-intervento-service';
import { DatiAnagraficiService } from 'src/app/service/dati-anagrafici-service';
import { StatiAttuazioneService } from 'src/app/service/stati-attuazione-service';
import { MonitoraggiMensiliTiService } from 'src/app/service/monitoraggi-mensili-ti-service';
import { MonitoraggiMensiliPsService } from 'src/app/service/monitoraggi-mensili-ps-service';
import { MonitoraggiMensiliMsService } from 'src/app/service/monitoraggi-mensili-ms-service';
import { CO_MON_MENSILE_TI, CO_MON_MENSILE_PS, CO_MON_MENSILE_MS } from '../../tipologiche/contesto-operativo';
import { TSA_SERVIZI, TSA_LAVORI, TSA_ATTREZZATURE, TSA_AMBULANZE } from '../../tipologiche/tipo-stati-attuazione';
import { TIPO_MON_MENSILE } from '../../tipologiche/tipo-monitoraggio';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inserimento-monitoraggio-mensile',
  templateUrl: './inserimento-monitoraggio-mensile.component.html',
  styleUrls: ['./inserimento-monitoraggio-mensile.component.css']
})
export class InserimentoMonitoraggioMensileComponent implements OnInit {

  adminRole:boolean;
  userRole:boolean;
  userSubscription: Subscription;

  active: number = 1;
  firstAttempt: boolean;
  pageStatusPL: string = 'NEW';
  pageStatusPS: string = 'NEW';
  pageStatusMS: string = 'NEW';

  idAnagrafica: any;
  periodoRiferimento: any;
  codiciInterventoPL: any[]=[];
  codiciInterventoPS: any[]=[];
  codiciInterventoMS: any[]=[];
  localCodiceInterventoPL: any;
  localCodiceInterventoMS: any;
  localCodiceInterventoPS: any;
  monitoraggioPL: any;
  monitoraggioPS: any;
  monitoraggioMS: any;
  localMonitoraggioPL: any = {
    isDefault: false,
  };
  localMonitoraggioMS: any = {
    isDefault: false,
  };
  localMonitoraggioPS: any = {
    isDefault: false,
  };
  statiAttuazioneServizi: any[]=[];
  statiAttuazioneLavori: any[]=[];
  statiAttuazioneAttrezzature: any[]=[];
  statiAttuazioneAmbulanze: any[]=[];
  aziende: any[] = [];
  selectedAzienda: any;
  selectedCodiceInterventoPL: any;
  selectedCodiceInterventoPS: any;
  selectedCodiceInterventoMS: any;

  constructor(
    private userService: UserService,
    private datiAnagraficiService: DatiAnagraficiService,
    private codiciInterventoService: CodiciInterventoService,
    private statiAttuazioneService: StatiAttuazioneService,
    private monitoraggiMensiliTiService: MonitoraggiMensiliTiService,
    private monitoraggiMensiliPsService: MonitoraggiMensiliPsService,
    private monitoraggiMensiliMsService: MonitoraggiMensiliMsService
  ) {}

  ngOnInit(): void {
    this.idAnagrafica = this.userService.getIdAnagrafica();
    this.loadCodiciInterventoPL();
    this.loadCodiciInterventoPS();
    this.loadCodiciInterventoMS();
    this.loadStatiAttuazioneServizi();
    this.loadStatiAttuazioneLavori();
    this.loadStatiAttuazioneAttrezzature();
    this.loadStatiAttuazioneAmbulanze();
    this.loadAziende();
    this.userSubscription = this.userService.user.subscribe(usr => {
      this.manageUser(usr);
    });
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  manageUser(user: any) {
    this.adminRole = this.userService.isAdmin();
    this.userRole = this.userService.isUser();
  }

  loadAziende() {
    this.datiAnagraficiService.getAziende().then((result) => {
      this.aziende = result;
    });
  }

  changeSelectAzienda() {
    this.codiciInterventoPL = [];
    this.codiciInterventoPS = [];
    this.codiciInterventoMS = [];
    this.localCodiceInterventoPL = {};
    this.localCodiceInterventoMS = {};
    this.localCodiceInterventoPS = {};
    this.monitoraggioPL = {};
    this.monitoraggioPS = {};
    this.monitoraggioMS = {};
    this.localMonitoraggioPL = {
      isDefault: false,
    };
    this.localMonitoraggioMS = {
      isDefault: false,
    };
    this.localMonitoraggioPS = {
      isDefault: false,
    };
    this.selectedCodiceInterventoPL = undefined;
    this.selectedCodiceInterventoPS = undefined;
    this.selectedCodiceInterventoMS = undefined;
    
    this.idAnagrafica = this.selectedAzienda.id;
    this.loadCodiciInterventoPL();
    this.loadCodiciInterventoPS();
    this.loadCodiciInterventoMS();
  }

  loadCodiciInterventoPL() {
    if(!this.idAnagrafica) {
      this.codiciInterventoPL = [];
      return;
    }
    this.codiciInterventoService.getCodiciInterventoByIdAnagraficaIdContesto(this.idAnagrafica, CO_MON_MENSILE_TI).then (result => {
      this.codiciInterventoPL = result;
    }).catch(error => {
      if(error.status === 404) {
        this.codiciInterventoPL = [];
      }
    })
  }

  loadCodiciInterventoPS() {
    if(!this.idAnagrafica) {
      this.codiciInterventoPS = [];
      return;
    }
    this.codiciInterventoService.getCodiciInterventoByIdAnagraficaIdContesto(this.idAnagrafica, CO_MON_MENSILE_PS).then (result => {
      this.codiciInterventoPS = result;
    }).catch(error => {
      if(error.status === 404) {
        this.codiciInterventoPS = [];
      }
    })
  }

  loadCodiciInterventoMS() {
    if(!this.idAnagrafica) {
      this.codiciInterventoMS = [];
      return;
    }
    this.codiciInterventoService.getCodiciInterventoByIdAnagraficaIdContesto(this.idAnagrafica, CO_MON_MENSILE_MS).then (result => {
      this.codiciInterventoMS = result;
    }).catch(error => {
      if(error.status === 404) {
        this.codiciInterventoMS = [];
      }
    })
  }

  loadStatiAttuazioneServizi() {
    this.statiAttuazioneService.getStatiAttuazioneByIdTipoStatoAttuazione(TSA_SERVIZI).then (result => {
      this.statiAttuazioneServizi = result;
    }).catch(error => {
      if(error.status === 404) {
        this.statiAttuazioneServizi = [];
      }
    })
  }

  loadStatiAttuazioneLavori() {
    this.statiAttuazioneService.getStatiAttuazioneByIdTipoStatoAttuazione(TSA_LAVORI).then (result => {
      this.statiAttuazioneLavori = result;
    }).catch(error => {
      if(error.status === 404) {
        this.statiAttuazioneLavori = [];
      }
    })
  }

  loadStatiAttuazioneAttrezzature() {
    this.statiAttuazioneService.getStatiAttuazioneByIdTipoStatoAttuazione(TSA_ATTREZZATURE).then (result => {
      this.statiAttuazioneAttrezzature = result;
    }).catch(error => {
      if(error.status === 404) {
        this.statiAttuazioneAttrezzature = [];
      }
    })
  }

  loadStatiAttuazioneAmbulanze() {
    this.statiAttuazioneService.getStatiAttuazioneByIdTipoStatoAttuazione(TSA_AMBULANZE).then (result => {
      this.statiAttuazioneAmbulanze = result;
    }).catch(error => {
      if(error.status === 404) {
        this.statiAttuazioneAmbulanze = [];
      }
    })
  }

  setIsDefault(isDefault, flagInviatoMinistero) {
    let isDefaultValue = false;
    if(this.userRole){
      isDefaultValue = isDefault || !flagInviatoMinistero;
    } else if(this.adminRole) {
      isDefaultValue = !isDefault && !flagInviatoMinistero;
    }
    return isDefaultValue;
  }

  loadPostiLetto() {
    this.loadDettaglioCodiceInterventoPL();
    this.loadMonitoraggioPostiLetto();
  }

  loadDettaglioCodiceInterventoPL() {
    if(!this.selectedCodiceInterventoPL) {
      this.localCodiceInterventoPL = {}
      return;
    }
    let idCodiceIntervento = this.selectedCodiceInterventoPL.id;
    this.codiciInterventoService.getCodiceInterventoByIdCodice(idCodiceIntervento).then (result => {
      this.localCodiceInterventoPL = result;
    }).catch(error => {
      if(error.status === 404) {
        this.localCodiceInterventoPL = {};
      }
    })
  }

  loadMonitoraggioPostiLetto() {
    this.firstAttempt = false;
    if(!this.selectedCodiceInterventoPL) {
      this.localMonitoraggioPL = {
        isDefault: false,
      }
      return;
    }
    let idCodiceIntervento = this.selectedCodiceInterventoPL.id;
    //localMonitoraggioPL contiene quella attualmente in modifica.
    this.monitoraggiMensiliTiService.getDefaultMensile(TIPO_MON_MENSILE, idCodiceIntervento).then (result => {
      this.periodoRiferimento = result?.monitoraggiMensili?.monitoraggi?.calendarioMonitoraggio;
      //posso modificare il monitoraggio fino a che non scade il periodo di inserimento
      //e fino a che non e' inviato al ministero
      let flagInviatoMinistero = result?.monitoraggiMensili?.monitoraggi?.inviatoMinistero;
      this.pageStatusPL = result?.monitoraggiMensili?.monitoraggi?.isDefault ? 'NEW' : 'UPDATE';

      this.monitoraggioPL = {
        ...result
      }
      
      this.localMonitoraggioPL = {
        serviziOrdAttivazione: result?.serviziOrdAttivazione,
        serviziStatoAttuazione: result?.serviziStatoAttuazione,
        serviziMesiComplessivi: result?.serviziMesiComplessivi,
        serviziPercentualeAttiv: result?.serviziPercentualeAttiv,
        serviziNote: result?.serviziNote,
        note: result?.note,
        lavoriOrdAttivazione: result?.lavoriOrdAttivazione,
        lavoriStatoAttuazione: result?.lavoriStatoAttuazione,
        lavoriMesiComplessivi: result?.lavoriMesiComplessivi,
        lavoriPercentualeAttiv: result?.lavoriPercentualeAttiv,
        lavoriNote: result?.lavoriNote,
        attrMesiComplessivi: result?.attrMesiComplessivi,
        attrStatoAttuazione: result?.attrStatoAttuazione,
        attrImporto: result?.attrImporto,
        attrNote: result?.attrNote,
        durataComplessivaIntervento: result?.durataComplessivaIntervento,
        numeroPlTiAttivati: result?.numeroPlTiAttivati,
        numeroPlStiAttivati: result?.numeroPlStiAttivati,
        dataPrevistaConclusione: result?.dataPrevistaConclusione,
        isDefault: this.setIsDefault(result?.monitoraggiMensili?.monitoraggi?.isDefault, flagInviatoMinistero)
      };
    }).catch(error => {
      if(error.status === 404) {
        this.localMonitoraggioPL = {
          isDefault: true
        };
      } else if(error.status === 500){
        this.localMonitoraggioPL = {
          isDefault: false
        };
      }
    })
  }

  loadProntoSoccorso() {
    this.loadDettaglioCodiceInterventoPS();
    this.loadMonitoraggioProntoSoccorso();
  }

  loadDettaglioCodiceInterventoPS() {
    if(!this.selectedCodiceInterventoPS) {
      this.localCodiceInterventoPS = {}
      return;
    }
    let idCodiceIntervento = this.selectedCodiceInterventoPS.id;
    this.codiciInterventoService.getCodiceInterventoByIdCodice(idCodiceIntervento).then (result => {
      this.localCodiceInterventoPS = result;
    }).catch(error => {
      if(error.status === 404) {
        this.localCodiceInterventoPS = {};
      }
    })
  }

  loadMonitoraggioProntoSoccorso() {
    this.firstAttempt = false;
    if(!this.selectedCodiceInterventoPS) {
      this.localMonitoraggioPS = {
        isDefault: false,
      }
      return;
    }
    let idCodiceIntervento = this.selectedCodiceInterventoPS.id;
    //localMonitoraggioPS contiene quella attualmente in modifica.
    this.monitoraggiMensiliPsService.getDefaultMensile(TIPO_MON_MENSILE, idCodiceIntervento).then (result => {
      this.periodoRiferimento = result?.monitoraggiMensili?.monitoraggi?.calendarioMonitoraggio;
      //posso modificare il monitoraggio fino a che non scade il periodo di inserimento
      //e fino a che non e' inviato al ministero
      let flagInviatoMinistero = result?.monitoraggiMensili?.monitoraggi?.inviatoMinistero;
      this.pageStatusPS = result?.monitoraggiMensili?.monitoraggi?.isDefault ? 'NEW' : 'UPDATE';

      this.monitoraggioPS = {
        ...result
      }
      
      this.localMonitoraggioPS = {
        serviziOrdAttivazione: result?.serviziOrdAttivazione,
        serviziStatoAttuazione: result?.serviziStatoAttuazione,
        serviziMesiComplessivi: result?.serviziMesiComplessivi,
        serviziPercentualeAttiv: result?.serviziPercentualeAttiv,
        serviziNote: result?.serviziNote,
        note: result?.note,
        lavoriOrdAttivazione: result?.lavoriOrdAttivazione,
        lavoriStatoAttuazione: result?.lavoriStatoAttuazione,
        lavoriMesiComplessivi: result?.lavoriMesiComplessivi,
        lavoriPercentualeAttiv: result?.lavoriPercentualeAttiv,
        lavoriNote: result?.lavoriNote,
        attrMesiComplessivi: result?.attrMesiComplessivi,
        attrStatoAttuazione: result?.attrStatoAttuazione,
        attrImporto: result?.attrImporto,
        attrNote: result?.attrNote,
        durataComplessivaIntervento: result?.durataComplessivaIntervento,
        numeroPlTiAttivati: result?.numeroPlTiAttivati,
        numeroPlStiAttivati: result?.numeroPlStiAttivati,
        dataPrevistaConclusione: result?.dataPrevistaConclusione,
        isDefault: this.setIsDefault(result?.monitoraggiMensili?.monitoraggi?.isDefault, flagInviatoMinistero)
      };
    }).catch(error => {
      if(error.status === 404) {
        this.localMonitoraggioPS = {
          isDefault: true
        };
      } else if(error.status === 500){
        this.localMonitoraggioPS = {
          isDefault: false
        };
      }
    })
  }

  loadMezziSoccorso() {
    this.loadDettaglioCodiceInterventoMS();
    this.loadMonitoraggioMezziSoccorso();
  }

  loadDettaglioCodiceInterventoMS() {
    if(!this.selectedCodiceInterventoMS) {
      this.localCodiceInterventoMS = {}
      return;
    }
    let idCodiceIntervento = this.selectedCodiceInterventoMS.id;
    this.codiciInterventoService.getCodiceInterventoByIdCodice(idCodiceIntervento).then (result => {
      this.localCodiceInterventoMS = result;
    }).catch(error => {
      if(error.status === 404) {
        this.localCodiceInterventoMS = {};
      }
    })
  }

  loadMonitoraggioMezziSoccorso() {
    this.firstAttempt = false;
    if(!this.selectedCodiceInterventoMS) {
      this.localMonitoraggioMS = {
        isDefault: false,
      }
      return;
    }
    let idCodiceIntervento = this.selectedCodiceInterventoMS.id;
    //localMonitoraggioPS contiene quella attualmente in modifica.
    this.monitoraggiMensiliMsService.getDefaultMensile(TIPO_MON_MENSILE, idCodiceIntervento).then (result => {
      this.periodoRiferimento = result?.monitoraggiMensili?.monitoraggi?.calendarioMonitoraggio;
      //posso modificare il monitoraggio fino a che non scade il periodo di inserimento
      //e fino a che non e' inviato al ministero
      let flagInviatoMinistero = result?.monitoraggiMensili?.monitoraggi?.inviatoMinistero;
      this.pageStatusMS = result?.monitoraggiMensili?.monitoraggi?.isDefault ? 'NEW' : 'UPDATE';

      this.monitoraggioMS = {
        ...result
      }
      
      this.localMonitoraggioMS = {
        statiAttuazione: result?.statiAttuazione,
        mesiComplessiviPrevisti: result?.mesiComplessiviPrevisti,
        percentualeAttuazione: result?.percentualeAttuazione,
        tempistichePreviste: result?.tempistichePreviste,
        note: result?.note,
        dataPrevistaConclusione: result?.dataPrevistaConclusione,
        isDefault: this.setIsDefault(result?.monitoraggiMensili?.monitoraggi?.isDefault, flagInviatoMinistero)
      };
    }).catch(error => {
      if(error.status === 404) {
        this.localMonitoraggioMS = {
          isDefault: true
        };
      } else if(error.status === 500){
        this.localMonitoraggioMS = {
          isDefault: false
        };
      }
    })
  }

  getSubmitBody(contestoOperativo: string, idCodiceIntervento: any) {
    let body = {
      idUtenteInserimento: this.userService.getIdUtente(),
      codiciIntervento: {
        id: idCodiceIntervento
      },
      monitoraggiMensili: {
        monitoraggi: {
          idAnagrafica: this.idAnagrafica,
          idUtente: this.userService.getIdUtente(),
          calendarioMonitoraggio: {
            id: this.periodoRiferimento.id
          }
        },
        contestoOperativo: {
          id: contestoOperativo
        },
      }
    }
    return body;
  }

  getUpdateBody(updateMonitoraggio:any) {
    let body = {
      ...updateMonitoraggio
    }
    body.idUtenteModifica = this.userService.getIdUtente();
    body.dataModifica = undefined;
    body.monitoraggiMensili.contestoOperativo.descrizione = undefined;
    body.monitoraggiMensili.monitoraggi.hash = undefined;
    body.monitoraggiMensili.monitoraggi.calendarioMonitoraggio.dataInizioInserimento = undefined;
    body.monitoraggiMensili.monitoraggi.calendarioMonitoraggio.dataFineInserimento = undefined;
    return body;
  }

  /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
   preSubmitPostiLetto(values: any) {
    this.localMonitoraggioPL = values;
    this.firstAttempt = true;
  }

  onSubmitPostiLetto(values) {
    //post di value
    let body = this.getSubmitBody(CO_MON_MENSILE_TI, this.selectedCodiceInterventoPL.id);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggiMensiliTiService.save(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadPostiLetto();
    })
  }

  onUpdatePostiLetto(values) {
    //put di value
    let body = this.getUpdateBody(this.monitoraggioPL);
    body =  {
      ...body,
      ...values
    }
    this.monitoraggiMensiliTiService.update(body).then(result => {
      // se la chiamata va a buon fine, ricarico il tab
      this.loadPostiLetto();
    })
  }

   /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
    preSubmitProntoSoccorso(values: any) {
      this.localMonitoraggioPS = values;
      this.firstAttempt = true;
    }
  
    onSubmitProntoSoccorso(values) {
      //post di value
      let body = this.getSubmitBody(CO_MON_MENSILE_PS, this.selectedCodiceInterventoPS.id);
      body =  {
        ...body,
        ...values
      }
      this.monitoraggiMensiliPsService.save(body).then(result => {
        // se la chiamata va a buon fine, ricarico il tab
        this.loadProntoSoccorso();
      })
    }

    onUpdateProntoSoccorso(values) {
      //put di value
      let body = this.getUpdateBody(this.monitoraggioPS);
      body =  {
        ...body,
        ...values
      }
      this.monitoraggiMensiliPsService.update(body).then(result => {
        // se la chiamata va a buon fine, ricarico il tab
        this.loadProntoSoccorso();
      })
    }

    /**
   * utilizzata per salvare una copia del monitoraggio nel caso di navigazione tra più pagine. 
   * @param values 
   */
     preSubmitMezziSoccorso(values: any) {
      this.localMonitoraggioMS = values;
      this.firstAttempt = true;
    }
  
    onSubmitMezziSoccorso(values) {
      //post di value
      let body = this.getSubmitBody(CO_MON_MENSILE_MS, this.selectedCodiceInterventoMS.id);
      body =  {
        ...body,
        ...values
      }
      this.monitoraggiMensiliMsService.save(body).then(result => {
        // se la chiamata va a buon fine, ricarico il tab
        this.loadMezziSoccorso();
      })
    }

    onUpdateMezziSoccorso(values) {
      //put di value
      let body = this.getUpdateBody(this.monitoraggioMS);
      body =  {
        ...body,
        ...values
      }
      this.monitoraggiMensiliMsService.update(body).then(result => {
        // se la chiamata va a buon fine, ricarico il tab
        this.loadMezziSoccorso();
      })
    }

}
