<app-container>
    <h3>Inserimento Dati Monitoraggio Mensile</h3>
    <p *ngIf="periodoRiferimento">Periodo di riferimento: <strong>{{periodoRiferimento.dataInizioInserimento}}</strong> - <strong>{{periodoRiferimento.dataFineInserimento}}</strong></p>
    <div class="mt-3" *ngIf="adminRole">
      <fieldset>
        <div class="form-row">
          <div class="col-md-5">
            <label for="azienda">Azienda</label>
            <select
              [ngClass]="{
                'form-control custom-select': true,
                'is-invalid':false}"
              id="azienda"
              [(ngModel)]="selectedAzienda"
              (change)="changeSelectAzienda()"
            >
            <option></option>
              <ng-container *ngFor="let azienda of aziende">
                <option [ngValue]="azienda">{{ azienda.azienda }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </fieldset>
    </div>
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs mt-5">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Posti Letto Terapia Intensiva e Sub Intensiva</a>
        <ng-template ngbNavContent>
          <app-monitoraggio-mensile-posti-letto
            [codiciIntervento]="codiciInterventoPL"
            [statiAttuazioneServizi]="statiAttuazioneServizi"
            [statiAttuazioneLavori]="statiAttuazioneLavori"
            [statiAttuazioneAttrezzature]="statiAttuazioneAttrezzature"
            [(selectedCodiceIntervento)]="selectedCodiceInterventoPL"
            [datiAnagrafici]="localCodiceInterventoPL?.datiAnagrafici"
            [intervento]="localCodiceInterventoPL?.descrizioneIntervento"
            [quadroEconomico]="localCodiceInterventoPL?.quadroEconomico"
            [monitoraggio]="localMonitoraggioPL"
            [periodoRiferimento]="periodoRiferimento"
            [shouldValidate]="firstAttempt"
            [pageStatus]="pageStatusPL"
            (changeSelect)="loadPostiLetto()"
            (preSubmit)="preSubmitPostiLetto($event)"
            (onSubmit)="onSubmitPostiLetto($event)"
            (onUpdate)="onUpdatePostiLetto($event)"
          ></app-monitoraggio-mensile-posti-letto>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Interventi Pronto Soccorso</a>
        <ng-template ngbNavContent>
          <app-monitoraggio-mensile-pronto-soccorso
            [codiciIntervento]="codiciInterventoPS"
            [statiAttuazioneServizi]="statiAttuazioneServizi"
            [statiAttuazioneLavori]="statiAttuazioneLavori"
            [statiAttuazioneAttrezzature]="statiAttuazioneAttrezzature"
            [(selectedCodiceIntervento)]="selectedCodiceInterventoPS"
            [datiAnagrafici]="localCodiceInterventoPS?.datiAnagrafici"
            [intervento]="localCodiceInterventoPS?.descrizioneIntervento"
            [interventiRistrutturazione]="localCodiceInterventoPS?.interventiRistrutturazione"
            [quadroEconomico]="localCodiceInterventoPS?.quadroEconomico"
            [monitoraggio]="localMonitoraggioPS"
            [periodoRiferimento]="periodoRiferimento"
            [shouldValidate]="firstAttempt"
            [pageStatus]="pageStatusPS"
            (changeSelect)="loadProntoSoccorso()"
            (preSubmit)="preSubmitProntoSoccorso($event)"
            (onSubmit)="onSubmitProntoSoccorso($event)"
            (onUpdate)="onUpdateProntoSoccorso($event)"
          ></app-monitoraggio-mensile-pronto-soccorso>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Interventi mezzi di soccorso</a>
        <ng-template ngbNavContent> 
          <app-monitoraggio-mensile-mezzi-soccorso
            [codiciIntervento]="codiciInterventoMS"
            [statiAttuazioneAmbulanze]="statiAttuazioneAmbulanze"
            [(selectedCodiceIntervento)]="selectedCodiceInterventoMS"
            [datiAnagrafici]="localCodiceInterventoMS?.datiAnagrafici"
            [acquistoAmbulanze]="localCodiceInterventoMS?.acquistoAmbulanze"
            [monitoraggio]="localMonitoraggioMS"
            [periodoRiferimento]="periodoRiferimento"
            [shouldValidate]="firstAttempt"
            [pageStatus]="pageStatusMS"
            (changeSelect)="loadMezziSoccorso()"
            (preSubmit)="preSubmitMezziSoccorso($event)"
            (onSubmit)="onSubmitMezziSoccorso($event)"
            (onUpdate)="onUpdateMezziSoccorso($event)"
          ></app-monitoraggio-mensile-mezzi-soccorso>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-0"></div>
  </app-container>
  