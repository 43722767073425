
import {
    NgbDateStruct,
  } from '@ng-bootstrap/ng-bootstrap';

export class DateUtils {

    public static convertToFeDate(value: string): NgbDateStruct | string {
        if (!value) {
            return '';
        }
        let completeDate = value.split(' ');
        let date = completeDate[0].split('-')
        return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[2], 10),
        }
    }

    

    public static convertToFeDateNullable(value: string): NgbDateStruct | string {
        if (!value) {
            return undefined;
        }
        let completeDate = value.split(' ');
        let date = completeDate[0].split('-')
        return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[2], 10),
        }
    }

    public static convertToBEOnlyDateString(date: NgbDateStruct | null): string {
        if (!date) {
            return ''
        }
        return `${DateUtils.getDay(date)}-${DateUtils.getMonth(date)}-${DateUtils.getYear(date)} 00:00:00`;
    }

    public static convertToBEDateString(date: NgbDateStruct | null): string {
        if (!date) {
            return ''
        }
        return `${DateUtils.getDay(date)}-${DateUtils.getMonth(date)}-${DateUtils.getYear(date)}`;
    }

    public static getDay(date: NgbDateStruct | null): string {
        if (!date) {
            return '00';
        }
        return `${date.day < 10 ? '0' + date.day : date.day}`;
    }

    public static getMonth(date: NgbDateStruct | null): string {
        if (!date) {
            return '00';
        }
        return `${date.month < 10 ? '0' + date.month : date.month}`;
    }

    public static getYear(date: NgbDateStruct | null): string {
        if (!date) {
            return '0000';
        }
        if (date.year < 10) {
            return `${'200' + date.year}`
        } else if (date.year < 100) {
            return `${'20' + date.year}`
        } else if (date.year < 1000) {
            return `${'2' + date.year}`
        }
        return `${date.year}`;
    }

}