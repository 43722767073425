import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login-service';
import { ModalService } from 'src/app/service/modale/modal.service';
import { UserService } from 'src/app/service/user-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    private loginService: LoginService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.get('');
  }

  openModal() {
    this.modalService.openModalGlobal('Titolo', 'MESSAGGIO', 'BOTTONE_OK', () => { console.log("BUTTON OK") }, 'BOTTONE_ANNULLA', () => { console.log("BUTTON ANNULLA") });
  }

}
