import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import localeItExtra from "@angular/common/locales/extra/it";
import { Injector, NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderComponent } from './component/header/header.component';
import { HomeComponent } from './page/home/home.component';
import { InterceptorService } from './service/token/interceptor.service';
import { UtenteNonAbilitatoComponent } from './page/utente-non-abilitato/utente-non-abilitato.component';
import { SessioneScadutaComponent } from './page/sessione-scaduta/sessione-scaduta.component';
import { LoginPageComponent } from './page/login-page/login-page.component';
import { ToastrModule } from 'ngx-toastr';
import { setAppInjector } from './helpers/app-injector';
import { LeftMenuComponent } from './component/left-menu/left-menu.component';
import { ContainerComponent } from './component/container/container.component';
import { InserimentoNuovoUtenteComponent } from './page/inserimento-nuovo-utente/inserimento-nuovo-utente.component';
import { ModificaUtenteComponent } from './page/modifica-utente/modifica-utente.component';
import { DatiUtenteComponent } from './component/dati-utente/dati-utente.component';
import { ModificaDatiUtenteComponent } from './component/modifica-dati-utente/modifica-dati-utente.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InserimentoMonitoraggioPeriodicoComponent } from './page/inserimento-monitoraggio-periodico/inserimento-monitoraggio-periodico.component';
import { InserimentoMonitoraggioMensileComponent } from './page/inserimento-monitoraggio-mensile/inserimento-monitoraggio-mensile.component';
import { MonitoraggioPostiLettoComponent } from './component/monitoraggio-posti-letto/monitoraggio-posti-letto.component';
import { CustomDateParserFormatter } from './utility/custom-date-parser-formatter';
import { MonitoraggioMezziSoccorsoComponent } from './component/monitoraggio-mezzi-soccorso/monitoraggio-mezzi-soccorso.component';
import { MonitoraggioProntoSoccorsoComponent } from './component/monitoraggio-pronto-soccorso/monitoraggio-pronto-soccorso.component';
import { RicercaMonitoraggioPeriodicoComponent } from './page/ricerca-monitoraggio-periodico/ricerca-monitoraggio-periodico.component';
import { SortableHeaderDirective } from './directive/sortable-header.directive';
import { ResetPasswordPageComponent } from './page/reset-password-page/reset-password-page.component';
import { ModalMonitoraggioPeriodicoComponent } from './modal/modal-monitoraggio-periodico/modal-monitoraggio-periodico.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { MonitoraggioMensilePostiLettoComponent } from './component/monitoraggio-mensile-posti-letto/monitoraggio-mensile-posti-letto.component';
import { MonitoraggioMensileDatiAnagraficiComponent } from './component/monitoraggio-mensile-dati-anagrafici/monitoraggio-mensile-dati-anagrafici.component';
import { MonitoraggioMensileInterventoComponent } from './component/monitoraggio-mensile-intervento/monitoraggio-mensile-intervento.component';
import { MonitoraggioMensileQuadroEconomicoComponent } from './component/monitoraggio-mensile-quadro-economico/monitoraggio-mensile-quadro-economico.component';
import { MonitoraggioMensileProntoSoccorsoComponent } from './component/monitoraggio-mensile-pronto-soccorso/monitoraggio-mensile-pronto-soccorso.component';
import { MonitoraggioMensileMezziSoccorsoComponent } from './component/monitoraggio-mensile-mezzi-soccorso/monitoraggio-mensile-mezzi-soccorso.component';
import { MonitoraggioMensileInterventiRistrutturazioneComponent } from './component/monitoraggio-mensile-interventi-ristrutturazione/monitoraggio-mensile-interventi-ristrutturazione.component';
import { MonitoraggioMensilePlPsComponent } from './component/monitoraggio-mensile-pl-ps/monitoraggio-mensile-pl-ps.component';
import { RicercaMonitoraggioMensileComponent } from './page/ricerca-monitoraggio-mensile/ricerca-monitoraggio-mensile.component';
import { RicercaMonitoraggioComponent } from './component/ricerca-monitoraggio/ricerca-monitoraggio.component';
import { MonitoraggioMensileFontiFinanziamentoComponent } from './component/monitoraggio-mensile-fonti-finanziamento/monitoraggio-mensile-fonti-finanziamento.component';

registerLocaleData(localeIt, "it", localeItExtra);

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    UtenteNonAbilitatoComponent,
    SessioneScadutaComponent,
    LoginPageComponent,
    LeftMenuComponent,
    ContainerComponent,
    InserimentoNuovoUtenteComponent,
    ModificaUtenteComponent,
    DatiUtenteComponent,
    ModificaDatiUtenteComponent,
    InserimentoMonitoraggioPeriodicoComponent,
    InserimentoMonitoraggioMensileComponent,
    MonitoraggioPostiLettoComponent,
    MonitoraggioMezziSoccorsoComponent,
    MonitoraggioProntoSoccorsoComponent,
    RicercaMonitoraggioPeriodicoComponent,
    SortableHeaderDirective,
    ResetPasswordPageComponent,
    ModalMonitoraggioPeriodicoComponent,
    MonitoraggioMensilePostiLettoComponent,
    MonitoraggioMensileDatiAnagraficiComponent,
    MonitoraggioMensileInterventoComponent,
    MonitoraggioMensileQuadroEconomicoComponent,
    MonitoraggioMensileProntoSoccorsoComponent,
    MonitoraggioMensileMezziSoccorsoComponent,
    MonitoraggioMensileInterventiRistrutturazioneComponent,
    MonitoraggioMensilePlPsComponent,
    RicercaMonitoraggioMensileComponent,
    RicercaMonitoraggioComponent,
    MonitoraggioMensileFontiFinanziamentoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      disableTimeOut: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: "it"},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    NgbActiveModal
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
