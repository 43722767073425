<div class="card mt-3">
    <div class="card-body">
        <fieldset>
            <legend>Fonti di Finanziamento</legend>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="dl342020" class="col-sm-6 col-form-label">DL 34/2020:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="dl342020">{{quadroEconomico?.dl342020 | currency:'EUR' }}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="dgrt3582020Lr192019" class="col-sm-6 col-form-label">DGRT 358/2020 LR 19/2019:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="dgrt3582020Lr192019">{{quadroEconomico?.dgrt3582020Lr192019 | currency:'EUR'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group row col-md-6">
                    <label for="autofinazAziendale" class="col-sm-6 col-form-label">Autofinanziamento aziendale:</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="autofinazAziendale">{{quadroEconomico?.autofinazAziendale | currency:'EUR'}}</p>
                    </div>
                </div>
                <div class="form-group row col-md-6">
                    <label for="importoComplFinanzIvainc" class="col-sm-6 col-form-label">Importo complessivo (IVA inclusa):</label>
                    <div class="col-sm-6">
                        <p class="form-control-plaintext" id="importoComplFinanzIvainc">{{quadroEconomico?.importoComplFinanzIvainc | currency:'EUR'}}</p>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</div>