import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { Injectable } from '@angular/core';
import { AlertService } from '../utility/alert-service'
import {AppInjector} from '../helpers/app-injector';
import {UserService} from './user-service';
import { pipe } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class RuoliService extends BaseService {

  alertService = AppInjector.get(AlertService);

  constructor(http: HttpClient) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/ruoli`);
  }

  getRuoli(): Promise<any> {
    return super.get('');
  }
}
