<div (mouseover)="handleToken()">
    <div class="wrapper">
      <app-header></app-header>
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin" [fullScreen]="true">
        <p style="color: white"> Caricamento... </p>
      </ngx-spinner>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>


  <div *ngIf="modal_global.open" class="modale-background">
    <div class="modale-content">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{modal_global.title}}</h5>
            <button type="button" class="close" (click)="modal_global.closeModal()" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{modal_global.message}}
          </div>
          <div class="modal-footer">
            <button *ngIf="modal_global.testoBottoneAnnulla" type="button" class="btn btn-primary"
              (click)="modal_global.funzioneBottoneAnnulla()"
              data-dismiss="modal">{{modal_global.testoBottoneAnnulla}}</button>
            <button type="button" class="btn btn-primary" (click)="modal_global.funzioneBottoneOK()"
              data-dismiss="modal">{{modal_global.testoBottoneOK}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>