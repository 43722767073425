import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
  HttpClient,
  HttpBackend,
  HttpResponse,
} from '@angular/common/http';
import { catchError, filter, take, switchMap, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { UtilityService } from './../../utility/utility.service';
import { Router } from '@angular/router';
import { UserService } from '../user-service';
import { AlertService } from 'src/app/utility/alert-service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public http: HttpClient,
    private router: Router,
    private utility: UtilityService,
    private userService: UserService,
    private alertService: AlertService,
    handler: HttpBackend
  ) {
    this.http = new HttpClient(handler);
  }

  addToken(request: HttpRequest<any>, token: String): HttpRequest<any> {
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }
    return request;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(this.addToken(req, this.utility.getToken()))
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse && event.status == 200) {
            if (!this.utility.getToken()) {
              this.utility.addToken(event.headers.get('access_token'));
            }
          }
          return event;
        }),
        catchError((response: HttpErrorResponse) => {
          switch (response.status) {
            case 401:
              if (response.error.errorMessage !== 'Bad credentials') {
                this.router.navigateByUrl('/sessionExpired');
              }
              this.userService.deleteUser();
              break;
            case 403:
              this.router.navigateByUrl('/login');
              this.userService.deleteUser();
              break;
            case 400:
            case 500:
              this.alertService.addErrorMessage('',response.error['500']);
              break;
            default:
              break;
          }
          return throwError({
            status: response?.status,
            statusText: response?.statusText,
            errorMessage: response?.error?.errorMessage,
          });
        })
      );
  }
}
