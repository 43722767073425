<app-container>
  <h3 class="title_1">
    Monitoraggio mensile interventi <br/>
    <h5>Art. 2 Decreto Legge 19 maggio 2020 n. 34</h5>
  </h3>
  <div class="alert alert-info alert-box text_1">
    <p>
      Questo portale permette ai Soggetti Attuatori degli interventi ex art. 2 del Decreto Legge 19 maggio 2020 n. 34 
      di inserire i dati del monitoraggio mensile che la Regione Toscana trasmetter&agrave; 
      successivamente al Ministero della Salute.
    </p>
    <p>
      Istruzioni per l'inserimento dei dati:<br/>
      L'inserimento dei dati deve avvenire entro delle date prefissate 
      che sono specificate sul portale per ogni mese di monitoraggio (data inizio e data fine dell'inserimento).<br/>
      Per le date il formato &egrave; gg/mm/aaaa. <br/>
      In ogni campo il dato deve essere inserito nel formato richiesto. <br/>
      Non sono ammessi campi vuoti: in presenza di campi vuoti il portale non permette di proseguire nell'inserimento dei dati.<br/>
      Nel caso non sia disponibile nessun valore numerico occorre inserire "0,00". <br/>
      Per il campo "Data prevista conclusione intervento" inserire sempre una data in formato gg/mm/aaaa. <br/>
      Per i campi "Numero posti letto TI attivati" e "Numero posti letto TSI attivati" inserire sempre dei numeri interi senza decimali. 
      Nel caso non sia stato realizzato nessun posto letto inserire "0". <br/>
      Le sezioni "Dati anagrafici", "Descrizione Intervento" e "Quadro Economico" di ogni intervento sono precompilate e non possono essere modificate 
      dai Soggetti Attuatori. Le modifiche verranno fatte dalla Regione Toscana solo a seguito di specifiche rimodulazioni degli interventi approvate dal Ministero della Salute.
    </p>
  </div>
</app-container>
