<div class="card custom-card-no-top-border">
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <fieldset>
          <legend>{{ formTitle }}</legend>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="dataRilevazione"
                >Data Rilevazione</label
              >
              <div class="input-group mb-2">
                <input
                  type="text"
                  [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      shouldValidate && formCommons.isFieldInvalid(form, 'dataRilevazione')
                  }"
                  class="form-control"
                  id="dataRilevazione"
                  aria-describedby="dataRilevazioneSmall"
                  formControlName="dataRilevazione"
                  placeholder="gg/mm/aaaa"
                  ngbDatepicker 
                  #dataRilevazioneDatePicker="ngbDatepicker"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    (click)="dataRilevazioneDatePicker.toggle()"
                  >
                    <i class="fas fa-calendar"></i>
                  </button>
                </div>
                <small id="dataRilevazioneSmall" class="invalid-feedback">
                  <p *ngIf="form.controls.dataRilevazione.errors && form.controls.dataRilevazione.errors.required">Il campo &egrave; obbligatorio</p>
                  <p *ngIf="form.controls.dataRilevazione.errors && form.controls.dataRilevazione.errors.ngbDate">La data non &egrave; in un formato valido</p>
                </small>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="daAcquistare"
                >N. ambulanze da acquistare</label
              >
              <input
                type="number"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    shouldValidate &&
                    formCommons.isFieldInvalid(form, 'daAcquistare')
                }"
                min="0"
                class="form-control"
                id="daAcquistare"
                formControlName="daAcquistare"
                aria-describedby="daAcquistareSmall"
                onkeydown="return event.keyCode !== 69"
              />
              <small id="daAcquistareSmall" class="invalid-feedback">
                <p *ngIf="form.controls.daAcquistare.errors && form.controls.daAcquistare.errors.required">Il campo &egrave; obbligatorio</p>
                <p *ngIf="form.controls.daAcquistare.errors && form.controls.daAcquistare.errors.min">Il valore minimo consentito &egrave; 0</p>
                <p *ngIf="form.controls.daAcquistare.errors && form.controls.daAcquistare.errors.pattern">&Egrave; consentito inserire solo numeri</p>
              </small>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="acquistate"
                  >N. ambulanze acquistate (periodo precedente)</label
              >
              <input
                  type="number"
                  [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                      shouldValidate &&
                      formCommons.isFieldInvalid(form, 'acquistate')
                  }"
                  min="0"
                  class="form-control"
                  id="acquistate"
                  formControlName="acquistate"
                  aria-describedby="acquistateSmall"
                  onkeydown="return event.keyCode !== 69"
              />
              <small id="acquistateSmall" class="invalid-feedback">
                  <p *ngIf="form.controls.acquistate.errors && form.controls.acquistate.errors.required">Il campo &egrave; obbligatorio</p>
                  <p *ngIf="form.controls.acquistate.errors && form.controls.acquistate.errors.min">Il valore minimo consentito &egrave; 0</p>
                  <p *ngIf="form.controls.acquistate.errors && form.controls.acquistate.errors.pattern">&Egrave; consentito inserire solo numeri</p>
              </small>
            </div>
            <div class="form-group col-md-6">
              <label for="acquistateAgg"
                  >N. ambulanze acquistate (periodo attuale)</label
              >
              <input
                  type="number"
                  [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                      shouldValidate &&
                      formCommons.isFieldInvalid(form, 'acquistateAgg')
                  }"
                  min="0"
                  class="form-control"
                  id="acquistateAgg"
                  formControlName="acquistateAgg"
                  aria-describedby="acquistateAggSmall"
                  onkeydown="return event.keyCode !== 69"
              />
              <small id="acquistateAggSmall" class="invalid-feedback">
                  <p *ngIf="form.controls.acquistateAgg.errors && form.controls.acquistateAgg.errors.required">Il campo &egrave; obbligatorio</p>
                  <p *ngIf="form.controls.acquistateAgg.errors && form.controls.acquistateAgg.errors.min">Il valore minimo consentito &egrave; 0</p>
                  <p *ngIf="form.controls.acquistateAgg.errors && form.controls.acquistateAgg.errors.pattern">&Egrave; consentito inserire solo numeri</p>
              </small>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="consegnateCollaudate"
                >N. ambulanze consegnate e collaudate (periodo precedente)</label
              >
              <input
                type="number"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    shouldValidate &&
                    formCommons.isFieldInvalid(form, 'consegnateCollaudate')
                }"
                min="0"
                class="form-control"
                id="consegnateCollaudate"
                formControlName="consegnateCollaudate"
                aria-describedby="consegnateCollaudateSmall"
                onkeydown="return event.keyCode !== 69"
              />
              <small id="consegnateCollaudateSmall" class="invalid-feedback">
                <p *ngIf="form.controls.consegnateCollaudate.errors && form.controls.consegnateCollaudate.errors.required">Il campo &egrave; obbligatorio</p>
                <p *ngIf="form.controls.consegnateCollaudate.errors && form.controls.consegnateCollaudate.errors.min">Il valore minimo consentito &egrave; 0</p>
                <p *ngIf="form.controls.consegnateCollaudate.errors && form.controls.consegnateCollaudate.errors.pattern">&Egrave; consentito inserire solo numeri</p>
              </small>
            </div>
            <div class="form-group col-md-6">
              <label for="consegnateCollaudateAgg"
                >N. ambulanze consegnate e collaudate (periodo attuale)</label
              >
              <input
                type="number"
                [ngClass]="{
                  'form-control': true,
                  'is-invalid':
                    shouldValidate &&
                    formCommons.isFieldInvalid(form, 'consegnateCollaudateAgg')
                }"
                min="0"
                class="form-control"
                id="consegnateCollaudateAgg"
                formControlName="consegnateCollaudateAgg"
                aria-describedby="consegnateCollaudateAggSmall"
                onkeydown="return event.keyCode !== 69"
              />
              <small id="consegnateCollaudateAggSmall" class="invalid-feedback">
                <p *ngIf="form.controls.consegnateCollaudateAgg.errors && form.controls.consegnateCollaudateAgg.errors.required">Il campo &egrave; obbligatorio</p>
                <p *ngIf="form.controls.consegnateCollaudateAgg.errors && form.controls.consegnateCollaudateAgg.errors.min">Il valore minimo consentito &egrave; 0</p>
                <p *ngIf="form.controls.consegnateCollaudateAgg.errors && form.controls.consegnateCollaudateAgg.errors.pattern">&Egrave; consentito inserire solo numeri</p>
              </small>
            </div>
          </div>
          <div *ngIf="this.monitoraggioMezziSoccorso && this.monitoraggioMezziSoccorso.isDefault !== false">
            <button type="reset" class="btn btn-light mr-2">Svuota campi</button>
            <button type="submit" class="btn btn-primary">Salva</button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
  