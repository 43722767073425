import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { UtenteNonAbilitatoComponent } from './page/utente-non-abilitato/utente-non-abilitato.component';
import { SessioneScadutaComponent } from './page/sessione-scaduta/sessione-scaduta.component';
import { LoginPageComponent } from './page/login-page/login-page.component';
import { ResetPasswordPageComponent } from './page/reset-password-page/reset-password-page.component';
import { AuthGuard } from './helpers/auth-guard';
import { AdminGuard } from './helpers/admin-guard';
import { UserGuard } from './helpers/user-guard';
import { ResetpwGuard } from './helpers/resetpw-guard';
import { InserimentoNuovoUtenteComponent } from './page/inserimento-nuovo-utente/inserimento-nuovo-utente.component';
import { InserimentoMonitoraggioPeriodicoComponent } from './page/inserimento-monitoraggio-periodico/inserimento-monitoraggio-periodico.component';
import { InserimentoMonitoraggioMensileComponent } from './page/inserimento-monitoraggio-mensile/inserimento-monitoraggio-mensile.component';
import { RicercaMonitoraggioPeriodicoComponent } from './page/ricerca-monitoraggio-periodico/ricerca-monitoraggio-periodico.component';
import { RicercaMonitoraggioMensileComponent } from './page/ricerca-monitoraggio-mensile/ricerca-monitoraggio-mensile.component';
import { ModificaUtenteComponent } from './page/modifica-utente/modifica-utente.component';

@NgModule({
  imports: [RouterModule.forRoot([
    { path: 'home', component: HomeComponent },
    { path: 'sessionExpired', component: SessioneScadutaComponent },
    { path: 'errorPage', component: UtenteNonAbilitatoComponent },
    { path: 'login', component: LoginPageComponent },
    { path: 'reset-password', component: ResetPasswordPageComponent },
    { path: 'inserimentoUtente', component: InserimentoNuovoUtenteComponent },
    { path: 'inserimentoMonitoraggioPeriodico', component: InserimentoMonitoraggioPeriodicoComponent },
    { path: 'inserimentoMonitoraggioMensile', component: InserimentoMonitoraggioMensileComponent },
    { path: 'ricercaMonitoraggioPeriodico', component: RicercaMonitoraggioPeriodicoComponent },
    { path: 'ricercaMonitoraggioMensile', component: RicercaMonitoraggioMensileComponent },
    { path: 'modificaUtente', component: ModificaUtenteComponent },
    { path: '**', redirectTo: '/login', pathMatch: 'full' }
  ]
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
