<nav class="navbar navbar-expand-xl navbar-light bg-light">
  <a class="navbar-brand" href="#"></a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#leftMenu"
    aria-controls="leftMenu"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="leftMenu">
    <ul class="navbar-nav flex-column">
      <li class="nav-item">
        <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
      </li>
      <li  *ngIf="adminRole" class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#navbarDropdownMenuUtente"
          role="button"
          data-toggle="collapse"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Gestione Utenti
        </a>
        <div class="collapse" id="navbarDropdownMenuUtente">
          <li class="nav-item">
            <a class="nav-link" routerLink="/inserimentoUtente" routerLinkActive="active">Nuovo utente</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/modificaUtente" routerLinkActive="active">Modifica utente</a>
          </li>
          <li *ngIf="false" class="nav-item">
            <a class="nav-link" routerLink="/resetPassword" routerLinkActive="active">Reset password</a>
          </li>
        </div>
      </li>
      <!--li *ngIf="adminRole" class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#navbarDropdownMenuMonitoraggioPeriodico"
          role="button"
          data-toggle="collapse"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Monitoraggio Quindicinale
        </a>
        <div class="collapse" id="navbarDropdownMenuMonitoraggioPeriodico">
          <li *ngIf="userRole" class="nav-item">
            <a class="nav-link" routerLink="/inserimentoMonitoraggioPeriodico" routerLinkActive="active">Inserimento/Modifica</a>
          </li>
          <li *ngIf="adminRole" class="nav-item">
            <a class="nav-link" routerLink="/inserimentoMonitoraggioPeriodico" routerLinkActive="active">Modifica</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/ricercaMonitoraggioPeriodico" routerLinkActive="active">Ricerca</a>
          </li>
        </div>
      </li-->
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#navbarDropdownMenuMonitoraggioMensile"
          role="button"
          data-toggle="collapse"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Monitoraggio Mensile
        </a>
        <div class="collapse" id="navbarDropdownMenuMonitoraggioMensile">
          <li *ngIf="userRole" class="nav-item">
            <a class="nav-link" routerLink="/inserimentoMonitoraggioMensile" routerLinkActive="active">Inserimento/Modifica</a>
          </li>
          <li *ngIf="adminRole" class="nav-item">
            <a class="nav-link" routerLink="/inserimentoMonitoraggioMensile" routerLinkActive="active">Modifica</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/ricercaMonitoraggioMensile" routerLinkActive="active">Ricerca</a>
          </li>
        </div>
      </li>
    </ul>
  </div>
</nav>
