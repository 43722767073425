import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { DateUtils } from 'src/app/utility/date-utils';

@Component({
  selector: 'app-monitoraggio-pronto-soccorso',
  templateUrl: './monitoraggio-pronto-soccorso.component.html',
  styleUrls: ['./monitoraggio-pronto-soccorso.component.css']
})
export class MonitoraggioProntoSoccorsoComponent implements OnInit {

  formCommons = AppInjector.get(FormCommons);
  form: FormGroup;

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = '';

  _monitoraggioProntoSoccorso: any;
  
  get monitoraggioProntoSoccorso(): any {
    return this._monitoraggioProntoSoccorso;
  };

  @Input()
  set monitoraggioProntoSoccorso(value: any) {
    this._monitoraggioProntoSoccorso = value;
    if(this.form) {
      if (value?.isDefault === false) {
        this.form.disable();
      } else {
        this.form.enable();
      }
      this.form.patchValue({
        interventiPrevisti: value?.interventiPrevisti,
        intervRistrInCorso: value?.intervRistrInCorso,
        intervRistrConclusi: value?.intervRistrConclusi,
        dataPrevistaConclusione: DateUtils.convertToFeDate(value?.dataPrevistaConclusione),
        dataRilevazione: DateUtils.convertToFeDate(value?.dataRilevazione)
      });
    }
  }

  _stabilimenti: any;

  get stabilimenti() {
    return this._stabilimenti;
  }

  @Input()
  set stabilimenti(value) {
    this._stabilimenti = value;
  }

  _selectedStabilimento: any;

  get selectedStabilimento() {
    return this._selectedStabilimento;
  }

  @Input()
  set selectedStabilimento(value) {
    this._selectedStabilimento = value;
  }

  @Output()
  selectedStabilimentoChange = new EventEmitter<any>();

  @Output()
  changeSelect = new EventEmitter<any>();

  @Output()
  preSubmit = new EventEmitter<any>();

  @Output()
  onSubmit = new EventEmitter<any>();

  @Output()
  onUpdate = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      interventiPrevisti: [
        this.monitoraggioProntoSoccorso?.interventiPrevisti,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      intervRistrInCorso: [
        this.monitoraggioProntoSoccorso?.intervRistrInCorso,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      intervRistrConclusi: [
        this.monitoraggioProntoSoccorso?.intervRistrConclusi,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      dataRilevazione: [
        DateUtils.convertToFeDate(this.monitoraggioProntoSoccorso?.dataRilevazione),
        [Validators.required],
      ],
      dataPrevistaConclusione: [
        DateUtils.convertToFeDate(this.monitoraggioProntoSoccorso?.dataPrevistaConclusione),
        [Validators.required],
      ],
    });

    if(this.pageStatus === 'VIEW' || this.monitoraggioProntoSoccorso?.isDefault === false) {
      this.form.disable();
    }
  }

  submit() {
    this.form.markAllAsTouched();
    let json = {
      interventiPrevisti: this.form.controls.interventiPrevisti.value,
      intervRistrInCorso: this.form.controls.intervRistrInCorso.value,
      intervRistrConclusi: this.form.controls.intervRistrConclusi.value,
      dataRilevazione: DateUtils.convertToBEOnlyDateString(
        this.form.controls.dataRilevazione.value
      ),
      dataPrevistaConclusione: DateUtils.convertToBEOnlyDateString(
        this.form.controls.dataPrevistaConclusione.value
      ),
    };
    this.preSubmit.emit(json);
    if (this.form.invalid) {
      return;
    }
    if(this.pageStatus === 'NEW') {
      this.onSubmit.emit(json);
    } else if(this.pageStatus === 'UPDATE') {
      this.onUpdate.emit(json);
    }
  }

  changeSelectStabilimento(){
    this.selectedStabilimentoChange.emit(this.selectedStabilimento);
    this.changeSelect.emit();
  }

}
