import { Component, OnInit, Input } from '@angular/core';
import { CO_MON_MENSILE_TI, CO_MON_MENSILE_PS, CO_MON_MENSILE_MS } from '../../tipologiche/contesto-operativo';

@Component({
  selector: 'app-monitoraggio-mensile-intervento',
  templateUrl: './monitoraggio-mensile-intervento.component.html',
  styleUrls: ['./monitoraggio-mensile-intervento.component.css']
})
export class MonitoraggioMensileInterventoComponent implements OnInit {
  @Input()
  intervento: any;

  @Input()
  contestoOperativo: string;

  constructor() { }

  ngOnInit(): void {
  }

  isMonitoraggioMensilePostiLetto(){
    return CO_MON_MENSILE_TI === this.contestoOperativo;
  }
}
