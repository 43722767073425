import { Component, EventEmitter, Input, OnInit, Output, ViewChild, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { SortableHeaderDirective, SortEvent } from 'src/app/directive/sortable-header.directive';
import { DateUtils } from 'src/app/utility/date-utils';
import { SearchBaseComponent } from 'src/app/page/search-base-component';
import { DatiAnagraficiService } from '../../service/dati-anagrafici-service';
import { MonitoraggiBisettimanaliService } from '../../service/monitoraggi-bisettimanali-service';
import { ModalService } from 'src/app/service/modale/modal.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/service/user-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMonitoraggioPeriodicoComponent } from 'src/app/modal/modal-monitoraggio-periodico/modal-monitoraggio-periodico.component';
import {TIPO_MON_QUINDICINALE} from 'src/app/tipologiche/tipo-monitoraggio';
import { RicercaMonitoraggioComponent } from 'src/app/component/ricerca-monitoraggio/ricerca-monitoraggio.component';

@Component({
  selector: 'app-ricerca-monitoraggio-periodico',
  templateUrl: './ricerca-monitoraggio-periodico.component.html',
  styleUrls: ['./ricerca-monitoraggio-periodico.component.css']
})
export class RicercaMonitoraggioPeriodicoComponent implements OnInit {

  tipoMonitoraggio = TIPO_MON_QUINDICINALE;
  ricercaMonitoraggioPeriodicoList: any[] = undefined;

  @ViewChild(RicercaMonitoraggioComponent) rmc!: RicercaMonitoraggioComponent;

  constructor(
    private fb: FormBuilder,
    private datiAnagraficiService: DatiAnagraficiService,
    private monitoraggiBisettimanaliService: MonitoraggiBisettimanaliService,
    private modalService: ModalService,
    private userService: UserService,
    private monitoraggioPeriodicoModalService: NgbModal
    ) {
    }

  ngOnInit(): void {  }

  searchMonitoraggioBisettimanale(criteri:any){
    this.monitoraggiBisettimanaliService.search(criteri).then(result => {
      this.ricercaMonitoraggioPeriodicoList = result?.monitoraggioDto;
      this.rmc.setPaginatorValues({page: result?.paginaCorrente, totalCount: result?.totaleRecord});
    }).catch(error => {
      if(error.status === 404) {
        this.ricercaMonitoraggioPeriodicoList = [];
      }
    })
  
  }

  downloadMonitoraggioBisettimanale(monitoraggio){
    this.monitoraggiBisettimanaliService.downladMonitoraggio(monitoraggio.idPeriodo);
  }

  confirmInviaAlMinisteroMonitoraggioBisettimanale(monitoraggio) {
    this.modalService.openModalGlobal(
      'Invia Monitoraggio al Ministero',
      "Vuoi confermare l'invio del monitoraggio al Ministero?",
      'Conferma',
      () => {
        this.inviaAlMinisteroMonitoraggioBisettimanale(monitoraggio);
      },
      'Annulla',
      () => {
        this.modalService.closeModal();
      }
    );
  }

  inviaAlMinisteroMonitoraggioBisettimanale(monitoraggio){
    this.modalService.closeModal();
    this.monitoraggiBisettimanaliService.inviaMinistero(monitoraggio.idPeriodo).then(() => {
      this.searchMonitoraggioBisettimanale(this.rmc.ricercaMonitoraggioCriteri);
    })
  }

  updateMonitoraggioPeriodico(event, monitoraggio:any) {
    /*
    * aggiungere il seguente pulsante per far aprire la modale:
    * <button *ngIf="userRole && !monitoraggio.dataInvioMinistero" 
    * id="modificaMonitoraggioButton" title="Modifica Monitoraggio" class="btn" 
    * type="button" (click)="updateMonitoraggioPeriodico($event, monitoraggio)">
    * <i class="fas fa-edit"></i></button>
    */
    this.openMonitoraggioPeriodicoModal('UPDATE', 'Modifica Dati Monitoraggio', monitoraggio)
  }

  openMonitoraggioPeriodicoModal(pageStatus:string, modalTitle:string, monitoraggio:any) {
    const modalRef = this.monitoraggioPeriodicoModalService.open(ModalMonitoraggioPeriodicoComponent);
    modalRef.componentInstance.pageStatus = pageStatus;
    modalRef.componentInstance.modalTitle = modalTitle;
    modalRef.componentInstance.monitoraggio = monitoraggio;
    modalRef.componentInstance.loadMonitoraggio();
  }
}
