export const environment = {
  production: false,
  component_be: "",
  issuer: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa',
  authUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/auth',
  tokenUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/token',
  redirect_uri: 'https://monitoraggio-dl34-test.sanita.toscana.it',
  // redirect_uri: 'http://localhost:4200',
  client_id: 'r_toscan__178__monitoraggio_dl34',
  itemsPerPage: 10,
  tokenSessionTime: 20,
  refresh_tokenTime: 10,
  access_tokenTime: 5,
  // tokenSessionTime: 2000,
  // refresh_tokenTime: 1000,
  // access_tokenTime: 500,
  // context: '/dl34',
  // apiPath: '',
  // apiVersion: ''
  // apiPath: '/api',
  // apiVersion: 'v1'
  context: 'https://apistage.regione.toscana.it/C07',
  apiPath: '/MonitoraggioDL34',
  apiVersion: 'v1'
  // solo in staging local
  // ,user_access_token: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJnZ3VFdlBBTS1rN0Jsa0xJMnN0VFN1Q0xVcXJsbDdwUE9JaWhsZUNFRWJZIn0.eyJleHAiOjE3MDU5MTM3NDQsImlhdCI6MTcwNTkxMzQ0NCwiYXV0aF90aW1lIjoxNzA1OTEzNDQ0LCJqdGkiOiJhYTliYmUxOS1iNDFkLTQzYjYtOGFjNS00MGYxMzdhNTgxNjUiLCJpc3MiOiJodHRwczovL2FjY2Vzc29zaWN1cm8tdHJpYWwucmV0ZS50b3NjYW5hLml0L2F1dGgvcmVhbG1zL2FycGEiLCJhdWQiOiJtb25pdG9yYWdnaW8tZGwzNC1ycyIsInN1YiI6ImFjMzA5OGU0LTFiYTYtNDZmNC05OGEyLTM2ZjkxMzBkOWZjOSIsInR5cCI6IkJlYXJlciIsImF6cCI6InJfdG9zY2FuX18xNzhfX21vbml0b3JhZ2dpb19kbDM0Iiwic2Vzc2lvbl9zdGF0ZSI6IjIwNTdjYmM0LWFmNzItNGZiMy05MDRlLWM4MGM1M2U5YmU3ZSIsImFjciI6IjEiLCJzY29wZSI6InByb2ZpbGUgcnRyb2xlcyBkZWZhdWx0Iiwic2lkIjoiMjA1N2NiYzQtYWY3Mi00ZmIzLTkwNGUtYzgwYzUzZTliZTdlIiwiZmlzY2FsX251bWJlciI6IlRJTklULUNTUkdHTDQ0TDEzSDUwMUUiLCJhdXRoX3R5cGUiOiJGZWRlcmF0aW9uIGh0dHBzOi8vYXJwYXN1cHBvcnQudGl4Lml0IiwiYXV0aF9sZXZlbCI6IjMiLCJuYW1lIjoiR0FJTyBHSVVMSU8iLCJzcGlkX2NvZGUiOiIxYzQ2NjgwMS1mZGExLTQ3MmMtYTU0Yi0yOGIyMDI4OGQ3ZTIiLCJhdXRoVFMiOiIxNzA1OTEzNDQzMjY4IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiQ1NSR0dMNDRMMTNINTAxRSIsImZhbWlseV9uYW1lIjoiQ0VTQVJFIiwiYXV0aElEIjoiMDFfdGVzdDE2XzE3MDU5MTM0NDMxNTJfNDY1OTIiLCJydF9yb2xlcyI6WyJPcGVyYXRvcmUgU29jaW8gU2FuaXRhcmlvIiwiT3BlcmF0b3JlIHNhbml0YXJpbyIsIk9wZXJhdG9yZSBTdWFwIiwiT3BlcmF0b3JlIFNEUyIsIlJlc3BvbnNhYmlsZVJTQSIsIm1lZGljb1NTVCIsIk9wZXJhdG9yZSBFbnRpIExvY2FsaSIsIk9wZXJhdG9yZSBFbnRpIEFjY3JlZGl0YXRpIFNDUiIsIkFkbWluIFN1YXAiLCJEaXJpZ2VudGUgU2NvbGFzdGljbyIsIkNpdHRhZGlubyJdfQ.e7StGDKC79EnfjdWZz3yV_GKscYyB8TpRxNvE96UfyN15n7R9nFAr_YO4yt4eHePnHZfgtRSy2lwHWQDPdHxJQOhcLyRnOVeF_nXItnuCP6k7LkmbfWZ3yRk9QZR-pOxHb5DhQZaYeOY2HdrgrjxOGF-yYz9npwdvL9fnHtUJLRAXLElghylyv4a4oZjM420AgOFdsKcKngdNByeMPlQCesDw-TOPZJYJExQWEbghMrxLFjBbTubuBh8PNOCyXNMtFYaZXfSrrjGDu4GvtTLtWCAO_ZN0e0av5mxxdeiLkxCNJjLTPOeSaTwQJ331OzQwM3MMnbQEtvrpZKmlWHf_Q'
  // ,user_refresh_token: 'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIxOGQxZWVlYi1mM2MwLTRhZjYtOTZlMS00YTExNmRiODMwNTgifQ.eyJleHAiOjE3MDU5MTQwNDQsImlhdCI6MTcwNTkxMzQ0NCwianRpIjoiOTRiNWE4ZDktNTk0Yy00MzIyLWJmMGUtZjQyOWU1OTYyMGQ5IiwiaXNzIjoiaHR0cHM6Ly9hY2Nlc3Nvc2ljdXJvLXRyaWFsLnJldGUudG9zY2FuYS5pdC9hdXRoL3JlYWxtcy9hcnBhIiwiYXVkIjoiaHR0cHM6Ly9hY2Nlc3Nvc2ljdXJvLXRyaWFsLnJldGUudG9zY2FuYS5pdC9hdXRoL3JlYWxtcy9hcnBhIiwic3ViIjoiYWMzMDk4ZTQtMWJhNi00NmY0LTk4YTItMzZmOTEzMGQ5ZmM5IiwidHlwIjoiUmVmcmVzaCIsImF6cCI6InJfdG9zY2FuX18xNzhfX21vbml0b3JhZ2dpb19kbDM0Iiwic2Vzc2lvbl9zdGF0ZSI6IjIwNTdjYmM0LWFmNzItNGZiMy05MDRlLWM4MGM1M2U5YmU3ZSIsInNjb3BlIjoicHJvZmlsZSBydHJvbGVzIGRlZmF1bHQiLCJzaWQiOiIyMDU3Y2JjNC1hZjcyLTRmYjMtOTA0ZS1jODBjNTNlOWJlN2UifQ.sUVnNnG5hKUE3srOA6txbmqHktho66lNOkN2K3qM1hQ'
  // ,user_token_type: 'Bearer'
  // ,user_id: 128
  // ,user_username: 'CSRGGL44L13H501E'
  // ,user_email: 'dl34admin@test.it'
  // ,user_id_dati_anagrafici: 8
  // ,user_id_ruolo: 1
  // ,user_nome_ruolo: 'ADMIN'
  // ,user_ente_riferimento: 'Regione Toscana'
  // ,user_ruolo_presso_ente: 'amministratore'
  // ,user_flag_reset: false
};