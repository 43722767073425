import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInjector } from 'src/app/helpers/app-injector';
import { FormCommons } from 'src/app/utility/commons';
import { DateUtils } from 'src/app/utility/date-utils';

@Component({
  selector: 'app-monitoraggio-posti-letto',
  templateUrl: './monitoraggio-posti-letto.component.html',
  styleUrls: ['./monitoraggio-posti-letto.component.css'],
})
export class MonitoraggioPostiLettoComponent implements OnInit {
  formCommons = AppInjector.get(FormCommons);
  form: FormGroup;

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = '';

  _monitoraggio: any;
  
  get monitoraggio(): any {
    return this._monitoraggio;
  };

  @Input()
  set monitoraggio(value: any) {
    this._monitoraggio = value;
    if(this.form) {
      if (value?.isDefault === false) {
        this.form.disable();
      } else {
        this.form.enable();
      }
      this.form.patchValue({
        plTiHsp2019Cod49: value?.plTiHsp2019Cod49,
        plTiProgConvModLg77: value?.plTiProgConvModLg77,
        plTiProgConvModLg77Agg: value?.plTiProgConvModLg77Agg,
        plTiProgConvModLg77Agg2: value?.plTiProgConvModLg77Agg2,
        plTsiProgConvModLg77: value?.plTsiProgConvModLg77,
        plTsiAttivati: value?.plTsiAttivati,
        plTsiProgConvModLg77Agg2: value?.plTsiProgConvModLg77Agg2,
        plTsiConvertibili: value?.plTsiConvertibili,
        plTsiProgConvModLg77Agg: value?.plTsiProgConvModLg77Agg,
        dataRilevazione: DateUtils.convertToFeDate(value?.dataRilevazione)
      });
    }
  }

  @Output()
  preSubmit = new EventEmitter<any>();

  @Output()
  onSubmit = new EventEmitter<any>();

  @Output()
  onUpdate = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      plTiHsp2019Cod49: [
        this.monitoraggio?.plTiHsp2019Cod49,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      plTiProgConvModLg77: [
        this.monitoraggio?.plTiProgConvModLg77,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      plTiProgConvModLg77Agg: [
        this.monitoraggio?.plTiProgConvModLg77Agg,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      plTiProgConvModLg77Agg2: [
        this.monitoraggio?.plTiProgConvModLg77Agg2,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      plTsiProgConvModLg77: [
        this.monitoraggio?.plTsiProgConvModLg77,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ]
      ],
      plTsiAttivati: [
        this.monitoraggio?.plTsiAttivati,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      plTsiProgConvModLg77Agg2: [
        this.monitoraggio?.plTsiProgConvModLg77Agg2,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      plTsiConvertibili: [
        this.monitoraggio?.plTsiConvertibili,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      plTsiProgConvModLg77Agg: [
        this.monitoraggio?.plTsiProgConvModLg77Agg,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      dataRilevazione: [
        DateUtils.convertToFeDate(this.monitoraggio?.dataRilevazione),
        [Validators.required],
      ],
    });

    if(this.pageStatus === 'VIEW' || this.monitoraggio?.isDefault === false) {
      this.form.disable();
    }
  }

  submit() {
    this.form.markAllAsTouched();
    let json = {
      plTiHsp2019Cod49: this.form.controls.plTiHsp2019Cod49.value,
      plTiProgConvModLg77: this.form.controls.plTiProgConvModLg77.value,
      plTiProgConvModLg77Agg: this.form.controls.plTiProgConvModLg77Agg.value,
      plTiProgConvModLg77Agg2: this.form.controls.plTiProgConvModLg77Agg2.value,
      plTsiProgConvModLg77: this.form.controls.plTsiProgConvModLg77.value,
      plTsiAttivati: this.form.controls.plTsiAttivati.value,
      plTsiProgConvModLg77Agg2:
        this.form.controls.plTsiProgConvModLg77Agg2.value,
      plTsiConvertibili: this.form.controls.plTsiConvertibili.value,
      plTsiProgConvModLg77Agg: this.form.controls.plTsiProgConvModLg77Agg.value,
      dataRilevazione: DateUtils.convertToBEOnlyDateString(
        this.form.controls.dataRilevazione.value
      ),
    };
    this.preSubmit.emit(json);
    if (this.form.invalid) {
      return;
    }
    if(this.pageStatus === 'NEW') {
      this.onSubmit.emit(json);
    } else if(this.pageStatus === 'UPDATE') {
      this.onUpdate.emit(json);
    }
  }
}
