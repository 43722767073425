<div class="justify-content-center page-content">
  <div class="home-content">
    <h3 class="title_1">Sistema per la rilevazione ed il monitoraggio degli interventi per posti letto di terapia intensiva e sub-intensiva, pronto soccorso e mezzi di soccorso
    </h3>
    <div class="access-button">
      <button (click)="loginArpa()" class="btn btn-primary">
        Accedi
      </button>
    </div>
    <!-- <div class="access-button">
      <button (click)="loginLocal()" class="btn btn-secondary">
        Accedi in locale
      </button>
    </div> -->
    <!-- <div class="access-button">
      <button (click)="logout()" class="btn btn-sm btn-light">
        Logout <i class="fas fa-sign-out-alt"></i>
      </button>
    </div> -->
  </div>
</div>